import { useGetLeagueByIdMutation, useGetLeaguesMutation } from "../store/api/esports-api";
import { UseTypedSelector, selectIsPending, selectLastLeagues, selectLeagues } from "../store/reducer";
import { useEffect } from "react";

import logo from '../assets/img/BFG-token-logo.png'
import { changePending } from "../store/reducers/esportsReducer";
import { useDispatch } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";

function EsportsRedirect(props){
    const [getLeagues] = useGetLeaguesMutation();
    const [setLastLeague] = useGetLeagueByIdMutation();
    
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_last_leagues = UseTypedSelector(selectLastLeagues)
    const state_league = UseTypedSelector(selectLeagues)
    const isPending = UseTypedSelector(selectIsPending)
    
    useEffect(() => {
        const temp = changePending(true);
        dispatch(temp)
    }, [dispatch])

    useEffect(() => {
        if(state_league){
            const temp = changePending(false);
            dispatch(temp)
        }
    }, [state_league, dispatch])

    useEffect(() => {
        if(isPending && !state_league){
            getLeagues()
        }
        if(!isPending && state_league){
            if(Object.keys(state_league).length){
                if(!state_last_leagues){
                    setLastLeague(state_league[Object.keys(state_league).length-1].id)
                }
            }
        }
    }, [state_league, state_last_leagues, setLastLeague, getLeagues, isPending])
    
    return(
        <div style={{flex: 1, display: 'flex', width: '100%', flexDirection: 'column'}}>
            {isPending &&
                <section className="section-first__esport">
                    <div>
                        <img src={logo} className="loading-logo__esports" alt='loading-logo' />
                    </div>
                </section>
            }
            {!isPending && !state_league && 
                <section className="section__esport" style={{overflow: 'hidden'}}>
                    <div style={{ backgroundImage : `url(/static/header-img.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} className="heading-layer__esports"></div>
                    <Container fluid className="container-menu__esports">
                        <Row className="row-menu__esports">
                            <Col className="justify-end__esports">
                                <div>
                                    <Button onClick={() => navigate('/')} variant="dark"> Back </Button>
                                </div>
                            </Col>
                            <Col lg={8} md={12} sm={12} xs={12}>
                            <div className="tabs_container__esport" style={{color: 'white'}}>
                                No leagues at the moment.
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
            {!isPending && state_league && state_last_leagues &&
                <Navigate to={'/esports/'+state_last_leagues.id+'/schedule'} />
            }
        </div>
    )
}

export default EsportsRedirect;
