import gifc5p1 from '../../assets/img/loregif/gifc5p1.gif';

import '../../assets/css/lore/chapter5.css';

function ChapterFive(props){
    const isMobile = props.mobile;
    return(
        <div className="row">
            <div className="col-sm-6 col-sm-6__5">
                The guards, of course, agreed to let them take the stone. After all, it wasn’t theirs to begin with, nor had they any reason to doubt their kings’ intentions. But, the second the stone was taken from the guards, the atmosphere changed. Everything felt much more somber, more serious. Suddenly, the guards’ eyes turned back inside their heads, and the only thing they could see was red. They lost the ability to speak and seemed to have lost all reason. One of them even started to scream, leaving the kings both confused and terrified.
                And so it was that King Ion mustered up the courage and, in the heat of the moment, grabbed hold of the stone and ran. Olaf and Glaucus followed him instinctively but did not manage to get far before the guards caught up to them.
                <br />
                Thinking quickly, Olaf released a spell that caused some rocks from a nearby hill to break, managing to block the road behind them. Unable to pass through, the guards began to hit the rocks as hard as they could, bloodying their hands in the process. Bits of bones started flying everywhere, but the guards didn’t seem to feel any pain. Once their hands were completely destroyed, they turned to each other and, in an inexplicable blinding rage, began to bite and gnaw on one another until their bodies were no more. It was a gruesome sight, and the stone was no longer around to heal them.
                
            </div>
            {isMobile &&
             <img className="slika__5" src={gifc5p1} alt='gifc5p1' />
            }
            
            <div className="col-sm-6__s5 temp__5">
                Once they were at a safe distance, the Kings unanimously agreed that not a single detail of this event would be shared with the people. This stone, they concluded, was too unpredictable to control and too dangerous if brought into the wrong hands.
                <br /><br />
                Fearing that they themselves would also fall victim to the stone’s influence, the Kings moved the stone every few months from one location to another. First, it was in possession of the Volnics. As expected, the floods ceased in no time, and the mines became once again available for the workers. King Glaucus was, of course, incredibly pleased with such an outcome but did not let himself become greedy. Once the time came, he handed the stone over to his fellow Kings who had suffered the same hardship as he had. Next, the stone was transferred to the Arcidam Kingdom, where crops quickly flourished, and lastly, it was brought to the Saidics, who noticed an unexpected increase in magical power.
                <br /><br />
                Worrying that suspicion might arise in people’s minds as to what was happening with the world, the Kings decided to share the news about the magical stone and its powers but avoided the part about the stone’s ability to grant immortality. For obvious reasons, they thought it best to keep this piece of information secret for a little longer.
            </div>
            {!isMobile &&
             <img className="slika__5" id="desk__5" src={gifc5p1} alt='gifc5p1' />
            }
        </div>
    )
}

export default ChapterFive;