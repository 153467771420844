import { Container } from "react-bootstrap";
import newsF from "../helpers/firebase";

import { useEffect, useState } from "react";
import NewsComponent from "../components/NewsComponent";

import { collection, orderBy, where, query, getDocs } from "firebase/firestore"; 

import ClipLoader from "react-spinners/ClipLoader";

import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/minimal.css';

function AllNews() {
  //news
  const firestore = newsF.firestore();
  
  const [snapshot, setSnapshot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [top, setTop] = useState(null);
  const [readed, setReaded] = useState(null);
  const [trending, setTrending] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(9);
  const [nPages, setPages] = useState(1);

  let indexOfLastRecord = currentPage * recordsPerPage;
  let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const defaultValues = {routeUrl: '', title: '', coverImg: '', content: '', date: {seconds: null}}
  
  useEffect(() => {
    async function fetchData() {
        try{
            let q = query(collection(firestore, "posts"), where("isVisible", '==', true), orderBy('date', 'desc'))
        
            const querySnapshot = await getDocs(q);
            
            let temp = []
            querySnapshot.forEach(async (doc) => {
                let data = await doc.data()
                temp.push(data)
            });
            setLoading(false)
            setSnapshot(temp)
        }catch(err){
            setError(true);
        }
    }
    fetchData();
  },[firestore])

  useEffect(() => {
    if(snapshot && !loading){
        if(snapshot.length > 0){
            setTrending(snapshot[0]);
        }
        let topIndex = snapshot.findIndex(e => {return e.top === true});
        if(topIndex !== -1){
            setTop(snapshot[topIndex]);
        }
        let mostRead = Math.max(...snapshot.map(o => o.fingerprints.length))
        let mostIndex = snapshot.findIndex(e => {return e.fingerprints.length === mostRead});
        if(mostIndex !== -1){
            setReaded(snapshot[mostIndex]);
        }
        setPages(Math.ceil(snapshot.length / recordsPerPage))
    }   
  },[snapshot, loading, recordsPerPage])

  useEffect(() => {
    setLoading(true)
    indexOfLastRecord = currentPage * recordsPerPage;
    indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    setTimeout(() => {
        setLoading(false)
    },100)
    
  }, [currentPage,recordsPerPage])

  return (
    <div className="ability-section">
        <div className="second-bg" style={{opacity: '0.2'}}/>
        <div className="top-container-news">
            <Container style={{position: 'relative', zIndex: '3'}} className="news-capsule-top pt-5">
                {top &&
                    <NewsComponent isPagination={true}  importance={'Top'} id={-1} items_data={top} ></NewsComponent>
                }
                {!top &&
                <NewsComponent isPagination={true}  importance={'Top'} id={-1} items_data={defaultValues} ></NewsComponent>
                }
                {trending &&
                    <NewsComponent isPagination={true}  importance={'Trending'} id={-2} items_data={trending} ></NewsComponent>
                }
                {!trending &&
                <NewsComponent isPagination={true}  importance={'Trending'}  id={-2} items_data={defaultValues} ></NewsComponent>
                }
                {readed &&
                    <NewsComponent isPagination={true}  importance={'Most Read'}  id={-3} items_data={readed} ></NewsComponent>
                }
                {!readed &&
                <NewsComponent isPagination={true}  importance={'Most Read'}  id={-3} items_data={defaultValues} ></NewsComponent>
                }
            </Container>
        </div>
        <div style={{position: 'relative', zIndex: '3'}} className="pt-5 mt-5 main-container-news-all">
            <Container style={{position: 'relative', zIndex: '10'}} className="news-wrapper-all">
            {loading &&
                <div className="pt-5 pb-5">
                   <ClipLoader
                        color="#ffffff"
                        loading={loading}
                        cssOverride={{
                            display: "block",
                            margin: "0 auto",
                            borderColor: "white",
                          }}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            }
            {!loading && snapshot &&
                snapshot.slice(indexOfFirstRecord, indexOfLastRecord).map((el,index) => (
                    <NewsComponent isPagination={true} importance={null} key={index} id={index} items_data={el} ></NewsComponent>
                ))
            }
            {!loading && error &&
                <div className="pt-5 pb-5" style={{color: 'white'}}>No news at the moment</div>
            }
            </Container>
            <div className="pt-5 pb-5" style={{display: 'flex', justifyContent: 'center'}}>
                {snapshot &&
                    <>
                        {
                            snapshot.length && 
                            <ResponsivePagination
                                current={currentPage}
                                total={nPages}
                                onPageChange={setCurrentPage}
                            />
                        }
                    </>
                }
            </div>
        </div>
    </div>
  );
}

export default AllNews;
