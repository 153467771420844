import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from "react-router-dom";

const useClickOutside = (handler) => {
    const domNode = useRef();
    useEffect(() => {
        const eventHandler = (event) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
}
function getMonth(month){
    switch (month) {
        case "12": return 'Dec'; 
        case "01": return 'Jan';
        case "02": return 'Feb'; 
        case "03": return 'Mar';
        case "04": return 'Apr'; 
        case "05": return 'May'; 
        case "06": return 'Jun';
        case "07": return 'Jul';
        case "08": return 'Aug';
        case "09": return 'Sep';
        case "10": return 'Oct';
        case "11": return 'Nov';
        default:  return '';
    }
}
function convertTimeStamp(data){
    const temp = data.split('T')[0]
    return getMonth(temp.split('-')[1]) + ' ' + temp.split('-')[0]
}

function EsportsMenu({league, content, leagues_all, menu_items, executeScroll}){
    const [isOpen, setIsOpen] = useState(false);
    const [range, setRange] = useState(null);
    const [selected, changeSelected] = useState(0);

    const navigate = useNavigate();

    const dropdownNode = useClickOutside(() => {
        setIsOpen(false);
    })

    useEffect(() => {
        if(content){
            let i = menu_items.filter(x => {
                return x.name === content;
            })
            if(i[0]){
                changeSelected(i[0].id);
            }
        }
    }, [content, menu_items])

    useEffect(() => {
        if(leagues_all){
            let ranges = []
            leagues_all.forEach(x => {
                ranges.push({field: x.season})
            })
            setRange(ranges.reverse());
        }
    }, [leagues_all])

    function select(name){
        navigate('/esports/'+league.id+'/'+name)    
        executeScroll();
    }

    return(
        <>
            <Row className="row-menu__esports">
                <Col className="justify-end__esports">
                    <div className="dropdown-container" ref={dropdownNode}>
                        <button className="dropdown-link" onClick={() => setIsOpen(!isOpen)}>
                            <span>{league.name}</span>
                            <FontAwesomeIcon className="ps-5 pe-2" icon={isOpen ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} />
                        </button>
                        <CSSTransition
                            in={isOpen}
                            timeout={300}
                            classNames="dropdown-animation"
                            unmountOnExit
                            onEnter={() => setIsOpen(true)}
                            onExited={() => setIsOpen(false)}
                        >
                            <div className="dropdown-box">
                                <ul>
                                    {range && 
                                        range.map(r => 
                                            <div key={r.field}>
                                                <div className="hr-lines__esports">Season {r.field}</div>   
                                                {leagues_all.filter(x => {return x.season === r.field}).map(l => 
                                                        <div key={l.id} onClick={() => l.id !== league.id ? navigate('/esports/'+ l.id) : {}} style={{textAlign: 'start'}} className="ps-2 mb-2 selectable__esports">
                                                            <div className="name__esports">{l.name}</div>
                                                            <div className="caption__esports">{convertTimeStamp(l.starts_at)}</div>
                                                        </div>
                                                )}
                                            </div>
                                        )
                                    }
                                </ul>
                            </div>
                        </CSSTransition>
                    </div>
                </Col>
                <Col lg={8} md={12} sm={12} xs={12}>
                   <div className="tabs_container__esport">
                    {menu_items &&
                        menu_items.map(x => 
                            <div key={x.id} onClick={() => select(x.name)} className={selected === x.id ? 'tab_container_active__esports tab_container__esports' : 'tab_container__esports tab_hovered__esports'}>{x.name.toUpperCase()}</div>
                        )
                    }
                   </div>
                </Col>
            </Row>
        </>
    )
}

export default EsportsMenu;