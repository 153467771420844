import "../assets/css/Tokenomics.css";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { useEffect } from "react";

import TokenomicsContainer from '../components/TokenomicsContainer';
import { Col, Container, Row } from "react-bootstrap";

const tokens = [
    {id: 0, name: 'Player Rewards', text: '3 month-cliff, linear daily vesting for 48 months', tokens: '30,000,000', color: '#B35674', percent: '20'},   
    {id: 1, name: 'Ecosystem Fund', text: '3 month-cliff, then discretionary, first unlocks slated for tournaments.', tokens: '22,500,000', color: '#B3745B', percent: '15'},
    {id: 2, name: 'Private Round', text: '10% at TGE, 6 month-cliff, linear vesting for 12 months. Per Token - 0.04333 USD', tokens: '15,000,000', color: '#B356AD', percent: '10'},   
    {id: 3, name: 'Seed Round', text: '10% at TGE, 6 month-cliff, linear vesting for 12 months. Per Token - 0.0333 USD', tokens: '9,000,000', color: '#8455B7', percent: '6'},   
    {id: 4, name: 'Avalanch IDO', text: '100% unlocked. Per Token - 0.05333 USD', tokens: '4,687,500', color: '#555EB8', percent: '3.1'}, 
    {id: 5, name: 'Team', text: '12 month-cliff, linear daily vesting for 48 months', tokens: '30,000,000', color: '#B3585A', percent: '20'},
    {id: 6, name: 'Liquidity', text: '10% at TGE, 6 month-cliff, linear vesting for 12 months', tokens: '15,000,000', color: '#B35691', percent: '10'},
    {id: 7, name: 'Reserves', text: '6 month-cliff, linear vesting for 6 months', tokens: '9,562,000', color: '#A256B8', percent: '6.4'}, 
    {id: 8, name: 'Advisors', text: '12 month-cliff, linear daily vesting for 48 months', tokens: '7,500,000', color: '#6956B8', percent: '5'}, 
    {id: 9, name: 'Gold Stakers', text: '10% at TGE, 6-month cliff, linear vesting for 12 months. Per Token - 0.0333 USD', tokens: '4,500,000', color: '#557AB8', percent: '3'}, 
    {id: 10, name: 'AirDrop Fund', text: 'Discretionary unlock', tokens: '2,250,000', color: '#5496B8', percent: '1.5'}, 
]

function Tokenomics(){

    useEffect(() => {
        try{
            am5.ready(function() {
                let root = am5.Root.new("chartdiv");
                
                root.setThemes([
                    am5themes_Dark.new(root),
                    am5themes_Responsive.new(root)
                ]);
                
                let chart = root.container.children.push(am5percent.PieChart.new(root, {
                    innerRadius: am5.percent(90),
                    layout: root.verticalLayout
                }));


                let series = chart.series.push(am5percent.PieSeries.new(root, {
                    valueField: "size",
                    categoryField: "sector"
                }));
                

                series.data.setAll([
                    { sector: "AirDrop Fund", size: 1.5, color : "rgba(77, 134, 191, 0.1)", },
                    { sector: "Gold Stakers", size: 3 },
                    { sector: "Avalanche IDO", size: 3.1 },
                    { sector: "Advisors", size: 5 },
                    { sector: "Seed", size: 6 },
                    { sector: "Reserved", size: 6.4 },
                    { sector: "Private Round", size: 10 },
                    { sector: "Liquidity", size: 10 },
                    { sector: "Player Rewards", size: 20 },
                    { sector: "Team", size: 20 },
                    { sector: "Ecosystem Fund", size: 15 }
                ]);
                
                series.slices.template.setAll({
                    fillOpacity: 0.8,
                    stroke: am5.color(0xffffff),
                    strokeWidth: 1
                  });

                series.appear(1000, 100);
            });
        }catch{
            //
        }
    }, [])
    

    return(
        <section className="tokenomics-page"> 
            <div className="second-bg"/>
            <Container className="container-main">
                <Row style={{paddingBottom: '20px'}}>
                    <Col lg={7} md={12} sm={12} className="relative-top first-flex">
                        <div>
                            <p className="subtitle subtitle__tokenomics">The tokenomics</p>
                            <h1 className="h1-tokenomics">BFG Distribution</h1>
                            <p className="text-content" style={{color: 'white'}}>
                                BFG is a governance token for the Battle For Giostone Universe. It will help us create a close community filled with active members willing to collaborate. We want to put the wheel into the hands of the players and eliminate the problems that often happen when a central body makes all of the decisions. 
                            </p>
                        </div>
                        <div className="cont">
                            <div className="doughnut-container">
                                <div id="chartdiv"></div>
                                <div className="innerCircle">
                                    <div className="inner-dot"/>
                                    <div className="inner-dot2"/>
                                    <p style={{position: 'relative', zIndex: 2, color: 'white'}}>
                                        <b>Total Supply</b> <br/>
                                        150,000,000 tokens
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} md={12} sm={12} className="relative-top flexing-1">
                        {tokens && tokens.map(v => 
                            <TokenomicsContainer key={v.id} value={v}/>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Tokenomics;