import { useParams, Navigate, useNavigate } from "react-router-dom";
import heroesFB from '../helpers/heroes';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Heroes(){
    let stats = '';
    const navigate = useNavigate();
    const { heroName } = useParams();

    const heroNames = ['akuma', 'ranvir', 'gogmangog', 'auri', 'lucius', 'gurluk'];

    const firestoreA = heroesFB.firestore();
    const dbA = firestoreA.doc('newHero/' + heroName.toLowerCase());

    const [snapshot, loading, error] = useDocumentData(dbA);
    
    function goPrev(){
        let index = heroNames.findIndex(x => {
            return x === heroName.toLowerCase()
        })
        if(index !== -1){
            let newIndex = index-1;
            if(newIndex < 0){
                newIndex = heroNames.length -1;
            }
            navigate('/heroes/'+heroNames[newIndex]);
        }
    }
    function goNext(){
        let index = heroNames.findIndex(x => {
            return x === heroName.toLowerCase()
        })
        if(index !== -1){
            let newIndex = index+1;
            if(newIndex >= heroNames.length){
                newIndex = 0;
            }
            navigate('/heroes/'+heroNames[newIndex]);
        }
    }

    function getMax(a,b,c){
        let maxN = Math.max(a, b, c);
        if (maxN === a) {
            return 'Agility'
        }
        if (maxN === b) {
            return 'Intelligence'
        }
        return 'Strenght'
    }

    if(!loading && snapshot){
        let temp = getMax(parseInt(snapshot.agility), parseInt(snapshot.intelligence), parseInt(snapshot.strenght));
        stats = temp;
    }


    return(
        <section className="heroes-section">
            <div className="second-bg"/>
            {!loading && (error || !snapshot) &&
                <Navigate to='/' />
            }
            {!loading && snapshot &&
                    <Container className="container-hero">
                        <Row className="row-hero">
                            <Col className="col-first">
                                <div className="vertical-bar">
                                    <img className="img-icon" width='100%' alt='attribute' src={stats === 'Agility' ? '/static/icons/agility.png' : stats === 'Intelligence' ? '/static/icons/intelligence.png' : '/static/icons/strength.png'} />     
                                    <div className="text-attribute ps-3">{stats.toUpperCase()}</div>
                                    <div className="vertical-line"/>
                                </div>
                                <div className="main-hero-section ps-3">
                                    <div style={{position: 'relative', zIndex: '3'}}>
                                        <h6 className="title-hero">{snapshot.name.toUpperCase()}</h6>
                                        <p className="desc-hero pt-5 pb-3">{snapshot.desc}</p>

                                        <div>
                                            <h6 className="subtitle-hero">ATTACK TYPE</h6>
                                            <div className="display-inline">
                                                <img className="img-icon" width="100%" alt='type' src={snapshot.type.startsWith('Melee') ? '/static/icons/sword.png' : '/static/icons/bow.png'} />
                                                <div className="text-icon ps-3">{snapshot.type.startsWith('Melee') ? 'MELEE' : 'RANGED'}</div>
                                            </div>
                                        </div>
                                        <div className="pt-4">
                                            <h6 className="subtitle-hero">ATTRIBUTES</h6>
                                            <div className="display-inline">
                                                <div className="display-inline">
                                                    <img className="img-icon" width="100%" alt='type' src='/static/icons/strength.png' />
                                                    <div className="text-icon ps-3">{snapshot.strenght}</div>
                                                </div>
                                                <div className="display-inline ps-3">
                                                    <img className="img-icon" width="100%" alt='type' src='/static/icons/agility.png' />
                                                    <div className="text-icon ps-3">{snapshot.agility}</div>
                                                </div>
                                                <div className="display-inline ps-3">
                                                    <img className="img-icon" width="100%" alt='type' src='/static/icons/intelligence.png' />
                                                    <div className="text-icon ps-3">{snapshot.intelligence}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="arrow-container">
                                <video poster={snapshot.heroImg} className={snapshot.name === 'Auri' ? 'background-container hero-vid auri-vid' : snapshot.name === 'Gurluk' ? 'background-container gurluk-vid': snapshot.name === 'Ranvir' ? 'background-container ranvir-vid': 'background-container hero-vid'} preload="metadata" loop muted playsInline autoPlay>
                                    <source type="video/mp4" src={snapshot.heroVid} />
                                    <source type="video/webm" src={snapshot.heroVid} />
                                </video>
                            </Col>
                            <Col className="arrow-container-ios">
                                <div className="mt-4">
                                    <img className='background-container1 hero-vid auri-vid' style={{width: '100%', height: 'auto'}} alt="hero" src={snapshot.heroImg} />
                                </div>
                            </Col>
                        </Row>
                        
                        <Container className="arrows-next mt-5 me-5">
                            <Row>
                                <Col onClick={() => {goPrev()}} className="icon-hover pt-1 pb-1"> <FontAwesomeIcon icon='fa-solid fa-chevron-left'  color="white"/> </Col>
                                <Col onClick={() => {goNext()}} className="icon-hover pt-1 pb-1"> <FontAwesomeIcon icon='fa-solid fa-chevron-right' color="white"/> </Col>
                            </Row>
                        </Container>
                    </Container>
            }
            {!loading && snapshot &&
            <div className="footer-hero">
                <Container>
                        <Row className="pt-3 pb-3 mobileFooter">
                            <Col style={{borderRight: '1px solid #ffffff4d'}} className="display-center">
                                <h6 className="subtitle-hero">ATTACK</h6>
                                <div className="gap-components">
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_damage.png' />
                                        <div className="text-icon ps-3">{snapshot.attack[0]}</div>
                                    </div>
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_attack_time.png' />
                                        <div className="text-icon ps-3">{snapshot.attack[1]}</div>
                                    </div>
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_attack_range.png' />
                                        <div className="text-icon ps-3">{snapshot.attack[2]}</div>
                                    </div>
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_projectile_speed.png' />
                                        <div className="text-icon ps-3">{snapshot.attack[3]}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{borderRight: '1px solid #ffffff4d'}} className="display-center">
                                <h6 className="subtitle-hero">DEFENSE</h6>
                                <div className="gap-components">
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_armor.png' />
                                        <div className="text-icon ps-3">{snapshot.defense[0]}</div>
                                    </div>
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_magic_resist.png' />
                                        <div className="text-icon ps-3">{snapshot.defense[1]}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="display-center">
                                <h6 className="subtitle-hero">MOBILITY</h6>
                                <div className="gap-components">
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_movement_speed.png' />
                                        <div className="text-icon ps-3">{snapshot.mobility[0]}</div>
                                    </div>
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_turn_rate.png' />
                                        <div className="text-icon ps-3">{snapshot.mobility[1]}</div>
                                    </div>
                                    <div className="display-inline">
                                        <img className="img-icon" width="100%" alt='type' src='/static/icons/icon_vision.png' />
                                        <div className="text-icon ps-3">{snapshot.mobility[2]}</div>
                                    </div>
                                </div>
                            </Col>
                    </Row>
                </Container>
            </div> 
            }
        </section>
    )
}

export default Heroes;