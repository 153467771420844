import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { BrowserRouter } from 'react-router-dom';
import { ThirdwebProvider } from "thirdweb/react";
import "./assets/css/App.css";
import './assets/css/About.css';
import "./assets/css/NavBar.css";
import "./assets/css/Heroes.css";
import "./assets/css/Abilities.css";
import "./assets/css/Lore.css";
import "./assets/css/News.css";
import "./assets/css/Esports.css";

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import values from './store';

import * as serviceWorker from './serviceWorker';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={values.store}>
    <PersistGate loading={null} persistor={values.persistor}>
      <BrowserRouter>
        <ThirdwebProvider>
          <ThemeProvider theme={darkTheme}>
          <CssBaseline />
            <App />
          </ThemeProvider>
        </ThirdwebProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
