import glacis from "../../assets/img/loregif/glacis.png";
import dekans from "../../assets/img/loregif/dekans.png";
function ChapterTen(props){
    return(
        <div className="row">
        <div className="headis__10">
            Centuries passed in total anarchy, and merely surviving became challenging once more. People had been searching for the Giostone during all of those years.
           
            Two new ideologies, the Deccan and the Glaccic, developed throughout time.
            <br />
            The Deccan thought the stone was necessary to prevent volcanoes from destroying the Earth.
            <br />
            The Glaccic, on the other hand, thought that an impending ice age was imminent and that the Giostone was the best way to save everyone.
            <br />
            The strongest warriors, assassins, and wizards in existence had pledged to find Gio and be the ones to get the stone. And so, the Battle for Giostone began...
        </div>
            <div className="col-sm-2__10 col-sm-2">
                <div className="decans__10">
                    <img src={dekans} alt="dekans"/>
                <p>The Deccan</p>
                </div>
            </div>
            <div className="col-sm-2__10 col-sm-2">
                <div className="glacis__10">
                    <img src={glacis} alt="glacis" />
                    <p>The Glaccic</p>
                </div>
            </div>
            <div className="lasst__10">
            </div>
        </div>
    )
}

export default ChapterTen;