
import gif4 from '../../assets/img/loregif/gif4.gif'
import gif3 from '../../assets/img/loregif/gif3.gif'
import gif2 from '../../assets/img/loregif/gif2.gif'
import gif1 from '../../assets/img/loregif/gif1.gif'

function ChapterOne(props){
    const isMobile = props.mobile;

    return(
        <>
        <div className="col-sm-6">
            Since its beginning, the world has gone through many epochs and eras, changes and still moments, and all kinds of natural and political disasters.
            Of course, all those events had an important story that helped humanity learn a new lesson. Nevertheless, each story starts at its own new beginning of time, as if every previous event has been long forgotten. Our story today is no exception.
            <br /><br />
            And so it was that there ruled three kingdoms at the beginning of time: the Saidic Kingdom, the Volnic Kingdom, and the Arcidam Kingdom.
            Each one had a king - a loved and respected King - who lived to serve their dear people. Not one villager was forgotten, for the kings lived among them, making sure all their voices were heard and leaving no one behind.
            <br /><br />
            <div className="row">
                <div className="col-sm-9">
                    Throughout their reign, there was an age known as the Golden Period when balance and peace were maintained, and the people worked as one. They cherished a simple life, focusing on food, peace, and happiness.
                    King Glaucus, the ruler of the Voinic Kingdom, nurtured the art of mining, crafting, and masonry. The Voinics supplied the three kingdoms with builders, weapons, tools, and ore. Its crafters were the finest, making the most delicate bows and spears from plain wood as well as the best swords, shields, and armor from steel.
                </div>
                <div className={!isMobile ? 'col-sm-3' : 'col-sm-3 justify-center row'}>
                    <img width="100%" className="widimg" src={gif4} alt='gif-4' style={!isMobile ? {} : {width: '30%', height: ' 100%'}}/>
                </div>
            </div>
            
            <div className="row">
                    <div className={!isMobile ? 'col-sm-3' : 'col-sm-3 justify-center row'}>
                        <img width="100%" className="widimg" src={gif2} alt='gif2' style={!isMobile ? {} : {width: '30%', height: ' 100%'}}/>
                    </div>
                    <div className="col-sm-9">
                        King Ion, the King of the Arcidam Kingdom, had taken charge of food production. The Arcidams were masters of hunting, foraging, and storing crops. The Arcidam Kingdom was the leading supplier of food and wood, providing for all three kingdoms equally and without hesitation.
                    </div>
                </div>
        </div>
        <div className="col-sm-6">
                <div className="row">
                    <div className={!isMobile ? 'col-sm-3' : 'col-sm-3 justify-center row'}>
                        <img className="widimg" src={gif3} alt='gif3' style={!isMobile ? {} : {width: '30%', height: ' 100%'}}/>
                    </div>
                    <div className="col-sm-9">
                        King Olaf, leader of the Saidic Kingdom, had the best wizards, engineers, teachers, schools, and trainers. The Saidics were responsible for education and continuously passed their knowledge to the next generations throughout the three kingdoms. They educated architects, farmers, blacksmiths, and miners alike.
            
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-9">
                        The Golden Period had been fruitful, and the Three Kingdoms’ people believed nothing more was needed other than fresh air, food, and water for a fulfilled life. Fertile fields, rich mines, and dense forests spanned as far as the eye could see, but what people cherished most had nothing to do with nature’s blessings. It was the ongoing solidarity, and mutual respect for one another’s crafts that made these three kingdoms as strong and as prosperous as they were.

                    </div> 
                    <div className={!isMobile ? 'col-sm-3' : 'col-sm-3 justify-center row'}>
                        <img width="100%" className="widimg" src={gif1} alt='gif1' style={!isMobile ? {} : {width: '30%', height: ' 100%'}}/>
                    </div>
                </div>
            
        </div>
        </>
    )
}

export default ChapterOne;