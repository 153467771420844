import axios from 'axios';
import values from '../index';

const axiosInstance = axios.create({
    //baseURL: 'http://localhost:3001'
    baseURL: 'https://api.battleforgiostone.com/api/',
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    //headers: {
    //  'Content-Type': 'application/json'
    //}
})

axiosInstance.interceptors.request.use((config) => {
    // Retrieve the access token from your Redux state and set the Authorization header
    const token = values.store.getState().auth.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

export default axiosInstance;