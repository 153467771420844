import gioMerge from '../../assets/img/loregif/gioMerge.png'
import glakus from '../../assets/img/loregif/glakus.png'
import ion from '../../assets/img/loregif/ion.png'
import olaf from '../../assets/img/loregif/olaf.png'
function ChapterNine(props){
    return(
        <div className="row">
            <div className="row relatives__9">
                <div className="col-sm-8__9 col-sm-8 nine_start">
                    The fighters on the field stood shocked and unable to move, still struck with horror and awe after witnessing the might of a real live dragon, something no one had seen in many, many centuries. Now that there was a new force to be reckoned with, the Stone War seemed pointless, and the fighting ceased quickly. The Dragon was given the name Gio in honor of the Kings’ deceased fathers - Glaucus, Ion, and Olaf - and the stone that remained within it was now called the Giostone.
                    <br /><br />
                    For better or worse, the Three Kingdoms’ people rebelled against their young kings and forced them off the throne. The sons of the once-respected rulers were held accountable for the Dragon’s spawning and were killed mercilessly in a moment of rage that overwhelmed the townsmen. Soon after, all three Kingdoms disbanded, for no one was willing nor capable of taking the lead and uniting them.
                </div>
        
                <img className="giomerge__9" src={gioMerge} alt="gioMerge" />
        
                <div className="kingsss__9">
                    <img className="kings__9" src={glakus} alt="glakus"  />
                    <img className="kings__9" src={ion} alt="ion"  />
                    <img className="kings__9" src={olaf} alt="olaf"  />
                </div>
            </div>
        </div>
    )
}

export default ChapterNine;