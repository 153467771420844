import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { UseTypedSelector, selectLR, selectLastLeagues, selectLeagueMatches, selectLeagues } from "../store/reducer";
import EsportsHeading from "../components/esports/EsportsHeading";
import { useGetMatchesMutation, useGetRegionsMutation } from "../store/api/esports-api";
import EsportsMenu from "../components/esports/EsportsMenu";
import Schedule from "../components/esports/Schedule";
import WatchEsport from "../components/esports/Watch";
import Brackets from "../components/esports/Brackets";
import Information from "../components/esports/Information";
import { Container } from "react-bootstrap";

const menu_items = [
    {id: 0, name: 'watch'},
    {id: 1, name: 'schedule'},
    {id: 3, name: 'brackets'},
    {id: 4, name: 'information'}
]

function Esports(){
    const [league, setLeague] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const navigate = useNavigate();
    const {leagueId, content, matchID} = useParams();
    const state_last_leagues = UseTypedSelector(selectLastLeagues)
    const state_leagues = UseTypedSelector(selectLeagues)
    const state_league_regions = UseTypedSelector(selectLR)
    const state_league_matches = UseTypedSelector(selectLeagueMatches)

    const [getRegions] = useGetRegionsMutation();
    const [getMatches] = useGetMatchesMutation();

    
    const executeScroll = () => {
        let element = document.getElementById('toBeScrolledTo')
        if(element){
            window.scrollTo(0, 380);
        }
    }


    function isNumber(value) {
        return typeof value === 'number';
    }

    useEffect(() => {
        if(!content){
            navigate('/esports/'+leagueId+'/schedule');
        }else{
            let i = menu_items.filter(x => {
                return x.name.toLowerCase() === content.toLowerCase()
            })
            if(!i[0]){
                navigate('/esports/'+leagueId+'/schedule');
            }
        }
    }, [content, navigate, leagueId])

    useEffect(() => {
        setLeague(null)
       
        if(!state_league_regions){
            getRegions()
        }

        if(state_leagues && isNumber(parseInt(leagueId))){
            if(!state_league_matches[leagueId]){
                getMatches(leagueId)
            }

            if(state_last_leagues){
                if(parseInt(leagueId) === state_last_leagues.id){
                    setLeague(state_last_leagues)
                }else{
                    getLeagueById();
                }
            }else{
                getLeagueById();
            }
        }

        function getLeagueById(){
            state_leagues.forEach(x => {
                if(x.id === parseInt(leagueId)){
                    setLeague(x.id);
                }
            })
        }

        setLoading(false)
    }, [leagueId, state_last_leagues, state_leagues, state_league_regions, state_league_matches, getRegions, getMatches])

    return(
        <section className="section__esport">
            {!isLoading && league &&
                <>
                    <EsportsHeading league={league} regions={state_league_regions} />
                    <Container fluid className="container-menu__esports" id="toBeScrolledTo">
                        <EsportsMenu league={league} content={content} leagues_all={state_leagues} menu_items={menu_items} executeScroll={executeScroll} />
                    </Container>
                    {content && 
                        <>
                            {content.toLowerCase() === 'watch' &&
                                <section className="ref-section">
                                    <div className="ref-layer"></div>
                                    <WatchEsport league={league} regions={state_league_regions} matches={state_league_matches[league.id]} matchID={matchID}/>
                                </section>
                            }
                            {content.toLowerCase() === 'schedule' &&
                                <section className="ref-section">
                                    <div className="ref-layer"></div>
                                    <Schedule league={league} regions={state_league_regions} matches={state_league_matches[league.id]} executeScroll={executeScroll} ></Schedule>
                                </section>
                            }
                            {content.toLowerCase() === 'brackets' &&
                                <section className="ref-section">
                                    <div className="ref-layer"></div>
                                    <Brackets league={league} regions={state_league_regions} />
                                </section>
                            }
                            {content.toLowerCase() === 'information' &&
                                <section className="ref-section">
                                    <div className="ref-layer"> </div>
                                    <Information></Information>
                                </section>
                            }
                        </>
                    }
                </>
            }
            {!isLoading && !league &&
                <Navigate to='/esports' />
            }
        </section>
    )
}

export default Esports;