import gifc6g1 from "../../assets/img/loregif/gifc6g1.gif"
import gifc6g2 from "../../assets/img/loregif/gifc6g2.gif"
import gifc6g3 from "../../assets/img/loregif/gifc6g3.gif"
import gifc6g4 from "../../assets/img/loregif/gifc6g4.gif"

import '../../assets/css/lore/chapter5.css';
function ChapterSix(props){
    const isMobile = props.mobile;
    return(
        <div className="row row__6">
            <div className="row row__6">
                <div className={!isMobile ? 'col-sm-10 col-sm-10__6' : 'col-sm-12 col-sm-12__6'}>
                    For years after, the stone was taken anywhere where misfortune would strike. If rain was needed, the stone brought rain. If sunlight was a deficit, the stone provided. Miracles were happening all around, and the Kingdoms’ people were once again getting used to an abundant life.
                    <br /><br />
                    However, one day the stone surprised them with yet another novelty - it suddenly cracked. Sounding much like glass breaking, the stone divided itself into three perfectly even pieces, all of them now shining in a different color: one yellow, one green, and one purple. King Glaucus was the one to witness this new phenomenon, for the stone was in his possession at the time. He wasted no time and called for his fellow kings immediately. Not knowing what to make of this, the Kings decided to take yet another gamble involving the stone - each one would guard a piece in his kingdom, see what happens, and report back to the others. And so they did.
                </div>
                <div v className={!isMobile ? 'col-sm-2 col-sm-2__6' : 'col-sm-12 col-sm-12__6'}>
                    <img className="ims" src={gifc6g1} alt="gifc6g1" />
                </div>
            </div>
            <div className="row">

                Once again, their gamble provided positive results. After only a few short months, it was discovered that each individual stone now carries its own power, different from the rest. The Greenstone would purify air, rewind time, and amplify magical abilities. The Purplestone could control water in all its states, find water sources, and freeze or vaporize water bodies. The Yellowstone’s power was producing heat, smoke, and fire. What was interesting was, that now you were not preventing ageing and hurting when you were around them.
            <br /><br />
            Just to be on the safe side, the Kings swapped the stones every few months, the same as they did before the original stone cracked. None of them had forgotten what happened to the three guards years ago, and they weren’t willing to risk inviting such an outcome for anyone else.
            <div className="picturess__6">
                <img src={gifc6g2} alt="gifc6g1"  />
                <img src={gifc6g3} alt="gifc6g1"  />
                <img src={gifc6g4} alt="gifc6g1"  />
            </div>   
        
            </div>
        
        </div>
    )
}

export default ChapterSix;