// project imports
import { createSlice } from '@reduxjs/toolkit';
import { esportsApi } from '../api/esports-api';

export const initialState = {
  league_regions_group_stage: [],
  league_region_playoffs: [],
  league_region_results: []
};

const bracketsReducer = createSlice({
  name: 'brackets',
  initialState,
  reducers: {
   // logout: () => initialState
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(esportsApi.endpoints.getGroupStageMatchesLeagueRegion.matchFulfilled, (state, action) => {
      let league = action.meta.arg.originalArgs.leagueId
      let region = action.meta.arg.originalArgs.regionId
      if(!state.league_regions_group_stage[league]){
        state.league_regions_group_stage[league] = [];
      }
      if(action.payload.data){
        state.league_regions_group_stage[league][region] = action.payload.data;
      }
    })
    .addMatcher(esportsApi.endpoints.getPlayOffMatchesLeagueRegion.matchFulfilled, (state, action) => {
      let league = action.meta.arg.originalArgs.leagueId
      let region = action.meta.arg.originalArgs.regionId
      if(!state.league_region_playoffs[league]){
        state.league_region_playoffs[league] = [];
        if(!state.league_region_playoffs[league][region]){
          state.league_region_playoffs[league][region] = action.payload.data;
        }
      }else{
        if(!state.league_region_playoffs[league][region]){
          state.league_region_playoffs[league][region] = action.payload.data;
        }
      }
      
    })
    .addMatcher(esportsApi.endpoints.getLeagueResults.matchFulfilled, (state, action) => {
      let league = action.meta.arg.originalArgs.leagueId
      let region = action.meta.arg.originalArgs.regionId
      if(!state.league_region_results[league]){
        state.league_region_results[league] = [];
        if(!state.league_region_results[league][region]){
          state.league_region_results[league][region] = action.payload.data;
        }
      }else{
        if(!state.league_region_results[league][region]){
          state.league_region_results[league][region] = action.payload.data;
        }
      }
      
    })
  }
});

export default bracketsReducer.reducer;