import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/web3/Account.css'
import '../../assets/css/web3/responsive-web3.css'
import '../../assets/css/web3/Marketplace.css'

import cover from '../../assets/img/bfg-cover.jpg';
import banner from '../../assets/img/bfg-banner.png';
import { Col, Container, Row } from 'react-bootstrap';
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import InputMui from '../../helpers/InputMui';
import DropDownCustom from '../../helpers/DropDownCustom';
import PaginationComponent from '../../helpers/PaginationComponent';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import heroesFB from '../../helpers/heroes';

const classFilter = [
    {id: 0, name: 'Akuma', imgSmall: '/static/cybe/aku.png'},
    {id: 1, name: 'Auri', imgSmall: '/static/cybe/aur.png'},
    {id: 2, name: 'Gogmangog', imgSmall: '/static/cybe/gog.png'},
    {id: 3, name: 'Ranvir', imgSmall: '/static/cybe/ranv.png'},
    {id: 4, name: 'Lucius', imgSmall: '/static/cybe/lucs.png'},
    {id: 5, name: 'Gurluk', imgSmall: '/static/cybe/gur.png'},
]

const parts = [
    {id: 0, name: '1st Hand'},
    {id: 1, name: '2nd Leg'},
    {id: 2, name: '3rd Body'},
    {id: 3, name: 'Ultimate Head'},
]

const rarity = [
    {id: 0, name: 'Uncommon', color: 'grey'},
    {id: 1, name: 'Common', color: 'green'},
    {id: 2, name: 'Rare', color: 'blue'},
    {id: 3, name: 'Legendary', color: 'red'},  
]


const sales = []


export default function Profile(){
    const {user, profile_picture} = useSelector(state => state.auth);
    const navigate = useNavigate();
    console.log(user)
    const characters = 234;
    const [letterCount, setLetterCount] = useState(0);
    const [isRead, setIsRead] = useState(false);
    const [inventory, setInventory] = useState(false);
    
    const [option, setOption] = useState(0);
    const [search, changeSearch] = useState('')
    const [selectedClass, setSelectedsClass] = useState([]);
    const [selectedParts, setSelectedsParts] = useState([]);
    const [selectedRarity, setSelectedsRarity] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [searchID, changeSearchID] = useState('')

    const clearAll = (type) => {
        if(type === 'CLASS'){
            setSelectedsClass([])
        }
        if(type === 'PARTS'){
            setSelectedsParts([])
            setSelectedFilter(null)
            changeSearch('')
        }
        if(type === 'RARITY'){
            setSelectedsRarity([])
        }
    }

    useEffect(() => {
        const list = []
        heroesFB.firestore().collection("newAbilities").get().then((userSnapshot) => {
            userSnapshot.forEach((doc) => {
              const data = doc.data();
              list.push(data);
            });
            setData(list);
            setFilteredData(list);
        })
    },[]);

    useEffect(() => {
        const divContent = document.getElementById('letter-count').textContent;
        if(divContent){
            const count = divContent.length;
            setLetterCount(count);
        }
    }, []);

    useEffect(() =>{
        let newArray = data;
        if(search !== ''){
            newArray = newArray.filter((item) => item.name?.toLowerCase().includes(search.toLowerCase()));   
        }
        if(selectedFilter){
            newArray = newArray.filter((item) => {
                return item.slot?.split(' ')[0]?.toLowerCase().includes(selectedFilter.name?.split(' ')[0]?.toLowerCase())
            })
        }
        
        setFilteredData(newArray);
        
    },[search, selectedFilter])

    const handleDropdownRarity = (id) => {
        let item = rarity?.find(x => x.id === id);
        let selectedId = selectedRarity.findIndex(x => x.id === id);

        //ADD
        if(item && selectedId === -1){
            setSelectedsRarity((selectedRarity) => [...selectedRarity, item]);
        }
        //REMOVE
        if(item && selectedId !== -1){
            const newArray = selectedRarity.filter((item) => item.id !== id);
            setSelectedsRarity(newArray);
        }
    }

    const handleFilteredParts = (id) => {
        let item = parts?.find(x => x.id === id);
        
        if(item){
            //REMOVE
            if(selectedFilter?.id === id){
                setSelectedFilter(null)
            }else{
                 //ADD
                setSelectedFilter(item);
            }
        }
    }

    const handleDropdownClass = (id) => {
        let item = classFilter?.find(x => x.id === id);
        let selectedId = selectedClass.findIndex(x => x.id === id);

        //ADD
        if(item && selectedId === -1){
            setSelectedsClass((selectedClass) => [...selectedClass, item]);
        }
        //REMOVE
        if(item && selectedId !== -1){
            const newArray = selectedClass.filter((item) => item.id !== id);
            setSelectedsClass(newArray);
        }
    }

    const handleDropdownParts = (name) => {
        let item = data?.find(x => x.name === name);
        let selectedId = selectedParts.findIndex(x => x.name === name);

        //ADD
        if(item && selectedId === -1){
            setSelectedsParts((selectedParts) => [...selectedParts, item]);
        }
        //REMOVE
        if(item && selectedId !== -1){
            const newArray = selectedParts.filter((item) => item.name !== name);
            setSelectedsParts(newArray);
        }
    }

    return(
        <section className="account_page">
             {/* PROFILE */}
            <Container className='display_spacebetween profile_container'>
                {user &&
                    <div className='displayColJustifyCenter' style={{justifyContent: 'flex-start'}}>
                        <Avatar className='profile_avatar' variant="square" src={profile_picture} />
                            <div style={{position: 'relative'}}>
                            <h6 className='username_profile'>{user.username ? user.username : "Username"}</h6>
                            <div className='address__friends' data-content-start={user.wallet_address} data-content-end={user.wallet_address}></div>
                            {letterCount > characters &&
                                <div onClick={() => setIsRead(!isRead)} className='floating_readmore'> {isRead ? 'Read Less' : 'Read More'} </div>
                            }
                            <div id="letter-count" className={isRead ? 'description_profile' : 'description_profile ellipsis-text'}> 
                                ...
                            </div>
                        </div>
                    </div>
                }

                <div className='btn_group__profile'>
                    <div onClick={() => navigate('/user/profile-settings')} className='profile_edit_btn'>Edit Profile</div>
                    <div onClick={() => setInventory(!inventory)} className='profile_inventory_btn'> {inventory ? 'Go Back' : 'Inventory'}</div>
                </div>
            </Container>

            {!inventory &&
            <>
                {/* ACTIVITY STATUS */}
                <Container className='display_spacebetween bfg_row_desc__home line_container mt-5'>
                    <h6 className='h6_profile'>Activity Status</h6>

                    <div style={{textAlign: 'end'}}>
                        <h6 className='h6_profile'>Online</h6>
                        <div className='subp__profile'>Active now</div>
                    </div>
                </Container>
                
                {/* BFG STATUS */}
                <Container className='display_spacebetween bfg_row_desc__home line_container mt-5'>
                    <div className='img_container__profile'>
                        <img className='img_cover__home' src={cover} alt='cover' />
                    </div>
                    <div style={{textAlign: 'end'}}>
                        <h6 className='h6_profile'>Battle For Gistone</h6>
                        <div className='subp__profile'>Free to play</div>
                        <div onClick={() => {window.open('https://elixir.games/browse/battle-for-giostone', '_blank')}} className='subp__profile subp_link'>Play now</div>
                    </div>
                </Container>
            </>
            }

{inventory &&
            <>
                {/* FILTERS */}
                <Container style={{position: 'relative' , zIndex: '100'}}>
                    <Row className='bfg_row_desc__home profile_row__mob line_container'>
                        <Col className='col_prof_mob' style={{width: '100%'}}>
                            {/* TOGGLE */}
                            <div style={{justifyContent: 'flex-start', gap: '1rem'}} className="displayColJustifyCenter pt-3">
                                <div className={option === 0 ? 'dropdownCustumSelected' : 'dropdownCustum'}>
                                    <div className='dropdownCustum-header' onClick={() => setOption(0)}>
                                        ALL
                                    </div>
                                </div>
                                <div className={option === 1 ? 'dropdownCustumSelected' : 'dropdownCustum'}>
                                    <div className='dropdownCustum-header' onClick={() => setOption(1)}>
                                        HEROES
                                    </div>
                                </div>
                                <div className={option === 2 ? 'dropdownCustumSelected' : 'dropdownCustum'}>
                                    <div className='dropdownCustum-header' onClick={() => setOption(2)}>
                                    ITEMS
                                    </div>
                                </div>
                            
                            </div>
                            {/* INPUT */}
                            <div className='mt-4 mb-4 input__profile_cont' style={{width: '60%'}} >
                                <InputMui placeholder="Search by ID" icon={faSearch} value={searchID} handleChange={changeSearchID} />
                            </div>
                            {/* FILTER */}
                            <div>
                                <div className="displayColJustifyCenter" style={{justifyContent: 'flex-start', gap: '1rem'}}>
                                    <DropDownCustom label="CLASS" data={classFilter} handleDropdown={handleDropdownClass} selectedItems={selectedClass} clearAll={clearAll}/>
                                    <DropDownCustom label="PARTS" data={filteredData} handleDropdown={handleDropdownParts} selectedItems={selectedParts} clearAll={clearAll} filterParts={parts} selectedFilter={selectedFilter} handleFilteredParts={handleFilteredParts}
                                    search={search} changeSearch={changeSearch}/>
                                    <DropDownCustom label="RARITY" data={rarity} handleDropdown={handleDropdownRarity} selectedItems={selectedRarity} clearAll={clearAll}/>
                                </div>
                                <Col style={{textAlign: 'start', flexWrap: 'wrap', gap: '.4rem'}} className="title_mp__home displayColJustifyCenter ps-0 pe-0">
                                    {selectedClass && selectedClass.map(c => 
                                        <div key={c.id} className="tag_class__mp">
                                            <img className="img_heroes_dp__mp" src={c.imgSmall} alt='icon' />
                                            <div className="ps-1"> {c.name} </div>
                                            <span onClick={() => handleDropdownClass(c.id)} className="close_tag__mp">X</span>
                                        </div>    
                                    )}
                                    {selectedParts && selectedParts.map(c => 
                                        <div key={c.name} className="tag_parts__mp">
                                            <img className="img_heroes_dp__mp" src={c.imgSmall} alt='icon' />
                                            <div className="ps-1">{c.name} </div>
                                            <span onClick={() => handleDropdownParts(c.name)} className="close_tag__mp">X</span>
                                        </div>    
                                    )}
                                    {selectedRarity && selectedRarity.map(c => 
                                        <div key={c.id} className="tag_rarity__mp">
                                            <div className="ms-2 dropdownCustum-dot" style={{background: c.color}} />
                                            <div className="ps-2">{c.name} </div>
                                        <span onClick={() => handleDropdownRarity(c.id)} className="close_tag__mp">X</span></div>    
                                    )}
                                </Col>
                            </div>
                        </Col>
                        
                        <Col className='profile_hide' xs={0} sm={0} md={4} lg={4} xl={4} xxl={4} style={{height: '100%', display: 'flex', justifyContent: 'center', flex: '1'}}>
                            <div className='img_banner__profile'>
                                <img style={{width: '100%'}} src={banner} alt='cover' />
                            </div>
                        </Col>
                    </Row>
                </Container>

                 {/* ITEMS */}
                 <Container className='mb-5'>
                    <Row style={{minHeight: '50vh'}} className='bfg_row_desc__home line_container mt-5'>
                        <Col className='pt-4'>
                            <PaginationComponent data={sales} profile={true} />
                        </Col>
                    </Row>
                </Container>
            </>
            }
        </section>   
    )
}