import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

const useOutsideClick = (ref, callback, excludedClass) => {
    const handleClick = (event) => {
        const isDescendantOfRef = (element, refElement) => {
            if (!element) {
                return false;
            }
        
            if (element === refElement) {
                return true;
            }
        
            return isDescendantOfRef(element.parentElement, refElement);
            };
      
        const clickedElement = event.target;

        if (
            ref.current &&
            !ref.current.contains(clickedElement) &&
            !isDescendantOfRef(clickedElement, ref.current) &&
            !clickedElement.closest(`.${excludedClass}`)
        ) {
            callback();
        }
    };
  
    useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
        document.removeEventListener('mousedown', handleClick);
    };
    }, [ref, callback, excludedClass]);
};

function DropDownCustom({label, data, handleDropdown, selectedItems, clearAll, filterParts, selectedFilter, handleFilteredParts, search, changeSearch}){
    const dropdownRef = useRef(null);
    const [isOpen, setOpen] = useState(false);
    
    const toggleDropdown = (e) => setOpen(!isOpen);
    
    useOutsideClick(dropdownRef, () => {
        setOpen(false);
    }, "dropdownCustum-body");

    return (
        <div className='dropdownCustum'>
            <div ref={dropdownRef} className='dropdownCustum-header' onClick={(e) => toggleDropdown(e)}>
                {label}
                <i className={`fa fa-chevron-right dropdownCustum-icon ${isOpen && "open"}`}></i>
            </div>
            <div className={`dropdownCustum-body ${isOpen && 'open'}`}>
                <div className="display_spacebetween pt-1 pb-2 ps-1 pe-1">
                    <h6 className="dropdownCustum-insideHeader">{label}</h6>
                    <h6 style={{cursor: 'pointer'}} className="dropdownCustum-insideHeader" onClick={() => clearAll(label)}>Clear</h6>
                </div>
                {label === "PARTS" &&
                    <>
                        <div className="displayItemsFilter__mp pb-2" style={{justifyContent: 'space-evenly', flexWrap: 'nowrap'}}>
                            {filterParts && filterParts.map(f => 
                                <div onClick={() => handleFilteredParts(f.id)} style={{textAlign: 'center', minWidth: '0'}} className={"ps-2 pe-2 displayColJustifyCenter dropdownCustum-item" + (selectedFilter?.id === f.id ? "-selected" : "")}  key={f.id}>
                                    {f.name}
                                </div>
                            )}
                        </div>
                        <InputGroup data-bs-theme="dark" size="sm" className="mb-3">
                        <InputGroup.Text id="search-parts">
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control
                            value={search}
                            onChange={e => changeSearch(e.target.value)}
                            placeholder="Search by ability name"
                            aria-label="Search by ability name"
                            aria-describedby="search-parts"
                        />
                        </InputGroup>
                    </>
                }
                
                <div className="displayItemsFilter__mp">
                    {data && data.map(item => (
                        <div key={item.name} className={"displayColJustifyCenter dropdownCustum-item" + (selectedItems.filter(value => value.name === item.name).length > 0  ? "-selected" : "")} onClick={() => handleDropdown(label === 'PARTS' ? item.name : item.id)} id={item.id} style={{justifyContent: 'flex-start'}}>
                            <div className="pe-1 plusdropdown_comp displayColJustifyCenter">
                                {item.imgSmall && 
                                    <img className="img_heroes_dp__mp" src={item.imgSmall} alt='icon' />
                                }
                                {item.color && 
                                    <div className="ms-2 dropdownCustum-dot" style={{background: item.color}} />
                                }
                            </div>
                            <div className="ps-1 pe-1">
                                {item.name}
                            </div>
                        </div>
                    ))}
                </div>
                
            </div>
        </div>
    )
}


export default DropDownCustom;