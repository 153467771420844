import { Col, Container, Row } from "react-bootstrap";

function TokenomicsContainer(props) {
    const {id, name, text, tokens, color, percent} = props.value
    return(
        <Container className="container-c" style={{borderLeft: '4px solid '+color}}>
            <Row>
                <Col>
                    <p className="header-1"><b style={{color: color}}>{percent+'%'}</b>  {name}</p>
                    <div className="text-1" dangerouslySetInnerHTML={{__html: text}}></div>
                </Col>
                <Col className="col-4" style={{textAlign: 'right'}}>
                    <p className="text-1"><b>tokens</b></p> 
                    <div className="text-1">{tokens}</div>
                </Col>
            </Row>
        </Container>
    )
}

export default TokenomicsContainer;