// project imports
import { createSlice } from '@reduxjs/toolkit';
import { esportsApi } from '../api/esports-api';

export const initialState = {
  isPending: false,
  leagues: null,
  lastLeague: null,
  league_regions: null,
  league_matches: [],
  league_matches_before: [],
  league_matches_after: [],
  league_regions_rank_list: [],
  league_regions_group_stage: [],
  league_region_playoffs: [],
  all_teams: null,
  current_match: null
};

const esportsReducer = createSlice({
  name: 'esports',
  initialState,
  reducers: {
    changePending(state, action) {
      state.isPending = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(esportsApi.endpoints.getLeagues.matchPending, (state, action) => {
      state.isPending = true;
    })
    .addMatcher(esportsApi.endpoints.getLeagues.matchFulfilled, (state, action) => {
      state.leagues = action.payload.data;
      state.isPending = false;
    })
    .addMatcher(esportsApi.endpoints.getLeagues.matchRejected, (state, action) => {
      state.isPending = false;
    })
    .addMatcher(esportsApi.endpoints.getLeagueById.matchFulfilled, (state, action) => {
      state.lastLeague = action.payload.data;
    })
    .addMatcher(esportsApi.endpoints.getRegions.matchFulfilled, (state, action) => {
      state.league_regions = action.payload.data;
    })
    .addMatcher(esportsApi.endpoints.getMatches.matchFulfilled, (state, action) => {
      state.league_matches[action.meta.arg.originalArgs] = action.payload;
    })
    .addMatcher(esportsApi.endpoints.getMatchesBefore.matchFulfilled, (state, action) => {
      state.league_matches_before[action.meta.arg.originalArgs.l_id] = action.payload;
    })
    .addMatcher(esportsApi.endpoints.getMatchesAfter.matchFulfilled, (state, action) => {
      state.league_matches_after[action.meta.arg.originalArgs.l_id] = action.payload;
    })
    .addMatcher(esportsApi.endpoints.getAllTeams.matchFulfilled, (state, action) => {
      state.all_teams = action.payload.data;
    })
    .addMatcher(esportsApi.endpoints.getCurrentMatch.matchFulfilled, (state, action) => {
      if(action.payload.error){
        state.current_match = undefined;
      }else{
        state.current_match = action.payload.data;
      }
    })
    .addMatcher(esportsApi.endpoints.getGroupStageRanksLeagueRegion.matchFulfilled, (state, action) => {
      let league = action.meta.arg.originalArgs.leagueId
      let region = action.meta.arg.originalArgs.regionId
      if(!state.league_regions_rank_list[league]){
        state.league_regions_rank_list[league] = [];
      }
      if(action.payload.data){
        state.league_regions_rank_list[league][region] = action.payload.data;
      }
    })
  }
});

export const { changePending } = esportsReducer.actions;
export default esportsReducer.reducer;