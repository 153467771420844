import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { UseTypedSelector, selectLeagueRegionPlayoffs, selectLeagueRegionResults, selectLeagueRegionsGroupStage, selectLeagueRegionsRankList } from "../../store/reducer";
import { useGetGroupStageMatchesLeagueRegionMutation, useGetGroupStageRanksLeagueRegionMutation, useGetLeagueResultsMutation, useGetPlayOffMatchesLeagueRegionMutation } from "../../store/api/esports-api";
import PlayOffBrackets from "./Brackets_Playoff";
import BracketsResults from "./Brackets_Results";

function calRank(type,team){
    if(type === 'top'){
        return team.slice(0, Math.floor(team.length/2))
    }
    if(type === 'middle'){
        return team.slice(Math.floor(team.length/2), Math.ceil(team.length/4) + Math.floor(team.length/2))
    }
    if(type === 'bottom'){
        return team.slice(Math.ceil(team.length/4) + Math.floor(team.length/2), team.length)
    }
}

function Brackets({regions, league}){
    const [isPending, setPending] = useState(true);
    const [rValue, setRValue] = useState(-1);
    const [gValue, setGValue] = useState(1);
    const [regionsMain, setRegions] = useState(null);

    const [hoveredX, changeHoveredX] = useState(0);
    const [hoveredY, changeHoveredY] = useState(0);

    const state_league_regions_rank_list = UseTypedSelector(selectLeagueRegionsRankList);
    const state_league_regions_group_matches = UseTypedSelector(selectLeagueRegionsGroupStage);
    const state_playoffs = UseTypedSelector(selectLeagueRegionPlayoffs);
    const state_results = UseTypedSelector(selectLeagueRegionResults);

    const [getLeagueRegionRankList] = useGetGroupStageRanksLeagueRegionMutation();
    const [getLeagueRegionGroupMatches] = useGetGroupStageMatchesLeagueRegionMutation();
    const [getPlayoff] = useGetPlayOffMatchesLeagueRegionMutation();
    const [getResults] = useGetLeagueResultsMutation();
    
    useEffect(() => {
        if(regionsMain){
            if(!state_league_regions_rank_list[league.id]){
                regionsMain.forEach(x => {
                    getLeagueRegionRankList({leagueId: league.id, regionId: x.region.id});
                })
            }
            if(!state_league_regions_group_matches[league.id]){
                regionsMain.forEach(x => {
                    getLeagueRegionGroupMatches({leagueId: league.id, regionId: x.region.id});
                })
            }
            if(!state_playoffs[league.id]){
                regionsMain.forEach(x => {
                    getPlayoff({leagueId: league.id, regionId: x.region.id});
                })
            }
            if(!state_results[league.id]){
                regionsMain.forEach(x => {
                    getResults({leagueId: league.id, regionId: x.region.id});
                })
            }
        }
    }, [regionsMain, league, state_league_regions_rank_list, state_league_regions_group_matches, state_playoffs, state_results, getResults, getLeagueRegionRankList, getLeagueRegionGroupMatches, getPlayoff])
    
    useEffect(() => {
        if(regions){
            const regions_now = regions.filter(x => {
                return x.league.id === league.id
            })
            if(regions_now){
                setRegions(regions_now)
                    if(regions_now[0]){
                        setRValue(regions_now[0].region.id)
                    }
                    setPending(false);
                }
            }
    }, [regions, league, setRegions])

    async function changeRegion(val){
        setRValue(val);
    }
    async function changeGame(val){
        setGValue(parseInt(val));
    }

    return(
        <div className="ref-in">
            <Container className="bracket_container__esports pb-5">
                <Row>
                    <Col></Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className="flex-select__esports">
                        <Col xl={4} lg={4} md={4}>
                            {regionsMain &&
                            <Form.Select value={rValue} onChange={(e) => changeRegion(e.target.value)} className="monserat-text__esports" variant="primary" data-bs-theme="dark" aria-label="Regions">
                                {regionsMain.map(x => 
                                    <option key={x.region.id} value={x.region.id}>{x.region.name}</option> 
                                )}
                            </Form.Select>
                            }
                        </Col>
                        <Col xl={4} lg={4}  md={4}>
                            <Form.Select value={gValue} onChange={(e) => changeGame(e.target.value)} data-bs-theme="dark" aria-label="Type" className="monserat-text__esports">
                                <option value="1">Group Stage</option>
                                <option value="2">Playoff</option>
                                <option value="3">Results</option>
                            </Form.Select>
                        </Col>
                    </Col>
                </Row>
                <Row className="flexed_row__esports pt-5">
                    {!isPending && state_league_regions_rank_list[league.id] && rValue !== -1 && gValue === 1 &&
                    <>
                        <Col xl={5} lg={4} md={12}>
                            <Col className="first-col__esports">
                                <div className="flex_row__esports">
                                    <Col className="flex_col__esports">
                                        <div className="elementWidth__esports">RANK</div>
                                        <div className="elementWidth__esports">TEAM</div>
                                    </Col>
                                    <Col className="flex_col__esports" style={{justifyContent: 'flex-end'}}>
                                        <div className="elementWidth__esports">WINS</div>
                                        <div className="elementWidth__esports">DRAWS</div>
                                        <div className="elementWidth__esports">LOSSES</div>
                                    </Col>
                                </div>
                                <div className="wins__esport">
                                    {calRank('top', state_league_regions_rank_list[league.id][rValue]).map(x => 
                                        <Row className="el_0_padding" key={x.place}>
                                            <Col className="flex_col__esports">
                                                <div className="elementWidth__esports">{x.place}</div>
                                                <div className="logo-text-flex__esports">
                                                    <img className="team-img__esports" src={x.team.logo} alt='team-logo' />
                                                    <span className="ps-2">{x.team.name}</span>
                                                </div>
                                            </Col>
                                            <Col className="flex_col__esports" style={{justifyContent: 'flex-end'}}>
                                                <div className="elementWidth__esports">{x.wins}</div>
                                                <div className="elementWidth__esports">{x.draws}</div>
                                                <div className="elementWidth__esports">{x.losses}</div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                                <div className="middle__esports">
                                    {calRank('middle', state_league_regions_rank_list[league.id][rValue]).map(x => 
                                        <Row className="el_0_padding" key={x.place}>
                                            <Col className="flex_col__esports">
                                                <div className="elementWidth__esports">{x.place}</div>
                                                <div className="logo-text-flex__esports">
                                                    <img className="team-img__esports" src={x.team.logo} alt='team-logo' />
                                                    <span className="ps-2">{x.team.name}</span>
                                                </div>
                                            </Col>
                                            <Col className="flex_col__esports" style={{justifyContent: 'flex-end'}}>
                                                <div className="elementWidth__esports">{x.wins}</div>
                                                <div className="elementWidth__esports">{x.draws}</div>
                                                <div className="elementWidth__esports">{x.losses}</div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                                <div className="bottom__esports">
                                    {calRank('bottom', state_league_regions_rank_list[league.id][rValue]).map(x => 
                                        <Row className="el_0_padding" key={x.place}>
                                            <Col className="flex_col__esports">
                                                <div className="elementWidth__esports">{x.place}</div>
                                                <div className="logo-text-flex__esports">
                                                    <img className="team-img__esports" src={x.team.logo} alt='team-logo' />
                                                    <span className="ps-2">{x.team.name}</span>
                                                </div>
                                            </Col>
                                            <Col className="flex_col__esports" style={{justifyContent: 'flex-end'}}>
                                                <div className="elementWidth__esports">{x.wins}</div>
                                                <div className="elementWidth__esports">{x.draws}</div>
                                                <div className="elementWidth__esports">{x.losses}</div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Col>
                        </Col>
                        <Col style={{display: 'flex', flexDirection: 'column'}}>
                            <Col className="first-col__esports">
                            {state_league_regions_group_matches[league.id] && state_league_regions_rank_list[league.id] && rValue !== -1 &&
                                <>
                                    <div className="flex_row__esports">
                                        <div>GRID PREVIEW</div>
                                    </div>
                                    <div className="group_matches__esports">
                                        {state_league_regions_group_matches[league.id][rValue] && state_league_regions_rank_list[league.id][rValue] &&
                                            [...Array((state_league_regions_rank_list[league.id][rValue]).length+1)].map((e,x) => 
                                                <div key={x}
                                                    className={hoveredX !== 0 && hoveredY !== 0 && hoveredX !== hoveredY ? (hoveredX === x) ? 
                                                    state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,hoveredX+1,hoveredY+1)].score !== '0:0' ? 
                                                    state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,hoveredX+1,hoveredY+1)].score === '1:1' ? 'tied grid-item' : 
                                                    state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,hoveredX+1,hoveredY+1)].score === '0:2' ? 
                                                    //0-2
                                                    (hoveredX > hoveredY ? 'wined grid-item' : 'losed grid-item') : 
                                                    //2-0
                                                    (hoveredX > hoveredY ? 'losed grid-item' : 'wined grid-item')  
                                                    : 'bg-transparent grid-item' : 'bg-transparent grid-item': 'bg-transparent grid-item'}

                                                >
                                                    {
                                                        [...Array((state_league_regions_rank_list[league.id][rValue]).length+1)].map((k,y) => 
                                                                <div className="full-width" key={y}>
                                                                    <div 
                                                                    onMouseLeave={() => {changeHoveredX(0); changeHoveredY(0)}}
                                                                    onMouseEnter={() => {changeHoveredX(x); changeHoveredY(y)}}

                                                                    style={hoveredX !== 0 && hoveredY !== 0 && hoveredX !== hoveredY ? (hoveredY === y) ?  
                                                                    state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,hoveredX+1,hoveredY+1)].score !== '0:0' ?
                                                                    state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,hoveredX+1,hoveredY+1)].score === '1:1' ? (x === 0 ? {background: 'rgba(255, 255, 0, 0.1)', borderTop: '1px solid yellow'} : hoveredY === y && hoveredX === x ? {background: '#1d1d1d'} : {background: 'rgba(255, 255, 0, 0.1)'}) : 
                                                                    
                                                                    (state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,hoveredX+1,hoveredY+1)].score === '0:2' ? 
                                                                    //0:2
                                                                    (x === 0 ? ( hoveredX > hoveredY ? {background: 'rgba(255, 0, 0, 0.1)',borderTop: '1px solid red'}: {background: 'rgba(0, 128, 0, 0.1)', borderTop: '1px solid green'}) : hoveredY === y && hoveredX === x ? {background: '#1d1d1d'} : ( hoveredX > hoveredY ? {background: 'rgba(255, 0, 0, 0.1)'} : {background: 'rgba(0, 128, 0, 0.1)'}) ) : 
                                                                    //2:0
                                                                    (x === 0 ? ( hoveredX > hoveredY ? {background: 'rgba(0, 128, 0, 0.1)', borderTop: '1px solid green'} : {background: 'rgba(255, 0, 0, 0.1)',borderTop: '1px solid red'}) : hoveredY === y && hoveredX === x ? {background: '#1d1d1d'} : ( hoveredX > hoveredY ? {background: 'rgba(0, 128, 0, 0.1)'} : {background: 'rgba(255, 0, 0, 0.1)'}))
                                                                    ) 
                                                                    : {background: 'transparent'} : {} : {}}
                                                                    
                                                                    className={x === 0 && y !== 0 && y !== (state_league_regions_rank_list[league.id][rValue]).length+1 ? 'border-top1 grid-col cursor-pointer' : 
                                                                    x !== 0 && y === 0 && x !== (state_league_regions_rank_list[league.id][rValue]).length+1 ? 'border-left1 grid-col cursor-pointer' : 
                                                                    x !== 0 && y !== 0 && y !== (state_league_regions_rank_list[league.id][rValue]).length+1 && x !== (state_league_regions_rank_list[league.id][rValue]).length+1 ? 'border-mid1 grid-col cursor-pointer' : 
                                                                    y !== 0 && y === 8 && x !== (state_league_regions_rank_list[league.id][rValue]).length+1 ? 'border-end1 grid-col cursor-pointer': 
                                                                    y !== (state_league_regions_rank_list[league.id][rValue]).length+1 && x === (state_league_regions_rank_list[league.id][rValue]).length+1 ? 'border-bottom1 grid-col cursor-pointer' : 'grid-col cursor-pointer' }
                                                                    >
                                                                        <>
                                                                        {(x+1) === 1 && (y+1) !== 1 &&
                                                                            <div>
                                                                                <img className="team-img__esports" alt='img-team' src={state_league_regions_group_matches[league.id][rValue][y-1].away_team.logo} />
                                                                            </div>
                                                                        }

                                                                        {(y+1) === 1 && (x+1) !== 1 &&
                                                                            <div>
                                                                                <img className="team-img__esports" alt='img-team' src={state_league_regions_group_matches[league.id][rValue][x-1].away_team.logo} />
                                                                            </div>
                                                                        }

                                                                        {
                                                                            x !== 0 && y !== 0 && x !== y &&
                                                                            <div 
                                                                            className={(state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].away_win === 0 && state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].draw === 0 && state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].home_win === 0) ? hoveredX === x && hoveredY === y ? 'mini textGrid text-center row items-center' : 'mini1 textGrid text-center row items-center' : 'textGrid text-center row items-center'}
                                                                            >
                                                                               {(state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].away_win === 0 && state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].draw === 0 && state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].home_win === 0 && hoveredX === x && hoveredY === y) ?  
                                                                            convertDate(state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].starts_at) + '  CET' : 
                                                                            x > y ? state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].score.replace(':', ' - ').split("").reverse().join("") : 
                                                                            state_league_regions_group_matches[league.id][rValue][mathGrid(state_league_regions_rank_list[league.id][rValue].length+1,x+1,y+1)].score.replace(':', ' - ') }
                                                                            </div>
                                                                        }
                                                                        </>
                                                                    </div>
                                                                </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </>
                            }
                            </Col>
                        </Col>
                    </>
                    }
                    {!isPending && state_league_regions_rank_list[league.id] && rValue !== -1 && gValue === 2 &&
                        <PlayOffBrackets leagueId={league.id} playoffs={state_playoffs[league.id]} rValue={rValue} regions={regionsMain} teams={state_league_regions_rank_list[league.id][rValue]} />
                    }
                    {!isPending  && rValue !== -1 && gValue === 3 && state_results[league.id] &&
                        <BracketsResults leagueId={league.id} results={state_results[league.id]}  rValue={rValue}/>
                    }
                </Row>
            </Container>
        </div>
    )

    function mathGrid(len1,x,y){
        let len = Math.ceil(len1);
        let res = (len * (x-2)) + (y-2)
        
        //center
        if(x === y){
            if(x <= 2){
                res = 0
                return res
            }
            else{
                res = mathGrid(len,x-1,y-1) + (len-(x-2))
                return res
            }
        }
        //bottom
        else if(x>y){
            res = (len * (y-2)) + (x-2)
            if(y > 2 && x > 1){
                res = mathGrid(len,x-1,y) + 1
            }
        }
        //top
        else if(y>=x){
            res = (len * (x-2)) + (y-2)
            if(x > 2 && y > 1){
                res = mathGrid(len,x,y-1) + 1
            }
        }
        return res
    }

    function convertDate(data) {
        if(data){
            const temp = data.split('T')
            let part = parseInt(temp[1].split(':')[0]) >= 12 ? 'PM' : 'AM'
            let hours = parseInt(temp[1].split(':')[0]) % 12;
            hours = hours ? hours : 12;
            return getMonth(temp[0].split('-')[1]) + ' ' + temp[0].split('-')[2] + ' ' + hours + ':' + temp[1].split(':')[1]+ ' ' + part;
        }else{
            return 'TBD'
        }
    }

    function getMonth(month){
        switch (month) {
            case "12": return 'Dec'; 
            case "01": return 'Jan';
            case "02": return 'Feb'; 
            case "03": return 'Mar';
            case "04": return 'Apr'; 
            case "05": return 'May'; 
            case "06": return 'Jun';
            case "07": return 'Jul';
            case "08": return 'Aug';
            case "09": return 'Sep';
            case "10": return 'Oct';
            case "11": return 'Nov';
            default:  return '';
        }
    }
}


export default Brackets;