import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from 'react';

import Navbar from "./layouts/Navbar";
import Price from "./layouts/Price";
import Home from "./views/Home";
import Tokenomics from "./views/Tokenomics";
import About from "./views/About";
import Heroes from "./views/Heroes";
import Footer from "./views/Footer";

import { Routes, Route, Navigate} from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import 'react-notifications/lib/notifications.css';
import Abilities from "./views/Abilities";
import AbilityDetail from "./views/AbilityDetail";
import Lore from "./views/Lore";
import Streamers from "./views/Streamers";
import AllNews from "./views/AllNews";
import NewsSample from "./views/NewsSample";
import Esports from "./views/Esports";
import EsportsRedirect from "./views/EsportsRedirect";
import Airdrop from "./views/web3/Airdrop";

import { useSelector } from 'react-redux';
import Profile from "./views/web3/Profile";
import ProfileSettings from "./views/web3/ProfileSettings";
import Notifications from "./helpers/Notifications";
import SetAccount from "./components/SetAccount";
function App() {
  const {accessToken, user} = useSelector(state => state.auth);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [showUpdateProfile, setShowUP] = useState(false);

  useEffect(() => {
      if(accessToken){
        if(user?.email === null || user?.username === null){
          setShowUP(true);
        }else{
          setShowUP(false);
        }
      }
  }, [accessToken, user])
  

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess('');
  };

  const handleCloseError = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setError('');
};

  return (
    <div className="App">
      <Navbar setError={setError} setSuccess={setSuccess}/>
      <Notifications success={success} error={error} handleClose={handleClose} handleCloseError={handleCloseError} />
      <SetAccount setSuccess={setSuccess} showAcc={showUpdateProfile} setShowAcc={setShowUP} />
      <Routes>
          <Route
            path="/"
            exact
            element={
              <>
                <ScrollToTop />
                <Home createNotification={createNotification}/>
                <Footer />
                <Price createNotification={createNotification} />
              </>
            }
          />
          <Route
              path="/tokenomics"
              element={
              <>
                <ScrollToTop />
                <Tokenomics />
                <Footer />
                <Price createNotification={createNotification} />
              </>
            }
          />
          <Route
              path="/about"
              element={
                <>
                  <ScrollToTop />
                  <About />
                  <Footer />
                  <Price createNotification={createNotification} />
                </>
              }
          />
          <Route
              path="/heroes"
              element={
                <>
                  <ScrollToTop />
                  <Navigate to="/heroes/akuma" />
                </>
              }
          />
          <Route
              path="/heroes/:heroName"
              element={
                <>
                  <ScrollToTop />
                  <Heroes />
                </>
              }
          />
          <Route
              path="/abilities"
              element={
                <>
                  <ScrollToTop />
                  <Abilities />
                  <Footer />
                  <Price createNotification={createNotification} />
                </>
              }
          />
          <Route
              path="/lore"
              element={
                <>
                  <ScrollToTop />
                  <Lore />
                </>
              }
          />
          <Route
              path="/ability/:abilityName"
              element={
                <>
                  <ScrollToTop />
                  <AbilityDetail />
                </>
              }
          />
          <Route
              path="/news"
              element={
                <>
                  <ScrollToTop />
                  <AllNews/>
                  <Footer />
                  <Price createNotification={createNotification} />
                </>
              }
          />
          <Route
              path="/news/:newsName"
              element={
                <>
                  <ScrollToTop />
                  <NewsSample/>
                  <Footer />
                  <Price createNotification={createNotification} />
                </>
              }
          />
          <Route
              path="/streamers"
              element={
                <>
                  <ScrollToTop />
                  <Streamers createNotification={createNotification}  />
                  <Footer />
                  <Price createNotification={createNotification} />
                </>
              }
          />
          <Route
              path="/esports"
              element={
                  <EsportsRedirect/>
              }
          />
          <Route
              path="/esports/:leagueId"
              element={
                <>
                  <Esports/>
                  <Footer />
                  <Price createNotification={createNotification} />
                </>
              }
          >
          <Route path='/esports/:leagueId/:content?/:matchID?' element={
               <>
                <Esports/>
                <Footer />
                <Price createNotification={createNotification} />
              </>
            } />
          </Route>
          <Route path="/airdrop" element={
              <>
                <ScrollToTop />
                <Airdrop/>
                <Footer />
                <Price createNotification={createNotification} />
              </>
          } />
          <Route path="/user/*" element={
            <>
              {!accessToken && <Navigate to="/" />}
              {accessToken &&
                <Routes>
                  <Route path='/profile' element={
                    <>
                      <ScrollToTop />
                      <Profile/>
                      <Footer />
                      <Price createNotification={createNotification} />
                    </>
                  } />
                  <Route path='/profile-settings' element={
                    <>
                      <ScrollToTop />
                      <ProfileSettings setSuccess={setSuccess}/>
                      <Footer />
                      <Price createNotification={createNotification}  />
                    </>
                  } />
                </Routes>
              }
            </>
          } />
          <Route path="*" element={
                <>
                <ScrollToTop />
                <Navigate to='/' />
              </>
            } />
      </Routes>
      
      <NotificationContainer />
    </div>
  );

  function createNotification (type, title, text) {
    switch (type) {
      case "info":
        NotificationManager.info(text, 2000);
        break;
      case "success":
        NotificationManager.success(text, title, 2000);
        break;
      case "warning":
        NotificationManager.warning(
          text,
          title,
          2000
        );
        break;
      case "error":
        NotificationManager.error(text, title, 5000, () => {
          alert("callback");
        });
        break;
      default: 
        break;
    }
  }
}

library.add(fab, far, fas);

export default App;
