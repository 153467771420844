
import gif4c3 from '../../assets/img/loregif/gif4c3.gif';
import gif3c3 from '../../assets/img/loregif/gif3c3.gif';
import gif1c3 from '../../assets/img/loregif/gif1c3.gif';
import gif2c3 from '../../assets/img/loregif/gif2c3.gif';

import '../../assets/css/lore/chapter3.css';

function ChapterThree(props){
    const isMobile = props.mobile;
    return(
        <>
        <div className="col-sm-9">
            <span className='ccsF'>
            Soon enough, news of these unnatural deaths traveled to the kings. Equipped with the wisest men in their kingdoms, the Kings personally came to inspect this mysterious incident and the strange stone behind it. Days, weeks, and months passed. Not a single clue was found. They tried soaking it in different chemicals, burning it, and keeping it in water so it could rust, but all of those attempts proved unsuccessful in causing a reaction. So naturally, the next move of the Kings was to lock the stone away to avoid another unwanted incident. It was taken to a remote location, far from anyone’s eyes or ears, with only three trusted guards to keep it safe. And so, a decade passed.
            </span>
            <br />
            <br />
            <div className="row mob1">
                <div className="col-sm-2">
                    <img className="slika__3" src={gif4c3} alt="gif4c3" />
                </div>
                <div className="col-sm-10">
                    In the meantime, the Three Kingdoms were going through unfortunate changes which, at the time, were thought of as quite natural. The Saidic Kingdom suffered droughts while constant rain flooded the Voinics. Disaster was also present in the Arcidam Kingdom, for they experienced a lack of proper sunlight, which greatly influenced crop growth. Still, the kingdoms managed to survive by sharing what little they had with each other, even though all their stocks were slowly being emptied. No one complained about the state of the world. At the end of the day, it was only natural, and no one was to blame. But none denied that life was becoming a bit more difficult day by day.
                </div>
            </div>
        </div>
        <div className="col-sm-3">
            <img className="firstgif__3" src={gif3c3} alt="gif3c3"/>
        </div>
        <div className="row">
            <div className="col-sm-6">
                <div className="row">
                    <div className="col-sm-9">

                        Yet, despite going through such an unfortunate time, development hadn’t ceased. The Voinics, not being able to enter the flooded mines, focused on refining their crafts and improving their already advanced weapon-making abilities. The Saidics, after experiencing a decrease in student numbers, invested their time in conjuring more potent, grander, and more effective spells for the existing wizards. In that decade of hardship, all kingdoms were slowly advancing towards something new.
                    </div>
                    <div className={!isMobile ? 'col-sm-3' : 'col-sm-3 justify-center row'}>
                        <img className="slika__3" src={gif1c3} alt="gif1c3"/>
                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="row">

                    <div className="col-sm-9">
                        However, no one was aware that, far from the droughts, floods, and other natural disasters, there was one location that prospered, untouched by bad weather or misfortune. Indeed, it was the place where the stone was kept. Those who knew of that small piece of prosperous land had named it holy and would often offer sacrifices to the Gods, wishing that the rest of the world would go back to being as plentiful as it was before. Olaf was the first king to hear rumors of such a spot. He went to investigate and, quickly deducing what was kept underground, rushed to speak with his fellow kings. So, they all went together.
                    </div>
                    <div className={!isMobile ? 'col-sm-3' : 'col-sm-3 justify-center row'}>
                        <img className="slika__3" src={gif2c3} alt="gif2c3"/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ChapterThree;