import { Avatar } from '@mui/material';

import StyledBadge from '../../helpers/StyledBadge';
import TextAreaMui from '../../helpers/TextAreaMui';
import InputMui from '../../helpers/InputMui';

import { Col, Row, Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { faUser, faWallet, faEnvelope, faLock} from '@fortawesome/free-solid-svg-icons';

import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from "../../store/api/axios-users";
import { setAuth } from '../../store/reducers/authReducer';

function ProfileSettings({setSuccess}){
    const {user, profile_picture} = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [inputUsername, changeInputUsername] = useState(user?.username ? user.username : '');
    const [inputEmail, changeInputEmail] = useState(user?.email ? user.email : '');
    const [password, setPassword] = useState('');
    const [file, setFile] = useState(null);
    const [url, setUrl] = useState(profile_picture ? profile_picture : '');
    const [error, setError] = useState('');

    useEffect(() => {
      setError('');
    }, [inputEmail, inputUsername, file])
    
    const validateEmail = (email1) => {
        return String(email1)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const updateProfileImage = () => {
        let el = document.getElementById('settings_input_pp');
        if(el){
            el.click();
        }
    }

    const handleFileSelected = (e): void => {
        const files = Array.from(e.target.files)
        console.log(files[0])
        if(files[0]){
            setFile(files[0]);
            previewFile(files[0]);
        }
    }
    function previewFile(file) {
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            setUrl(reader.result);
        }, false);
      
        if (file) {
          reader.readAsDataURL(file);
        }
    }


    const saveChanges = async () => {
        if(inputEmail !== user?.email || password !== '' || inputUsername !== user?.username || profile_picture !== url){
            if(inputEmail !== '' && inputUsername !== ''){
                if(validateEmail(inputEmail)){
                    try{
                        let form = new FormData();
                        form.append('username', inputUsername);
                        form.append('email', inputEmail);

                        if(password !== ''){
                            form.append('password', password)
                            form.append('password_confirmation', password)
                        }
                        if(file){
                            let formData = new FormData();
                            formData.append('file', file);
                            await axiosInstance.post('set-profile-image', formData);
                        }

                        const result = await axiosInstance.put('users/'+user?.id, form);
                    
                        if(result?.data?.data){
                            dispatch(setAuth(result?.data?.data));
                            setPassword('');
                            changeInputEmail(user?.email ? user.email : '')
                            changeInputUsername(user?.username ? user.username : '')
                        }

                        setSuccess('Account updated')
                    }catch(err){
                        if(err?.response?.data?.message){
                            setError(err?.response?.data?.message)
                        }
                    }
                }else{
                    setError('Invalid email format')
                }
            }else{
                setError('Fill in the form');
            }
        }
    }

    return(
        <section className="account_page pb-5">
            <Container className='display_spacebetween profile_container mb-2'>
                <h6 className='username_profile'>Account Settings</h6>
            </Container>

            <Container className="pb-5">
                <Row style={{gap: '1.5rem'}}>
                    <Col className='bfg_row_desc__home line_container mt-1 ps-4 pe-4 pt-4 pb-4'>
                            <h6 className='h6_profile'>Profile</h6>
                            <Row className='pt-4'>
                                <Col xs={12} sm={4} md={3} lg={3} xl={3} xxl={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                                    <div>
                                        <input id="settings_input_pp" onChange={handleFileSelected} style={{display: 'none'}} type="file" />
                                        <StyledBadge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot"
                                            onClick={() => updateProfileImage()}
                                        >
                                            <Avatar className='profile_avatar' variant="square" src={url} />
                                        </StyledBadge>
                                    </div>
                                </Col>
                                <Col>
                                    <h6 className='sub_profile pb-1'>Username</h6>
                                    <InputMui value={inputUsername} placeholder="Input username..." handleChange={changeInputUsername} icon={faUser}/>
                                    
                                    <h6 className='sub_profile pb-1 pt-3 mt-3'>Email</h6>
                                    <InputMui value={inputEmail} placeholder="Input email..." handleChange={changeInputEmail} icon={faEnvelope}/>

                                     
                                    <h6 className='sub_profile pb-1 pt-3 mt-3'>Password</h6>
                                    <InputMui value={password} placeholder="Input password..." type="password" handleChange={setPassword} icon={faLock}/>
                                    
                                    {/** 
                                    <h6 className='sub_profile pb-1 pt-3 mt-3'>Change Quote</h6>
                                    <TextAreaMui />
                                    */}
                                    <div className='display_flexend pt-5'>
                                        <div>
                                            <div onClick={()=>saveChanges()} className={password !== '' || inputEmail !== user?.email || inputUsername !== user?.username || profile_picture !== url ? 'profile_inventory_btn' : 'disabled_profile_inventory_btn'}>Save Changes</div>
                                            {error !== '' && <div className="p_error pt-3">{error}</div>}
                                        </div>
                                    </div>
                                    
                                </Col>
                                
                            </Row>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4} className='bfg_row_desc__home line_container mt-1 ps-4 pe-4 pt-4 pb-4'>
                            <h6 className='h6_profile'>Wallet</h6>
                            <Row className='pt-4'>
                                <Col>
                                    <h6 className='sub_profile pb-1'>Wallet Address</h6>
                                    <InputMui value={''} placeholder={user?.wallet_address ? user?.wallet_address?.slice(0,12) + '...' + user?.wallet_address?.slice(user?.wallet_address.length-12,user?.wallet_address.length) : 'none'} handleChange={()=>{}} icon={faWallet} readOnly={true}/>
                                </Col>
                            </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ProfileSettings;