import { rootReducer } from './reducer';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import { configureStore } from '@reduxjs/toolkit';
import { api } from './api/api';

const persistConfig = {
    key: 'root',
    storage: localforage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false
      }).concat(api.middleware);
    }
});

let persistor = persistStore(store);

const values = { store, persistor }
export default values;
