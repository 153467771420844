import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accessToken: null,
    user: null,
    profile_picture: '/static/defaultProfilePicture.png'
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setAuthToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setAuth: (state, action) => {
            state.user = action.payload;
            state.profile_picture = action.payload?.profile_picture;
        },
        logOutAuth: (state) => {
            state.user = null;
            state.accessToken = null;
            state.profile_picture = '/static/defaultProfilePicture.png'
        },
        changeProfileAuth: (state, action) => {
            state.profile_picture = action.payload;
        },
    }
});

export const {setAuthToken, setAuth, logOutAuth, changeProfileAuth} = authSlice.actions;

export default authSlice.reducer;