import gif1c2 from '../../assets/img/loregif/gif1c2.gif';
import gif2c2 from '../../assets/img/loregif/gif2c2.gif';
import paprat from '../../assets/img/loregif/paprat.png';
import gif3c2 from '../../assets/img/loregif/gif3c2.gif';
import gif4c2 from '../../assets/img/loregif/gif4c2.gif';

import '../../assets/css/lore/chapter2.css';

function ChapterTwo(props){
    const isMobile = props.mobile;
    return(
        <>
        <div className="col-sm-6 col-sm-6__c2 ">
                <div className="row__c2">
                    <div className="widness__c2 firstletter__c2">
                        Among everything else that was shared, the Three Kingdoms were all using one particular mine for their various everyday needs. Little did they know that one day this mine would show to bear something incredibly precious. On a completely ordinary day - much like any other day - the miners found one extraordinary stone that looked nothing like any other they’d seen before.
                    </div>
                    <div className={!isMobile ? 'col-sm-3 col-sm-3__c2' : 'col-sm-3 col-sm-3__c2 justify-center row__c2'}>
                        <img className="widimg" width="100%" src={gif1c2} alt="gif1c2" style={!isMobile ? {} : {width: '40%', height: ' 100%'}} />
                    </div>
                </div>

                <div className="row">
                    <div className={!isMobile ? 'col-sm-3 col-sm-3__c2' : 'col-sm-3 col-sm-3__c2 justify-center row__c2'}>
                        <img className="widimg" width="100%" src={gif2c2} alt="gif2c2" style={!isMobile ? {} : {width: '40%', height: ' 100%'}} />
                    </div>
                    <div className="col-sm-9__c2 col-sm-9">
                        It glistened with a peculiar dark red color and had an unnatural form. It looked pretty clean and polished when picked up from the dirt as if none of the filth of the mine had ever touched it. Although it was the size of a small child, the stone was incredibly light. Even the oldest miners, the oldest of Voinics, had never witnessed such a sight before. The touch of its surface felt flat and hard, and it had sharp edges here and there. In the hearts of the miners, curiosity rose greater by the minute, and like that, the stone was taken outside the mine.
                    </div>
                </div>
            </div>
            <div className="col-sm-6__c2 col-sm-6">
                <img className="imgcs__c2" v-if="$q.screen.width > 900" src={paprat} alt="paprat" />
                <div className="row__c2 custommar__c2">
                    <div className={!isMobile ? 'col-sm-3 col-sm-3__c2' : 'col-sm-3 col-sm-3__c2 justify-center row'}>
                        <img className="widimg__c2" width="100%" src={gif3c2} alt="gif3c2" style={!isMobile ? {} : {width: '40%', height: ' 100%'}} />
                    </div>
                    <div className="col-sm-9__c2 col-sm-9">
                        Wishing to inspect it further, the miners tried to break it. One of them was set to hold it firmly, and a few others swung at it with their hammers. It’s fair to say that no one expected the outcome that followed. On the very first hit, the stone released a blast, and two of the miners died instantly. A great confusion burst out among those who stood watching. At first, they were too stunned to even say a word. Yet, a few moments later, they noticed that the miner holding the stone was standing as if nothing had happened, untouched and unharmed. At that moment, no one dared go near him, and they avoided the stone just as well.
                    </div>

                </div>

            </div>
            <div className="row__c2 customwidrth__c2">
                <div className={!isMobile ? 'col-sm-3 col-sm-3__c2' : 'col-sm-3 col-sm-3__c2 justify-center row'}>
                    <img className="widimg__c2" width="100%" src={gif4c2} alt="gif4c2" style={!isMobile ? {} : {width: '40%', height: ' 100%'}} />
                </div>
                <div className="col-sm-9__c2 col-sm-9">
                    Of course, the people who witnessed this phenomenon quickly assumed that a mysterious power was guarding the strange stone and that it could sense the emotions of those near it. They concluded the following: if approached with ill intent, the stone strikes you; if you were to mean it no harm, only touch or carry it, nothing would happen, and the stone stays still.
                </div>

            </div>
        </>
    )
}

export default ChapterTwo;