import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/system';
import { forwardRef } from 'react';

const Input = forwardRef(function CustomInput(props, ref) {
  return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
});

export default function TextAreaMui() {

  return <Input aria-label="text-area-mui" placeholder="Type something…" />;
}


const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#39325A',
};

const InputElement = styled('textarea')(
  ({ theme }) => `
  width: 100%;
  height: 250px;
  font-family: 'monserat-regular';
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #FFFFFF;
  box-shadow: 6px 5px 4px 2px #0000007a;
  background: ${grey[900]};
  border: none;

  &:hover {
    border-color: transparent;
  }

  &:focus {
    border-color: transparent;
    box-shadow: 6px 5px 4px 2px #0000007a;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);