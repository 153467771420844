import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from "react";

function TeamComponent(props){
    const [loaded, setLoaded] = useState(false);
    const { name, position, linkedin, link, img} = props.values;
    function openLink(link){
        window.open(link, '_blank');
    }
    return(
        <Col onClick={() => openLink(link)} className={loaded ? 'team-col' : ''} md={3} sm={4} xs={6} xxs={12} lg={3}>
            <img onLoad={() => setLoaded(true)} className="imgContainer" src={img} alt={name} width={100}/>
            {loaded &&
            <div className="shadow-bottom">
                <h6 className="name-team">{name}</h6>
                {linkedin &&
                    <FontAwesomeIcon className="pb-1" icon='fa-brands fa-linkedin'/>
                }
                {!linkedin &&
                    <FontAwesomeIcon className="pb-1" icon='fa-solid fa-link'/>
                }
                <p className="position">{position}</p>
            </div>}
            {/* 
            <div>
                <h6>{name}</h6>
                <p>{position}</p>
            </div>
            */}
        </Col>
    )
}

export default TeamComponent;