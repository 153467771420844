import { retry } from '@reduxjs/toolkit/query/react';
import { api } from './api';

export const esportsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLeagues: build.mutation({
      query: () => ({
        url: 'leagues',
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getRegions: build.mutation({
      query: () => ({
        url: 'league-region',
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getLeagueById: build.mutation({
      query: (id) => ({
        url: `/leagues/${id}`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getMatches: build.mutation({
      query: (l_id) => ({
        url: `/leagues/${l_id}/matches?group_by_date=true&sort_by_starts_at=desc`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getMatchesBefore: build.mutation({
      query: ({l_id, time}) => ({
        url: `/leagues/${l_id}/matches?starts_at_before=${time}&group_by_date=true&sort_by_starts_at=desc&is_finished=true`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getMatchesAfter: build.mutation({
      query: ({l_id, time}) => ({
        url: `/leagues/${l_id}/matches?starts_at_after=${time}&group_by_date=true&sort_by_starts_at=desc&is_finished=true`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getAllTeams: build.mutation({
      query: () => ({
        url: `/league-region-team`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getCurrentMatch: build.mutation({
      query: (leagueId) => ({
        url: `/current-match?league_id=${leagueId}&region_id=1`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getGroupStageRanksLeagueRegion: build.mutation({
      query: ({leagueId, regionId}) => ({
        url: `/league/${leagueId}/region/${regionId}/group-stage-rank-list`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getGroupStageMatchesLeagueRegion: build.mutation({
      query: ({leagueId, regionId}) => ({
        url: `/league/${leagueId}/region/${regionId}/matches`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getPlayOffMatchesLeagueRegion: build.mutation({
      query: ({leagueId, regionId}) => ({
        url: `/league/${leagueId}/region/${regionId}/playoff-matches?sort_by=display_order&direction=asc`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
    getLeagueResults: build.mutation({
      query: ({leagueId, regionId}) => ({
        url: `/league-region-results?league_id=${leagueId}&region_id=${regionId}`,
        method: 'GET',
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.error;
      },
      extraOptions: {
        backoff: () => {
          retry.fail('Failed to fetch user');
        }
      }
    }),
  })
});

export const {
  useGetLeagueResultsMutation,
  useGetPlayOffMatchesLeagueRegionMutation,
  useGetGroupStageMatchesLeagueRegionMutation,
  useGetGroupStageRanksLeagueRegionMutation,
  useGetCurrentMatchMutation,
  useGetAllTeamsMutation,
  useGetMatchesAfterMutation,
  useGetMatchesBeforeMutation,
  useGetMatchesMutation,
  useGetRegionsMutation,
  useGetLeagueByIdMutation,
  useGetLeaguesMutation
} = esportsApi;

export const {
  endpoints: { getLeagues }
} = esportsApi;
