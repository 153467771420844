import { Badge, styled } from "@mui/material";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      color: '#fff',
      top: '-1.4rem',
      right: '2rem',
      cursor: 'pointer',
      backgroundColor: '#918E9B',
      border: '1px solid white',
      padding: '1rem', 
      fontSize: '1.2rem',
      '&::after': {
        position: 'absolute',
        top: '70%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        content: "'\\270E'",
      },
      '&:hover': {
        transform: 'translate(50%, 50%) scale(1.1)',
      }
    },
}));

export default StyledBadge;