import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faChevronLeft, faChevronRight, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";

function PaginationComponent({data, profile}){

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(profile ? 8 : 8);
    const [totalPages, setTotalPages] = useState(Math.ceil(data.length / perPage));

    const changePage = (value) => {
        let toInt = parseInt(value)
        if(toInt <= 0){
            setPage(1)
        }
        if(toInt > totalPages){
            setPage(totalPages)
        }
        else{
            setPage(toInt)
        }
    }

    const arrowPage = (type) => {
        if(type === 'reduce'){
            if(page > 1){
                setPage(page-1)
            }
        }else{
            if(page < totalPages){
                setPage(page+1)
            }
        }
    }
    return(
        <Container className="cont__pag" style={profile ? {maxWidth: '95%', position: 'relative'} : {maxWidth: '75%', position: 'relative'}}>
        {/* PAGINATION */}
        <Row>
            <div className="displayColJustifyCenter pt-5 pb-4">
                <div className="display_flexstart">
                    <div onClick={() => arrowPage('reduce')} className={(page-1 <=0 ? 'disabledbtn__mp' : 'arrowContainer__mp')}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <div className="page_change_container__mp">
                       <div> Page </div> <input onChange={e => changePage(e.target.value)} type="number" className="inputPagination__mp ms-2 me-2" value={page} /> <div> of {totalPages} </div>  
                    </div>
                    <div onClick={() => arrowPage('add')}  className={(page+1 > totalPages ? 'disabledbtn__mp' : 'arrowContainer__mp')}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </div>
            </div>

            <div className="display_flexstart" style={{flexWrap: 'wrap', gap: '.6rem', minHeight: '40vw'}}>
                {data.length > 0 && data.slice((page-1)*perPage,page*perPage).map(s =>
                        <div key={s.id} className="card_listings__mpheroes">
                            <div className="card_imgnft__mp">
                                <img src={s.imgNFT} alt="nft" style={{width: '100%'}} />
                            </div>
                            <div className="card_infonft__mp">
                                <div className="displayColJustifyCenter">
                                    <div className="className__mp ps-2 pe-2">{s.class.charAt(0).toUpperCase() + s.class.substring(1, s.class.length).toLowerCase()}</div>
                                    <div className="heroId_mp"> {s.id} </div>
                                </div>
                                {!profile &&
                                    <div className="full-width display_spacebetween">
                                        <div className="soldTime__mp">{s.time}</div>
                                        <div>
                                            <FontAwesomeIcon icon={faHeart} />
                                        </div>
                                    </div>
                                }
                                {profile &&
                                    <>
                                        {s.onSale && 
                                            <div className="full-width display_spacebetween onsale__pagination">
                                                <div>On Sale</div>
                                                <div className="displayColJustifyCenter">
                                                    {s.priceBFG + ' BFG'}
                                                    <div>
                                                        <FontAwesomeIcon className="onsale_x__pagination" icon={faX} />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {!s.onSale && 
                                            <div className="full-width display_spacebetween addsale__pagination">
                                                <div>Add For Sale</div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>    
                    )}

                {!data.length &&
                    <div class="no_text">No items in the inventory for display.</div>
                }
            </div>

        </Row>
    </Container>
    )
}

export default PaginationComponent;