import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/base/Button';
import { Input as BaseInput, inputClasses } from '@mui/base/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';

const Input = React.forwardRef(function CustomInput(props, ref) {
  const { slots, ...other } = props;
  return (
    <BaseInput
      slots={{
        root: InputRoot,
        input: InputElement,
        ...slots,
      }}
      {...other}
      ref={ref}
    />
  );
});

Input.propTypes = {
  slots: PropTypes.shape({
    input: PropTypes.elementType,
    root: PropTypes.elementType,
    textarea: PropTypes.elementType,
  }),
};

export default function InputMui({icon, value, handleChange, placeholder, readOnly, iconsBack, otherChange, hasType, disabled}) {
  return (
      <Input
        disabled={disabled}
        type={hasType ? hasType : 'text'}
        readOnly={readOnly}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        startAdornment={ icon ? 
          <InputAdornment>
            <IconButton
              size="small"
              aria-label="user user-icon"
            >
              {icon &&
                <FontAwesomeIcon icon={icon} />
              }
            </IconButton>
          </InputAdornment> 
          :
          null
        }
        endAdornment={
          iconsBack ?
            iconsBack.map(x =>
              <InputAdornment key={x.id}>
                <IconButton
                  size="small"
                  className='iconsRight__input'
                  aria-label="user user-icon"
                >
                  {x.icon &&
                    <FontAwesomeIcon icon={x.icon} />
                  }
                </IconButton>
              </InputAdornment> 
            )
          :
          null
        }
      />
    
  );
}


const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#39325A',
};

const InputRoot = styled('div')(
  ({ theme }) => `
  font-family: 'monserat-regular';
  border-radius: 8px;
  color: #FFFFFF !important;
  background: ${grey[900]};
  border: none;
  box-shadow: 6px 5px 4px 2px #0000007a;
  display: flex;
  align-items: center;
  justify-content: center;

  &.${inputClasses.focused} {
    border-color: transparent;
    box-shadow: 6px 5px 4px 2px #0000007a;
  }

  &:hover {
    border-color: transparent;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const InputElement = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  color: #FFFFFF;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`,
);

const IconButton = styled(Button)(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  color: #ffffffa6;
  `,
);

const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;