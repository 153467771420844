import { Col, Container, Row } from "react-bootstrap";

const first_section = [
    {id: 0, img: '/static/esports/tour1.jpg', text: "<p class='ps-4'> A few crypto YouTubers and influencers post about how much money they’re making with one hour of gameplay, and a horde of investors try to ride the wave. However, that often leads to losing money and feeling angry for aping your way in a project without doing proper research. <br/><br/> Whenever there’s a lot of inorganic media momentum, it’s probably time to cash out from a specific project. Coming in at peak prices will make you feel left out in the cold when the bull market ends. Remember, everyone who rides the wave will not be loyal long-term. </p>"},
    {id: 1, img: '/static/esports/tour2.jpg', text: "<p class='pe-4'> <span class='indside-h__esports'>That’s why Battle For Giostone is different.</span> <br/> One of the things that Web3 games are missing is competitiveness. Players want to prove they’re the best in the whole world. They want the spotlight, but you need to give them the stairs that help them climb to the top. That’s why having an e-sports infrastructure from the start is crucial. </p>"}
]

const second_section = [
    {id: 0, img: '/static/esports/tour3.jpg', text: "<p class='pe-4'> In a dozen years, these two games have amassed massive audiences. Each month they gobble up more than 10 000 years of watchtime on Twitch alone. <br/> <br/> MOBA is the best game genre because something’s always happening at some part of the map. There are five people on each team. At least one of them will try to do something crazy. They might try to steal the jungle monster of an enemy Hero. They might try to gank, tower dive, or lure an opponent into a trap. </p>"},
    {id: 1, img: '/static/esports/tour5.jpg', text: "<p class='ps-4'> Traditional sports like football have fouls, halftime breaks, and boring moments. None of that is present in a MOBA. The developers at Battle For Giostone are creating a game that will be just as fun to watch as it is to play yourself. <br/> <br/> Including casters into the mix makes tournaments an even better experience to enjoy. Some of the most popular casters are qu1ksh0t and Blaze. We can’t wait to see how internet personalities will talk about BFG. </p>"}  
]

function Information(){
    return(
        <div className='section-info__esports'>
            <div className='header-info__esports'>
                <div className="hover-color__esports"></div>
                <Container className="after-hover-color__esports">
                    <Row className="row-info__esport_resp">
                        <Col className="col_heading_i__esports">
                            <h1 className="h1-info__esports">Why E-Sports Will Skyrocket Battle For Giostone?</h1>
                        </Col>
                        <Col style={{display:'flex', alignItems: 'center'}}>
                            <p className="p-info__esports">
                                Traditional sports like football have fouls, halftime breaks, and boring moments. None of that is present in a MOBA. The developers at Battle For Giostone are creating a game that will be just as fun to watch as it is to play yourself.
                                <br/> <br/>
                                Including casters into the mix makes tournaments an even better experience to enjoy. Some of the most popular casters are qu1ksh0t and Blaze. We can’t wait to see how internet personalities will talk about BFG.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container className="inner_info__esports_container" style={{padding: '4% 0%', overflow: 'hidden'}}>
                    {first_section && 
                        first_section.map(x => 
                           <Row className={x.id === 1 ? 'row-info__esport_resp reverse__esports' : 'row-info__esport_resp'} key={x.id}>
                                {x.id === 0 &&
                                <>
                                    <Col className="img-cont-res__esports" style={{justifyContent: 'flex-end', display: 'flex', padding: 0}}>
                                        <img className="img-info-resp" style={{width: '70%'}} src={x.img} alt='img-info' />
                                    </Col>
                                    <Col style={{justifyContent: 'flex-start'}} className="p-info-next__esports" dangerouslySetInnerHTML={{__html: x.text }}></Col>
                                </>
                                }
                                {x.id === 1 &&
                                <>
                                    <Col style={{justifyContent: 'flex-end'}} className="p-info-next__esports" dangerouslySetInnerHTML={{__html: x.text }}></Col>
                                    <Col className="img-cont-res__esports" style={{justifyContent: 'flex-start', display: 'flex', padding: 0}}>
                                        <img className="img-info-resp" style={{width: '70%'}} src={x.img} alt='img-info' />
                                    </Col>
                                </>
                                }
                           </Row>     
                        )
                    }
                </Container>
            </div>
            <div className='header-info__esports' style={{backgroundImage: "url('/static/esports/tour6.jpg')"}}>
                <div className="hover-color__esports"></div>
                <Container className="after-hover-color__esports">
                    <Row className="row-info__esport_resp">
                        <Col className="col_heading_i__esports">
                            <h1 className="h1-info__esports">MOBA is the key to success</h1>
                        </Col>
                        <Col style={{display:'flex', alignItems: 'center'}}>
                            <p className="p-info__esports">
                                Battle For Giostone follows in the footsteps of two of the greatest games ever to exist: League of Legends and Dota2. These two games paved the way for e-sports and popularized the niche to the entire world. Now, it’s time for their Web3 counterpart to take over the scene.
                                <br/> <br/>
                                Here’s a bit of history. League of Legends started all the way back in 2009. By coincidence, that’s the year when Bitcoin was launched. Two years later, Dota2 entered the scene with a one-million dollar e-sports tournament – The International.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container className="inner_info__esports_container" style={{padding: '4% 0%', overflow: 'hidden'}}>
                    {second_section && 
                        second_section.map(x => 
                           <Row className={x.id === 0 ? 'row-info__esport_resp reverse__esports' : 'row-info__esport_resp'} key={x.id}>
                                {x.id === 1 &&
                                <>
                                    <Col className="img-cont-res__esports" style={{justifyContent: 'flex-end', display: 'flex', padding: 0}}>
                                        <img className="img-info-resp" style={{width: '70%'}} src={x.img} alt='img-info' />
                                    </Col>
                                    <Col style={{justifyContent: 'flex-start'}} className="p-info-next__esports" dangerouslySetInnerHTML={{__html: x.text }}></Col>
                                </>
                                }
                                {x.id === 0 &&
                                <>
                                    <Col style={{justifyContent: 'flex-end'}} className="p-info-next__esports" dangerouslySetInnerHTML={{__html: x.text }}></Col>
                                    <Col className="img-cont-res__esports" style={{justifyContent: 'flex-start', display: 'flex', padding: 0}}>
                                        <img className="img-info-resp" style={{width: '70%'}} src={x.img} alt='img-info' />
                                    </Col>
                                </>
                                }
                           </Row>     
                        )
                    }
                </Container>
            </div>
        </div>
    )
}

export default Information;