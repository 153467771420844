
function AbilityIndexComponent(props) {
    const public_url = process.env.PUBLIC_URL;
    const main = props.main;

    return(
        <a className={main ? 'link-element' : 'link-element ab-element'} href={public_url+'ability/'+props.x.routeUrl.replaceAll(' ','-')} style={{backgroundImage: 'url('+props.x.imgIcon+')'}} >
            <div className="bottom">
                <div className="text-bottom">{props.x.name.toUpperCase()}</div>
            </div>
        </a>
    )
}

export default AbilityIndexComponent;