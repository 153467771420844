import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import elixir from '../assets/img/elixir-icon.webp';
import owl from '../assets/img/icons/core_owl.png';
import logoBfg from '../assets/img/bfg-logo.png';
import logoMtech from '../assets/img/m-tech-logo.png';

import { useState } from 'react';
import { createBrowserHistory } from "history";
import ThirdwebBtn from '../helpers/thirdweb_btn';

function NavBar({getEsports, setError, setSuccess}) {
    const history = createBrowserHistory()

    const [isTop, changeTop] = useState(false);
    const [isMobile, changeMobile] = useState(false);
    const [show, setShow] = useState(false);

    const toggleDropdown = (e)=>{
        if(isMobile){
            if(show){
                setShow(false);
            }else{
                setShow(true);
            }
        }
    }

    const showDropdown = e => {
        if(!isMobile){
            setShow(true);
        }
    }

    const hideDropdown = e => {
        if(!isMobile){
            setShow(false);
        }
    }

    function changeTopValue() {
        if (window.scrollY > 250){
            changeTop(true);
        }else{
            changeTop(false);
        }
    }

    function changeMobileValue() {
        if (window.innerWidth >= 1200){
            changeMobile(false);
        }else{
            changeMobile(true);
        }
    }

    
    const goTo = (link) => {
        window.open(link, '_blank');
    }
    
    window.addEventListener('scroll', changeTopValue);
    window.addEventListener('resize', changeMobileValue);
    window.addEventListener('load', changeMobileValue);
    return (
        <Navbar collapseOnSelect fixed={history.location.pathname === '/' ? !isTop ? 'top' : '' : ''} sticky={history.location.pathname === '/' ? isTop ? 'top' : '' : 'top'} data-bs-theme="dark" expand="xl">
        <Container fluid>
            <div className='icons ps-3'>
                <Navbar.Brand target="_blank" href="https://mtech.mk/">
                    <img
                        src={logoMtech}
                        width="35"
                        height="42"
                        className="d-inline-block align-top mtech_nav"
                        alt="Mtech logo"
                    />
                </Navbar.Brand>
                <Navbar.Brand href="/">
                    <img
                        src={logoBfg}
                        width="45"
                        height="50"
                        className="d-inline-block align-top bfg_nav"
                        alt="Battle For Giostone logo"
                    />
                </Navbar.Brand>
            </div>
            <div className="gap-nav">
                <Navbar.Offcanvas 
                    data-bs-theme="dark"
                    id="offcanvasNavbar-expand-xl"
                    aria-labelledby="offcanvasNavbarLabel-expand-xl"
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <div id="offcanvasNavbarLabel-expand-xl" className='d-flex icons gap-2 ps-3'>
                            <Navbar.Brand target="_blank" href="https://mtech.mk/">
                                <img
                                    src={logoMtech}
                                    width="35"
                                    height="42"
                                    className="d-inline-block align-top"
                                    alt="MTech logo"
                                />
                            </Navbar.Brand>
                            <Navbar.Brand href="/">
                                <img
                                    src={logoBfg}
                                    width="45"
                                    height="50"
                                    className="d-inline-block align-top"
                                    alt="Battle For Giostone logo"
                                />
                            </Navbar.Brand>
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav
                        className="justify-content-start nav_mobile flex-grow-1 ps-3"
                        variant={!isMobile ? 'underline' : ''}
                        defaultActiveKey={history.location.pathname}
                        >
                            <NavDropdown 
                            title="GAME" 
                            show={show}
                            onMouseEnter={showDropdown} 
                            onMouseLeave={hideDropdown}
                            onClick={toggleDropdown}
                            >
                                <NavDropdown.Item id="nav-dropdown" href="/heroes/akuma">HEROES</NavDropdown.Item>
                                <NavDropdown.Item href="/abilities">
                                    ABILITIES
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/lore">
                                    LORE
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/news">NEWS</Nav.Link>
                            {/* 
                                <Nav.Link href="/tokenomics">TOKENOMICS</Nav.Link>
                                <Nav.Link target='_blank' href="https://forum.battleforgiostone.com/">FORUM</Nav.Link>
                                <Nav.Link target='_blank'  href="https://web3.battleforgiostone.com">PANEL</Nav.Link>
                                <Nav.Link href="/airdrop">AIRDROP</Nav.Link>
                            */}
                            <Nav.Link href="/esports">ESPORTS</Nav.Link>
                            <Nav.Link target='_blank' href="https://whitepaper.battleforgiostone.com/">WHITEPAPER</Nav.Link>
                            <Nav.Link href="/streamers">STREAMERS</Nav.Link>
                            <Nav.Link href="/about">ABOUT</Nav.Link>
                            
                        </Nav>
                        <Form className="d-flex" style={{alignItems: 'center'}}>
                            {/* 
                            <div className='owl-Content'>
                                <div className="owlCoreText">CORE</div>
                                <img onClick={() => goTo('https://chromewebstore.google.com/detail/core-crypto-wallet-nft-ex/agoakfejjabomempkjlepdflaleeobhb')} className='logoOwl' alt="Core Wallet logo" src={owl} />
                            </div>*/}
                            <Button style={{width: '100%'}} onClick={() => goTo('https://elixir.app/')} className='playButton' variant="outline-light">
                                    <div className='button-logo logo-nav-button'>
                                        <img className='logoElixir' alt="Elixir logo" src={elixir} />
                                        <div>
                                            <div>PLAY FOR FREE</div>
                                    </div>
                                    </div>
                            </Button>
                        </Form>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            
                <div className="gap-nav1">
                    <ThirdwebBtn setError={setError} setSuccess={setSuccess}/>
                    <Navbar.Toggle aria-controls="offcanvasNavbar-expand-xl"/>
                </div>
            </div>
        </Container>
        </Navbar>
    );
}

export default NavBar;