import { Col, Container, Row } from "react-bootstrap";

function getMonth(month){
    switch (month) {
    case "12": return 'Dec'; 
    case "01": return 'Jan';
    case "02": return 'Feb'; 
    case "03": return 'Mar';
    case "04": return 'Apr'; 
    case "05": return 'May'; 
    case "06": return 'Jun';
    case "07": return 'Jul';
    case "08": return 'Aug';
    case "09": return 'Sep';
    case "10": return 'Oct';
    case "11": return 'Nov';
    default:  return '';
    }
}
function convertRange(a,b){
    if(a!=='' && b!== ''){
        let dateA = a.split('T')[0];
        let dateB = b.split('T')[0];
        if(dateA.split('-')[1] !== dateB.split('-')[1]){
            return getMonth(dateA.split('-')[1]) + " " + dateA.split('-')[2] + " - " +
            getMonth(dateB.split('-')[1]) + " " + dateB.split('-')[2]
        }
        return getMonth(dateA.split('-')[1]) + " " + dateA.split('-')[2] + " - " + dateB.split('-')[2]
    }
    return ""
}

function EsportsHeading({league, regions}){
    return(
        <div style={{ backgroundImage : `url(/static/esports/${league.id}.jpg)`}} className="heading-layer__esports">
            <Container fluid style={{height: '100%'}}>
                <Row style={{height: '100%'}}>
                    <Col className="justify-center__esport side-headers__esport">
                    {league.group_stage_to !== '' &&
                        <div>
                            <h6 className="stages__esport">GROUP STAGE</h6>
                            <h4 className="stage_content__esport">{convertRange(league.group_stage_from, league.group_stage_to)}</h4>
                        </div>
                    }
                    {league.playoff_from !== '' &&
                        <div>
                            <h6 className="stages__esport">PLAYOFF</h6>
                            <h4 className="stage_content__esport">{convertRange(league.playoff_from, league.playoff_to)}</h4>
                        </div>
                    }
                    {league.finals_from !== '' &&
                        <div>
                            <h6 className="stages__esport">FINALS</h6>
                            <h4 className="stage_content__esport">{convertRange(league.finals_from, league.finals_to)}</h4>
                        </div>
                    }
                        
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} className="main-header-title__esport justify-center__esport">
                        <div>
                            <h4 className="stage_content__esport title_heading__esport">{league.name.toUpperCase()}</h4>
                            <h6 className="stages__esport" style={{marginTop: '0'}}>SEASON {league.season}</h6>
                        </div>
                    </Col>
                    <Col className="justify-center__esport side-headers__esport">
                        {league.prize_pool !== '' &&
                            <div>
                                <h6 className="stages__esport">TOTAL PRIZE POOL</h6>
                                <h4 className="stage_content__esport">$ {league.prize_pool.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</h4>
                            </div>
                        }
                        {regions &&
                            <>
                                <h6 className="stages__esport">REGIONS</h6>
                                <h4 className="stage_content__esport">
                                {
                                    regions.filter(x => {return x.league.id === league.id}).map(x => 
                                        <span key={x.region.id}> {x.region.name}</span>
                                    )
                                }
                                </h4>
                            </>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default EsportsHeading;