import defaultImg from "../assets/img/default-img.jpg";

function NewsComponent(props) {
    const data = props.items_data;
    const id = props.id;
    const importance = props.importance
    const isPagination = props.isPagination;

    function  hovers (id) {
        var element = document.getElementById(id)
        if(element){
            if (element.classList.contains('shows')) {
                element.classList.remove('shows')
            } else {
                element.classList.add('shows')
            }
        }
    }

    const getDate1 = (timestamp) => {
        var date = new Date(timestamp * 1000);
        // eslint-disable-next-line no-unused-expressions
        date.getMonth() + 1;
        var str = (date.toLocaleString('en-us', {
            month: 'long'
        })).toUpperCase() + " " + date.getDate() + ', ' + date.getFullYear();
        return str;
    }

    return (
        <a className={isPagination ? 'blog-pagination' : 'blog'} href={'/news/'+data.routeUrl}>
            {importance && <div className="importance"><span> {importance} </span><span className='dates_news'>{data.date.seconds ? getDate1(data.date.seconds) : ''}</span></div>}
            <div onMouseEnter={() => hovers(id)} onMouseLeave={() => hovers(id)} id={id} className="blogcapsule" key={id}>
                <img className="blogcapsule_img" src={data.coverImg ? data.coverImg : defaultImg} alt="coverImg"/>
                <div className="fade-container">
                    <div className="fade__news">
                    </div>
                </div>

                <div className="blogcapsule_desc" dangerouslySetInnerHTML={{ __html: data.content !== '' ? data.content.substring(0, 130).concat('...') : '' }}>
                </div>

                <div className={isPagination ? 'blogcapsule_date_pagination' : 'blogcapsule_date'}>
                    {importance ? '' : data.date.seconds ? getDate1(data.date.seconds) : ''}
                </div>
                <div className={isPagination ? 'blogcapsule_title_pagination' : 'blogcapsule_title'}>
                    {data.title}
                </div>
            </div>
        </a>
    )
}

export default NewsComponent;