import { useEffect, useState } from "react";
import bfgLogoLore from '../assets/img/bfg-logo-lore.png';
import ChapterOne from "../components/lore/ChapterOne";
import ChapterTwo from "../components/lore/ChapterTwo";
import ChapterThree from "../components/lore/ChapterThree";
import ChapterFour from "../components/lore/ChapterFour";
import ChapterFive from "../components/lore/ChapterFive";
import ChapterSix from "../components/lore/ChapterSix";
import ChapterSeven from "../components/lore/ChapterSeven";
import ChapterEight from "../components/lore/ChapterEight";
import ChapterNine from "../components/lore/ChapterNine";
import ChapterTen from "../components/lore/ChapterTen";
import { Col, Container, Row } from "react-bootstrap";

import arrowR from "../assets/img/icons/leftarrow.png";
import arrowL from "../assets/img/icons/rightarrow.png";

const chapters = [{
    id: 0,
    chapter: '0',
    content: 'some content',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'ONCE UPON A TIME  THREE KINGDOMS KEPT THE PEACE 1',
}, {
    id: 1,
    active: true,
    chapter: 'I',
    content: 'some content 1',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'THE LEGACY OF THE THREE KINGS',
},
{
    id: 2,
    active: true,
    chapter: 'II',
    content: 'some content 2',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'A STONE SHROUDED IN DIRT, MYSTERY AND POWER',
},
{
    id: 3,
    active: true,
    chapter: 'III',
    content: 'some content 3',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'THE AGE OF HARDSHIP, SUFFERING AND MISFORTUNE',
},
{
    id: 4,
    active: true,
    chapter: 'IV',
    content: 'some content 4',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'THE CLANDESTINE WHEREABOUTS OF ABUNDANCE',
},
{
    id: 5,
    active: true,
    chapter: 'V',
    content: 'MINDS DEVOID OF ALL REASON',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'MINDS DEVOID OF ALL REASON',
},
{
    id: 6,
    active: true,
    chapter: 'VI',
    content: 'FRACTIONED POWER',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'FRACTIONED POWER',
},
{
    id: 7,
    active: true,
    chapter: 'VII',
    content: 'some content 7',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'THE END OF THE GOLDEN ERA',
},
{
    id: 8,
    active: true,
    chapter: 'VIII',
    content: 'some content 8',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'THE GREATNES OF THE KINGS',
},
{
    id: 9,
    active: true,
    chapter: 'IX',
    content: 'some content 9',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'THE GIOSTONE',
},
{
    id: 10,
    active: true,
    chapter: 'X',
    content: 'some content 10',
    img: 'https://firebasestorage.googleapis.com/v0/b/bfg-lore.appspot.com/o/ilustrations%2Fchapter1.png?alt=media&token=645dcde8-8f80-4296-836d-fe61b2c237b0',
    title: 'THE DECCAN AND THE GLACCIC',
},

]
function Lore(){
    const [title, setTitle] = useState('BATTLE FOR GIOSTONE');
    const [start, setStart] = useState(true);
    const [currNumber, setCurrNumber] = useState(0);
    const [showtext, setShowText] = useState(false);
    const [mobile, setMobile] = useState(false);

    function moveToTight(id) {
        setCurrNumber(1);
        setStart(!start);
        setTitle(chapters[currNumber].title)

        var element = document.getElementById(id);
        var one = document.getElementById('i1');
        one.style.color = "#f1f1f1"
        var two = document.getElementById('i2');
        var tree = document.getElementById('i3');
        var svg = document.getElementsByClassName('path1');
        var basicDes = document.getElementsByClassName('basicDes');
        if (element.classList.contains('showsA')) {
            element.classList.remove("showsA");
            element.classList.add("shows1");
            one.classList.remove('one')
            two.classList.remove('two')
            tree.classList.remove('tree')
            Array.from(svg).forEach((svg, index) => {
                svg.classList.remove('path')
            });
            Array.from(basicDes).forEach((basicDes, index) => {
                basicDes.removeAttribute('class')
                basicDes.classList.add('basicDes')
            });
            setShowText(false)
            setTitle('BATTLE FOR GIOSTONE')
        } else if (element.classList.contains("shows1")) {
            setShowText(true)
            element.classList.remove('startLogo')
            setTitle(chapters[currNumber].title)
            Array.from(basicDes).forEach((basicDes, index) => {
                basicDes.removeAttribute('class')
                basicDes.classList.add('basicDes')
            });
            element.classList.remove("shows1");
            element.classList.add("showsA");
            one.classList.add('one')
            two.classList.add('two')
            tree.classList.add('tree')
            Array.from(svg).forEach((svg, index) => {
                svg.classList.add('path')
            });
            var active = document.getElementsByClassName('active2');
            Array.from(active).forEach((active, index) => {
                active.classList.remove('active2')
            });

        } else {
            setShowText(true)
            element.classList.remove('startLogo')
            setTitle(chapters[currNumber].title)
            element.classList.add("showsA");
            one.classList.add('one')
            two.classList.add('two')
            tree.classList.add('tree')
            Array.from(svg).forEach((svg, index) => {
                svg.classList.add('path')
            });
        }

    }

    function chapterClick(ids) {
        var one = document.getElementById('i1');
        one.style.color = "#1d050f"

        setCurrNumber(parseInt(ids));
        var prevF = parseInt(ids) - 1;
        var nextF = parseInt(ids) + 1;
        var lastF = parseInt(ids) + 2;
        var firstF = parseInt(ids) - 2;
        
        setTitle(chapters[ids].title)
        
        var id = 'i' + ids;
        var curr = document.getElementById(id);
        var prev = document.getElementById('i' + prevF)
        var next = document.getElementById('i' + nextF)
        var last = document.getElementById('i' + lastF)
        var first = document.getElementById('i' + firstF)

        var active = document.getElementsByClassName('active1');

        Array.from(active).forEach((active, index) => {
            active.classList.remove('active1')
        });

        var hidens = document.getElementsByClassName('hidens');
        var hidensb = document.getElementsByClassName('hidensb');
        Array.from(hidensb).forEach((hidensb, index) => {
            hidensb.classList.remove('hidensb')
        });
        Array.from(hidens).forEach((hidens, index) => {
            hidens.classList.remove('hidens')
        });
        Array.from(hidens).forEach((hidens, index) => {
            hidens.classList.remove('hidens')
        });
        Array.from(hidensb).forEach((hidensb, index) => {
            hidensb.classList.remove('hidensb')
        });
        Array.from(hidensb).forEach((hidensb, index) => {
            hidensb.classList.remove('hidensb')
        });

        if (curr.classList.contains('tree') || curr.classList.contains('ntree') || curr.classList.contains('nextb')) {
            if (nextF <= 10) { // counterut e kolku chapters ima
                curr.classList.remove('tree')
                curr.classList.remove('ntree')
                curr.classList.remove('nextb')
                curr.classList.add('active1')
                curr.classList.add('active2')
                prev.classList.remove('two')
                prev.classList.remove('active2')
                prev.classList.remove('ntree')
                prev.classList.remove('nextb')
                prev.classList.remove('rbacks')
                prev.classList.add('backs')
                next.classList.remove('somr') // za koa veke ke s izvrte edns
                next.classList.add('ntree')
                first.classList.remove('one') // ili dr klasa 
                first.classList.remove('backs')
                first.classList.remove('rhidens')

                Array.from(hidens).forEach((hidens, index) => {
                    hidens.classList.remove('hidens')
                });
                first.classList.add('hidens')
            } else {
                curr.classList.remove('tree')
                curr.classList.remove('ntree')
                curr.classList.remove('nextb')
                curr.classList.add('active1')
                curr.classList.add('active2')
                prev.classList.remove('two')
                prev.classList.remove('active2')
                prev.classList.add('backs')
                first.classList.remove('one') // ili dr klasa 
                first.classList.remove('backs')
                first.classList.remove('rhidens')

                Array.from(hidens).forEach((hidens, index) => {
                    hidens.classList.remove('hidens')
                });
                first.classList.add('hidens')
            }
        }
        if (curr.classList.contains('backs')) {
            if (ids === 0) {
                one.style.color = "#f1f1f1"
            }
            
            if (prevF >= 1) {
                curr.classList.remove('backs')
                curr.classList.add('active1')
                curr.classList.add('rbacks')
                prev.classList.add('rhidens')
                next.classList.remove('active2')
                next.classList.add('nextb')
                if(lastF<10){
                    last.classList.remove('ntree')
                }

                Array.from(hidensb).forEach((hidensb, index) => {
                    hidensb.classList.remove('hidensb')
                });
                if(lastF<10){
                    last.classList.add('hidensb')
                }
            }
        }
        if (curr.classList.contains('rhidens')) {
            if (ids === 1) {
                one.style.color = "#f1f1f1"
            }
            if (prevF >= 1) {

                curr.classList.remove('rhidens')
                curr.classList.add('active1')
                curr.classList.add('rbacks')
                prev.classList.add('rhidens')
                next.classList.remove('active2')
                next.classList.remove('rbacks')
                next.classList.add('nextb')
                last.classList.remove('ntree')
                last.classList.remove('nextb')

                Array.from(hidensb).forEach((hidensb, index) => {
                    hidensb.classList.remove('hidensb')
                });
                last.classList.add('hidensb')
            }
        } else {
            if (ids === 1) {
                one.style.color = "#f1f1f1"
            }
            curr.classList.add('active1')
        }
    }

    function checkMobile(){
        if(window.innerWidth > 600){
            setMobile(false)
        }else{
            setMobile(true)
        }
    }

    useEffect(() => {
        if(mobile){
            setCurrNumber(1);
            setStart(false);
            setTitle(chapters[1].title);
            setShowText(true);
            var current = document.getElementById("left");
            if(current){
                current.classList.add('opacity-gone')
            }
        }else{
            setShowText(false);
            setTitle(chapters[0].title);
            setStart(true);
            setCurrNumber(0);
        }
    }, [mobile])
    
    function goNext(){
        let temp = currNumber;
        if(temp === 1){
            var current = document.getElementById("left");
            if(current){
                current.classList.remove('opacity-gone')
            }
            temp = temp+1;
            setCurrNumber(temp);
            setTitle(chapters[temp].title);
            return;
        }
        if(temp === 9){
            var current1 = document.getElementById("right");
            if(current1){
                current1.classList.add('opacity-gone')
            }
            temp = temp+1;
            setCurrNumber(temp);
            setTitle(chapters[temp].title);
            return
        }
        else{
            temp = temp+1;
            setCurrNumber(temp);
            setTitle(chapters[temp].title);
        }

    }

    function goPrev(){
        let temp = currNumber;
        if(temp === 2){
            var current = document.getElementById("left");
            if(current){
                current.classList.add('opacity-gone')
            }
            temp = temp-1;
            setCurrNumber(temp);
            setTitle(chapters[temp].title);
            return;
        }
        if(temp === 10){
            var current1 = document.getElementById("right");
            if(current1){
                current1.classList.remove('opacity-gone')
            }
            temp = temp-1;
            setCurrNumber(temp);
            setTitle(chapters[temp].title);
            return;
        }
        if(temp > 2){
            temp = temp-1;
            setCurrNumber(temp);
            setTitle(chapters[temp].title);
        }
    }
  
    window.addEventListener('resize', checkMobile);
    window.addEventListener('load', checkMobile);

    const chaptersCount = [0,1,2,3,4,5,6,7,8,9,10]
    return(
        <div className="lore-main">
            <div className="second-bg" style={{opacity: '0.2'}}/>
            <div className={start ? 'main-lore-container lore-main-start' : 'main-lore-container'}>
                <div className={mobile ? 'mids-mobile' : 'mids'}>
                    <div className="startS" style={{width: '80%'}}>
                        <span><b>{title}</b></span>
                        <br />
                        {start &&
                            <span className="start2">The Story</span>
                        }
                    </div>
                    {showtext && !start &&
                        <div className='contextt row'>
                            {currNumber === 1 &&
                                <ChapterOne mobile={mobile}/>
                            }
                            {currNumber === 2 &&
                                <ChapterTwo mobile={mobile}/>
                            }
                            {currNumber === 3 &&
                                <ChapterThree mobile={mobile}/>
                            }
                            {currNumber === 4 &&
                                <ChapterFour mobile={mobile}/>
                            }
                            {currNumber === 5 &&
                                <ChapterFive mobile={mobile}/>
                            }
                            {currNumber === 6 &&
                                <ChapterSix mobile={mobile}/>
                            }
                            {currNumber === 7 &&
                                <ChapterSeven mobile={mobile}/>
                            }
                            {currNumber === 8 &&
                                <ChapterEight mobile={mobile}/>
                            }
                            {currNumber === 9 &&
                                <ChapterNine mobile={mobile}/>
                            }
                            {currNumber === 10 &&
                                <ChapterTen mobile={mobile}/>
                            }
                        </div>
                    }
                    {!mobile &&
                        <img onClick={() => moveToTight('logo')} className="startLogo" id="logo" src={bfgLogoLore} width='100%' alt='bfg-logo-lore' />
                    }

                    {!mobile &&
                        <div className="qkards">
                            {chaptersCount && chaptersCount.map(i => 
                                <div onClick={() => chapterClick(i)}  className="basicDes" id={'i'+ i} key={i}>
                                    {chapters[i].active === true &&
                                    <div>
                                        <span>Chapter {chapters[i].chapter}</span> 
                                        <svg className="widthsvg" viewBox="0 0 800 300">
                                            <path id="svg" className="path1" fill="none" stroke="#1c040e" strokeWidth="20" d="M20,140 l81,140 l700,00" />
                                        </svg>
                                    </div>
                                    }
                                </div> 
                            )}
                        </div>
                    }
                    {mobile && 
                        <div className="content__m">
                            <Container fluid className="qcardss" style={{position: 'relative'}}>
                                <Row className="row widness">
                                    <Col className="aligment">
                                        <img onClick={() => goPrev()} style={{width: '1rem', cursor: 'pointer'}} width="1rem" id="left" src={arrowR} alt="arrowR" />
                                    </Col>
                                    <Col className="aligment">
                                        <div id={'i'+currNumber} style={chapters[currNumber].active === true ? {} : {display: 'none'}} className="chapter_m">Chapter {chapters[currNumber].chapter}</div>
                                    </Col>
                                    <Col className="aligment">
                                        <img onClick={() => goNext()}  style={{width: '1rem', cursor: 'pointer'}} id="right" src={arrowL} alt="arrowL" />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    }
                    {start &&
                        <span onClick={() => moveToTight('logo')} style={{cursor: 'pointer'}} className="beging" id="beging">Click To Begin</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default Lore;