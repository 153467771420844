import heroesFB from '../helpers/heroes';
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';

function AbilityDetail(){
    const [abilities, setAbilities] = useState([]);
    const [snapshot, setSnapshot] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [next, setNext] = useState(null);
    const [prev, setPrev] = useState(null);

    const navigate = useNavigate();
    const { abilityName } = useParams();

    useEffect(() => {
        let index = abilities.findIndex(x => x.name.toLowerCase() === abilityName.replace('-', ' '));
            
        //next
        if (index === (abilities.length - 1)) {
            setNext(abilities[0])
        } else {
            setNext(abilities[index + 1])
        }

        //prev
        if (index === 0) {
            setPrev(abilities[abilities.length - 1])
        } else {
            setPrev(abilities[index - 1])
        }
        setLoading(false);
    },[abilities, abilityName])

    useEffect(() => {
        setLoading(true);
        try{
            const list = []
            heroesFB.firestore().collection("newAbilities").get().then((userSnapshot) => {
                userSnapshot.forEach((doc) => {
                    const data = doc.data();
                    if(data.routeUrl.replace(' ','-').toLowerCase() === abilityName.toLowerCase()){
                        setSnapshot(data);
                    }
                    list.push(data);
                });
                setAbilities(list);
            })
        }catch(error){
            setError(true);
        }
    },[abilityName]);

    function goTo(link){
        navigate(link);
    }
    const elements = ['1st - Hands/Shoulders','2nd - Legs','3rd - Body/Shoulders','Ultimate - Head']
    return(
        <section className="heroes-section" style={{background: 'linear-gradient(#071029,#071029)'}}>
            <div className="second-bg"/>
            {!loading && (error || !snapshot) &&
                <Navigate to='/' />
            }
            {!loading && snapshot &&
                <Container fluid='lg' style={{flexDirection: 'column'}} className="container-hero">
                    
                    <Row className="row-ability" style={{alignContent: 'center'}}>
                        <div className='semi-title' style={{height: 'fit-content', marginBottom: '30ren'}}>
                            Ability Details
                        </div>
                        <Col className="col-first">
                            <div className='container-video-ab'>
                                    <video className="video_ability_1" preload="metadata" loop muted playsInline autoPlay>
                                        <source type="video/mp4" src={snapshot.video}/>
                                    </video>
                                <div className='ability-slots-container'>
                                    {elements && elements.map(e => 
                                        <div style={!e.includes(snapshot.slot) ? {backgroundImage: 'url(/static/bg-about.jpeg)'} : {backgroundImage: 'url('+ snapshot.imgIcon + ')'}} className={!e.includes(snapshot.slot) ? 'ability_selectable inactive-ab' : 'ability_selectable'} key={e}></div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col className="col-first second-mob">
                            <div className='container-video-ab' style={{background: '#07001f'}}>
                                <Container className='video_ability' style={{maxWidth: '100%', display: 'flex', flexDirection: 'column'}}>
                                    <Row className='pt-2 pb-2' style={{background: 'linear-gradient(319deg,hsl(0deg 0% 89% / 4%) 13.54%,hsl(0deg 0% 89% / 1%) 86.45%)', overflow: 'hidden'}}>
                                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3} className='display-center' style={{flexDirection: 'row'}}>
                                            <img className='icon-ability-main' src={snapshot.imgBig} alt='main-ability-icon' width='100%' />    
                                        </Col>
                                        <Col className='display-center' style={{flexDirection: 'row'}}>
                                            <div>
                                                <div className='text-start title-ab-1'>{snapshot.name}</div>
                                                <div className='text-start desc-ab-1'>{snapshot.desc}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div style={{flex: '1'}}>
                                        <Row className='text-start pt-3'>
                                            <Col className='desc-ab-key'>
                                                <span className='desc-ab-value'>Slot:</span> {snapshot.slot}
                                            </Col>
                                            <Col className='desc-ab-key'>
                                                <span className='desc-ab-value'>Complexity:</span> {snapshot.complexity}
                                            </Col>
                                        </Row>
                                        <Row className='text-start pt-3 desc-ab-key'>
                                            {snapshot.attributes && snapshot.attributes.map((key,value) => 
                                                <div style={{padding: '0'}} key={value}><span className='desc-ab-value'>{key.value.split(':')[0]}: </span>{key.value.split(':')[1]}</div>
                                            )}
                                        </Row>
                                    </div>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
            {!loading && next && prev &&
            <div className="footer-hero" style={{animationDuration: '0.1s'}}>
                <Row className="mobileFooter mobileFooterAbilities">
                    <Col onClick={() => goTo('/ability/'+prev.routeUrl.replaceAll(' ', '-'))} className="display-center prev-section" style={{justifyContent: 'center',alignItems: 'flex-end',background: 'linear-gradient(268deg,hsl(0deg 0% 89% / 4%) 13.54%,hsl(0deg 0% 89% / 1%) 86.45%)', position: 'relative', cursor: 'pointer'}}>
                        <img className='floating-side-img-left' src={prev.imgBig} alt='prev-icon' />
                        <div className='pe-md-3 pe-lg-3 pe-xl-3 pe-sm-1 pe-xs-1'>
                            <div className='footer-titleA'> PREVIOUS ABILITY</div>
                            <div className='footer-nameA'> {prev.name}</div>
                            <div className='footer-slotA'> {prev.slot}</div>
                        </div>
                    </Col>
                    <Col onClick={() => goTo('/abilities')} xxl={1} xl={1} lg={1} md={2} sm={3} xs={3}   className="display-center all-cont">
                        <span className="material-symbols-outlined icon-all">view_carousel</span>
                        <div className='label-all'>ALL ABILITIES</div>
                    </Col>
                    <Col onClick={() => goTo('/ability/'+next.routeUrl.replaceAll(' ', '-'))}  className="display-center next-section"  style={{justifyContent: 'center', alignItems: 'flex-start',background: 'linear-gradient(63deg,hsl(0deg 0% 89% / 4%) 13.54%,hsl(0deg 0% 89% / 1%) 86.45%)',  position: 'relative', cursor: 'pointer'}}>
                        <img className='floating-side-img-right' src={next.imgBig} alt='prev-icon' />
                        <div className='ps-md-3 ps-lg-3 ps-xl-3 ps-sm-1 ps-xs-1'>
                            <div style={{textAlign: 'start'}} className='footer-titleA'> NEXT ABILITY</div>
                            <div className='footer-nameA'> {next.name}</div>
                            <div style={{textAlign: 'start'}} className='footer-slotA'> {next.slot}</div>
                        </div>
                    </Col>
                </Row>
            </div> 
            }
        </section>
    )
}

export default AbilityDetail;