import { Col } from "react-bootstrap";

function HeroIndexComponent(props) {
    // eslint-disable-next-line no-unused-vars
    const [id,type,name, video1 ,videoPoster,logo, routeUrl, selected, color] = props.classseIn;

    const route_url = process.env.PUBLIC_URL;
    const classes = props.classes;
    const changeClasses = props.changeClasses;
    const changeSelectedOutter = props.changeSelected;
    const changeVideo = props.changeVideo;
    return(
       <Col sm={2} xs={2} lg={2} md={2} className={selected ? 'selectedHero' : 'box-Hero'}>
            <div onClick={() => changeSelected(id)}  className="mainImg" style={{backgroundImage: 'url('+route_url+logo+')'}}/>
            <div className="home_hero_name">
                {name}
            </div>
            {/* <div className="back-Hero"  />*/}
            {selected && 
            <>
            <div className="selectedDown"></div>
            {/* 
            <div className="selectTextBox" style={isMobile ? {left: '4px'} : {}}>
                <div className="titleStory qb-0">{name}</div>
                <div className="subtitle diffS pt-0 pb-2">{type}</div>
            </div>*/}
            </>
            }
       </Col>
    )

   function changeSelected(id){
        const index = classes.findIndex(x => {
            return x.selected === true;
        })
        if(index !== -1 && index !== id){
            let temp = [...classes];
            temp[index].selected = false;
            temp[id].selected = true;
            changeClasses(temp);
            changeSelectedOutter(temp[id]);

            let video = document.getElementById('hero-video');
            let img = document.getElementById('bigImgHero');
            let text = document.getElementById('nameHeroBig');

            if(video){
                if(video.classList.contains('showsS')){
                    video.classList.remove('showsS');
                }
                changeVideo();
            }
            if(img){
                if(img.classList.contains('animation-slide-in-img-big')){
                    img.classList.remove('animation-slide-in-img-big');
                }
                setTimeout(()=> { 
                    img.classList.add('animation-slide-in-img-big');
                }, 10);
            }
            if(text){
                if(text.classList.contains('animation-slide-in-text-big')){
                    text.classList.remove('animation-slide-in-text-big');
                }
                setTimeout(()=> { 
                    text.classList.add('animation-slide-in-text-big');
                }, 10);
            }
        }
   }
}

export default HeroIndexComponent;