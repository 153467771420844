import gifc7c1 from "../../assets/img/loregif/gifc7c1.gif";
import loreArrows from  "../../assets/img/loregif/loreArrows.png";

function ChapterSeven(props){
    return(
        <div className="row">
            <div className="row">
                <div className="col-sm-9 col-sm-9__7">
                    <span className="seven_start">
                    However, the Kings were now getting old - too old to endure the world’s problems any longer - so they started preparing a successor. Each King had a son to follow in his footsteps, and all three were told about the stone’s secrets that were never revealed. After being promised by their children to rule with honor and respect, the three Kings passed away one by one, parting to the Land of the Dead, leaving their cherished kingdoms in the hands of their successors.
                    <br /> <br />
                    Raised by fathers such as Glaucus, Ion, and Olaf, the three young men knew well what it means to be honorable and respectful. Of course, they had no intention of disturbing the peace among their people. However, hearing the news of the three stones got all of them thinking - what if the pieces are once again united?
                    Unlike their fathers, the young kings did not wish to share such an evil thought with their fellow rulers, but, as much as they avoided the idea, it slowly festered in their minds. Greed was creepily taking over, and each of the new kings wished for immortality more than anything else. They all wanted to be the best - best at growing food, best at crafting weapons, best at magic - and each one had an idea of how great a ruler he’d be if he had all stones together.
                    </span>
                </div>
                <div className="col-sm-3">
                    <img className="crounws__7" src={gifc7c1} alt={gifc7c1} />
                </div>
            </div>
            <br /> <br />
            <div className="row">
                <div className="col-sm-3">
                    <img className="arrows__7" src={loreArrows} alt="loreArrows" />
                </div>
                <div className="col-sm-9__7 col-sm-9">
                    Slowly, tension was rising. After a decade, even the townspeople felt pressure growing in their everyday lives. Everything had to be constantly better. No one was allowed to slack off. More and more guards roamed around each kingdom as if expecting to run into trouble. Little did the people know that, in the minds of their new Kings, the thought of reforging the One stone had become an obsession, and taking the pieces forcefully was now an idea they were thoroughly looking into. One thing was for sure - the balance they previously enjoyed was now lost. The air smelled of war.
                    <br /> <br />
                    Silently, they all prepared. On a night that was supposed to be their annual celebration of peace, great armies were brought, and shots were fired. Each young King had one of the three stones placed on his chest plate armor, and, as the attack began, they rode forward proudly, prepared to kill and take. And so, the Stone War began.
                </div>
            </div>
        </div>
    )
}

export default ChapterSeven;