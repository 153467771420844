import { Button, Col, Container, Row } from "react-bootstrap";

import streamer from "../assets/img/streamer.jpg";
import streamer2 from "../assets/img/streamer2.jpg";

function Streamers(props) {
  const createNotification = props.createNotification;
  function goTo(link) {
    window.open(link, "_blank");
  }
  function copyEmail() {
    try {
      navigator.clipboard.writeText("contact@battleforgiostone.com");
      createNotification("success", "", "Email copied");
    } catch {
      try {
        window.clipboardData.setData("Text", "Copy this text to clipboard");
      } catch {
        //
      }
    }
  }
  return (
    <section className="about-section">
      <div className="second-bg" />
      <Container className="starting-container">
        <Row style={{ textAlign: "center", justifyContent: "center" }}>
          <h1 className="subtitle1" style={{ color: "white" }}>
            {" "}
            Open Call For Streamers{" "}
          </h1>
          <div
            style={{ marginBottom: "8px", marginTop: "8px" }}
            className="separator"
          />
          <p
            className="text-stream"
            style={{ color: "rgb(255 255 255 / 74%)" }}
          >
            We want to be the central hub of Web3 Esports, and our goal is to
            partner with 100 streamers. We can offer heroes, cash prizes,
            whitelist spots, tournament slots, a part in our referral program,
            and more (contact us to find out what additional perks you can get).
            The only thing that you need to do is to stream Battle For Giostone.
          </p>
        </Row>
        <Row className="row pt-5 pb-5" style={{ justifyContent: "center" }}>
          <Col lg={5} md={6} sm={10} xs={12}>
            <img src={streamer} width="100%" alt="streamer-img" />
          </Col>
        </Row>
        <Row style={{ color: "white", fontFamily: "egyptian-black" }}>
          <h3 className="pt-4"> BFG STREAMER RECRUITMENT PROJECT</h3>
        </Row>
        <Row>
          <div style={{ color: "white", fontFamily: "egyptian-black" }}>
            <h5 className="pt-3"> 1. SIGN UP</h5>
            <div className="text-stream" style={{ fontFamily: "monserat" }}>
              Participate in the BFG Official Streamer Open Call, reach certain
              conditions, and earn rewards. Fill in the application to become
              one of our sponsored streamers.
            </div>
            <h5 className="pt-5">2. PASS THE REVIEW</h5>
            <div className="text-stream" style={{ fontFamily: "monserat" }}>
              If you are good at MOBA games and your followers are MOBA or Web3
              fans, you will have a higher chance of passing the review!
            </div>
            <h5 className="pt-5">3. EARN MONEY FROM LIVE STREAMING</h5>
            <div className="text-stream" style={{ fontFamily: "monserat" }}>
              After passing our review, you will earn money and additional
              rewards based on certain conditions.
            </div>
          </div>
        </Row>
        <Row className="pt-5 pb-5" style={{ justifyContent: "center" }}>
          <Col lg={3}  md={3}>
            <Button
              onClick={() =>
                goTo(
                  "https://docs.google.com/forms/u/1/d/e/1FAIpQLSfW4iBZTXPgHWyZUigTdrKRFitlnQnYd4G3FnnFMD8P78HwYQ/viewform"
                )
              }
              style={{
                width: "100%",
                background: "linear-gradient(90deg,#b60101,#b856b0,purple)",
                border: "none",
                fontFamily: "monseratMedium",
              }}
              size="lg"
            >
              APPLY
            </Button>
          </Col>
        </Row>
        <Row>
          <div style={{ color: "white", fontFamily: "egyptian-black" }}>
            <h5 className="pt-5">The process</h5>
            <div
              className="text-stream pt-3"
              style={{ fontFamily: "monserat" }}
            >
              1. Fill in the application form.
              <br />
              <br />
              2. Our staff will verify the information. If the information is
              false, you will be disqualified from participating.
              <br />
              <br />
              3. After passing the review, you will be contacted by our staff to
              enter the BFG Streamer Group.
              <br />
              <br />
              4. Entering the Group means you are qualified to be our official
              streamer and can start live streaming. Those who do not enter the
              Group will be considered as not participating in this project. We
              will email you whether you pass the review process or not.
              <br />
              <br />
              5. Our review criteria:
              <div className="ps-3">
                1. Good social media presence
                <br />
                2. Live Streaming time is more than 10 hours per week.
                <br />
                3. Other factors (Good at MOBA games, followers are mainly MOBA
                fans, having good live streaming conditions, smooth network,
                good PC, clear live quality).
              </div>
            </div>
          </div>
        </Row>
        <Row className="row pt-5 pb-3" style={{ justifyContent: "center" }}>
          <Col lg={6} md={7} sm={10} xs={10}>
            <img src={streamer2} width="100%" alt="streamer2-img" />
          </Col>
        </Row>
        <Row>
          <div style={{ color: "white", fontFamily: "egyptian-black" }}>
            <h5 className="pt-5">Live Streaming Rules</h5>
            <div
              className="text-stream  pt-3"
              style={{ fontFamily: "monserat" }}
            >
              We will only count streaming <b>Battle For Giostone</b> in this
              program. Streaming other content (other games) will not be
              included in the valid live streaming time. If you want to stream
              additional content, please remember to switch to different
              categories and rename your stream.
              <br />
              <br />
              The name of your streams needs to be related to{" "}
              <b>Battle For Giostone</b> (example: “I’m the best player of{" "}
              <b> Battle For Giostone</b>”, “Watch me beat everyone in{" "}
              <b>Battle For Giostone</b>”, “Let’s Play{" "}
              <b>Battle For Giostone</b>”)
              <br />
              <br />
              Your channel should be comfortable to watch.
              <br />
              <br />
              We want to receive background statistics once per week to make
              sure you’re not using external programs to manipulate viewers.
              <br />
              <br />
              Conforming to the above Live Streaming Rules will be rewarded with
              a corresponding bonus:
              <br />
              <br />
              Reward Tiers Time Per Month
              <div className="ps-3">
                <b>S Tier</b> - Stream for more than 120 hours - <b>$200</b>
                <br />
                <b>A Tier</b> - Stream for more than 100 hours - <b>$160</b>
                <br />
                <b>B Tier</b> - Stream for more than 80 hours - <b>$120</b>
                <br />
                <b>C Tier</b> - Stream for more than 60 hours - <b>$80</b>
                <br />
                <b>D Tier</b> - Stream less than 60 hrs - <b>$0</b>
              </div>
              <br />
              Feel free to contact us on{" "}
              <span
                className="underline"
                onClick={() => goTo("https://discord.com/invite/BFGiostone")}
              >
                Discord
              </span>
              ,{" "}
              <span
                className="underline"
                onClick={() => goTo("https://twitter.com/BFGiostone")}
              >
                Twitter
              </span>
              ,{" "}
              <span
                className="underline"
                onClick={() =>
                  goTo("https://www.facebook.com/BattleForGiostone")
                }
              >
                Facebook
              </span>
              , or
              <span
                onClick={() =>
                  goTo("https://www.instagram.com/battleforgiostone/")
                }
                className="underline ps-1"
              >
                Instagram
              </span>
              , or email us at{" "}
              <span onClick={() => copyEmail()} className="underline">
                contact@battleforgiostone.com
              </span>{" "}
              so we can start working together!
            </div>
          </div>
        </Row>
        <Row className="pt-5 pb-5" style={{ justifyContent: "center" }}>
          <Col lg={3} md={3}>
            <Button
              onClick={() =>
                goTo(
                  "https://docs.google.com/forms/u/1/d/e/1FAIpQLSfW4iBZTXPgHWyZUigTdrKRFitlnQnYd4G3FnnFMD8P78HwYQ/viewform"
                )
              }
              style={{
                width: "100%",
                background: "linear-gradient(90deg,#b60101,#b856b0,purple)",
                border: "none",
                fontFamily: "monseratMedium",
              }}
              size="lg"
            >
              APPLY
            </Button>
          </Col>
        </Row>
        <Row className="pb-5">
          <div style={{ color: "white", fontFamily: "egyptian-black" }}>
            <h5>Disclaimer</h5>
            <div
              className="text-stream pt-3"
              style={{ fontFamily: "monserat" }}
            >
              1. Streamers should strictly follow the basic rules of the
              platforms. Any streamer who violates the basic rules during live
              streaming will get a penalty, including being disqualified from
              the program, cancelling the rewards, or banning the live channel,
              based on the severity of the violation.
              <br />
              <br />
              2. Streamers are not allowed to cheat in live streaming. For
              example, streamers with the following actions: AFK behavior
              (Repeated content in the live streaming for a long time, frozen
              screen, no commentating voice will be considered as AFK), playing
              recordings, and falsifying viewer numbers will be disqualified
              from our project, and will not receive the rewards that have been
              reached
              <br />
              <br />
              3. If we receive and verify reports more than once that streamers
              violate the above rules, smear the game, or Livestream other
              content irrelevant to BFG, we will disqualify those streamers and
              cancel the rewards that have been reached.
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default Streamers;
