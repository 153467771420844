import popave from "../../assets/img/loregif/popave.png"
import GioStory from "../../assets/img/loregif/GioStory.png"

function ChapterEight(props){
    const isMobile = props.mobile;
    return(
        <div className="row row__8">
            <div className="row row__8">
                <div className="col-sm-9 col-sm-9__8">
                    <span className="eight_start">In the Land of the Dead, their fathers stood watching. Neither Olaf, Ion, nor Glaucus could believe what was happening to their people. Their souls filled up with such grief that it caught the attention of the God of the Underworld. He learned quickly what was the cause of such sorrow, so he proposed a solution: he would unite their souls as one and allow them to go back into the world in the body of a mighty dragon. However, if they decide to go back, they would be stuck in the Dragon’s body until the stone is destroyed. Having seen the horror their sons had brought to their people, the Kings had no other choice but to accept.
                    <br /><br />
                    As the battle went on, the three young Kings felt a burning near the chest. They saw their precious stones quickly turning to a dark red color and heating up so much, forcing the Kings to take the armor off and throw it on the ground. No one was sure what was happening, so the fighting ceased for a moment. Suddenly, the stones were drawn to each other with a magnet-like force, and, right before clashing, bursts of lightning started spawning from the space between them.
                    </span>
                </div>
                <div className="col-sm-3 col-sm-3__8">
                    <img className="popave__8" src={popave} alt="popave" />
                </div>
            </div>
            <div className="row">
                <div className={!isMobile ? 'col-sm-4' : 'col-sm-4 justify-center row'}>
                    <img className="popave__8"  src={GioStory} alt="GioStory"  />
                </div>
                <div className="col-sm-8">
                    All warriors took a step back out of fear, and their Kings fell back with them. The three stones connected abruptly, and, at that moment, a strange shape started morphing around the restored One stone. Before they could see what it was, the armies heard a deafening roar. Out of nowhere, a giant winged beast appeared before them and, looking both terrifying and majestic, he took the stone, rejected all the attacks and crawled into the forests never to be seen again.
                </div>
            </div>
        </div>
    )
}

export default ChapterEight;