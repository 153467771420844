import trailer from '../assets/video/trailer.mp4';
import gio from '../assets/video/GIO.webm';
import deccan from '../assets/img/icons/deccan.png';
import glacic from '../assets/img/icons/glacic.png';
import map from '../assets/img/map.png';

import "../assets/css/Home.css";

import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import elixir from '../assets/img/elixir-icon.webp'

import NewsComponent from '../components/NewsComponent';
import HeroIndexComponent from '../components/HeroIndexComponent';
import AbilityIndexComponent from '../components/AbilityIndexComponent';

import newsF from '../helpers/firebase';
import heroesFB from '../helpers/heroes';

import { useCollectionData } from 'react-firebase-hooks/firestore';
import {useState } from 'react';

//import bfgCoin from '../assets/img/BFG-token-logo.png';
//import giosCoin from '../assets/img/gst-logo.png';

import blizzard from '../assets/img/icons/blizzard.png';
import avalaunch from '../assets/img/icons/avalaunch.png';
import avalabs from '../assets/img/icons/avalabs.png';
import line from '../assets/img/line.png';
import circle from '../assets/img/icons/shine_circle.png';

import { useNavigate  } from "react-router-dom";
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';


const classes = [{
    id: 0,
    type: 'Melee Warrior',
    name: 'Ranvir',
    video1: 'static/media/ranvir.mp4',
    videoPoster: 'static/media/ranvir-poster.png',
    logo: 'static/cube/ranv.png',
    routeUrl: 'ranvir',
    selected: true,
    color: '#432b0947',
    imgBig: 'https://firebasestorage.googleapis.com/v0/b/bfg-website-f3f23.appspot.com/o/HeroPrev%2F1.png?alt=media&token=a6f097fc-3a13-4728-80d3-a9cb642d1c24',
    anime: 'static/cybe/ranv.png'
},
{
    id: 1,
    type: 'Melee Assassin',
    name: 'Akuma',
    video1: 'static/media/akuma.mp4',
    videoPoster: 'static/media/akuma-poster.png',
    logo: 'static/cube/aku.png',
    routeUrl: 'akuma',
    selected: false,
    color: 'rgb(67 9 58 / 28%)',
    imgBig:'https://firebasestorage.googleapis.com/v0/b/bfg-website-f3f23.appspot.com/o/HeroPrev%2F4.png?alt=media&token=8708296f-cb86-4ddb-9e85-632471534c9b',
    anime: 'static/cybe/aku.png'
},
{
    id: 2,
    type: 'Melee Wizard',
    name: 'Gogmangog',
    video1: 'static/media/gogmangog.mp4',
    videoPoster: 'static/media/gogmangog-poster.png',
    logo: 'static/cube/gog.png',
    routeUrl: 'gogmangog',
    selected: false,
    color: 'rgb(9 60 67 / 28%)',
    imgBig: 'https://firebasestorage.googleapis.com/v0/b/bfg-website-f3f23.appspot.com/o/HeroPrev%2F6.png?alt=media&token=dafda34b-f874-401f-a7eb-196230f1f538',
    anime: 'static/cybe/gog.png'
},
{
    id: 3,
    type: 'Ranged Warrior',
    name: 'Auri',
    video1: 'static/media/auri.mp4',
    videoPoster: 'static/media/auri-poster.png',
    logo: 'static/cube/aur.png',
    routeUrl: 'auri',
    selected: false,
    color: 'rgb(155 4 4 / 15%)',
    imgBig: 'https://firebasestorage.googleapis.com/v0/b/bfg-website-f3f23.appspot.com/o/HeroPrev%2F3.png?alt=media&token=991c27f8-ff7f-4642-ba72-7626dadbfab1',
    anime: 'static/cybe/aur.png'
},
{
    id: 4,
    type: 'Ranged Assassin',
    name: 'Lucius',
    video1: 'static/media/lucius.mp4',
    videoPoster: 'static/media/lucius-poster.png',
    logo: 'static/cube/lucs.png',
    routeUrl: 'lucius',
    selected: false,
    color: '#432b0947',
    imgBig: 'https://firebasestorage.googleapis.com/v0/b/bfg-website-f3f23.appspot.com/o/HeroPrev%2F2.png?alt=media&token=44331c52-d321-4f84-b6ea-9f85de6c9723',
    anime: 'static/cybe/lucs.png'
},
{
    id: 5,
    type: 'Ranged Wizard',
    name: 'Gurluk',
    video1: 'static/media/gurluk.mp4',
    videoPoster: 'static/media/gurluk-poster.png',
    logo: 'static/cube/gur.png',
    routeUrl: 'gurluk',
    selected: false,
    color: 'rgb(27 67 9 / 28%)',
    imgBig: 'https://firebasestorage.googleapis.com/v0/b/bfg-website-f3f23.appspot.com/o/HeroPrev%2F5.png?alt=media&token=3fb3d2a9-9ae3-455a-bac5-d281d839a710',
    anime: 'static/cybe/gur.png'
},
]

const phases = [
{
    id: 0,
    name: 'Q4 2023',
    pass: true,
    next: false,
    active: false,
},
{
    id: 1,
    name: 'Q1 2024',
    pass: true,
    next: false,
    active: false,
},
{
    id: 2,
    name: 'Q2 2024',
    pass: false,
    next: false,
    active: true,
},
{
    id: 3,
    name: 'Q3 2024',
    pass: false,
    next: true,
    active: false,
},
{
    id: 4,
    name: 'Q4 2024',
    pass: false,
    next: false,
    active: false,
},]

const text = [
    [
        {
            id: 1,
            desc: 'Official Website Launch',
            done: true,
            current: false
        },
        {
            id: 2,
            desc: 'Closed Pre-Alpha Game Launch',
            done: true,
            current: false
        },
        {
            id: 3,
            desc: 'Esports',
            done: true,
            current: false,
        },
        {
            id: 4,
            desc: 'Promoting the game at physical events',
            done: true,
            current: false,
        },
    ],
    [{
            id: 0,
            desc: 'Fixing Bugs',
            done: true,
            current: false
        },
        {
            id: 1,
            desc: 'Production Studio',
            done: true,
            current: false
        },
        {
            id: 2,
            desc: 'Add More Items',
            done: true,
            current: false
        },
        {
            id: 3,
            desc: 'Add More Abilities',
            done: true,
            current: false
        },
    
    ],
    [
        {id: 0, desc: 'Seed Round for BFG v2', done: false, current: true},
        {id: 1, desc: 'Hero Forging', done: false, current: true},
        {id: 3, desc: 'Marketing Campaign', done: false, current: true},
    ],
    [
        {id: 0, desc: 'IDO BFGv2 TGE Launch', done: false, current: false},
        {id: 1, desc: 'BFG Reborn Beta Launch', done: false, current: false},
        {id: 2, desc: 'Esports Tournament Relaunch', done: false, current: false},
        {id: 3, desc: 'Leaderboard Rewards', done: false, current: false},
        {id: 4, desc: 'Staking Launch + BFG Airdrop System', done: false, current: false},

    ],
    [
    {
        id: 2,
        desc: 'MARKETPLACE RELEASE',
        done: false,
        current: false,

    },
    {
        id: 3,
        desc: 'BETTING PLATFORM RELEASE',
        done: false,
        current: false,

    },
    {
        id: 4,
        desc: 'BFG Reborn Launch',
        done: false,
        current: false,
    },
    {
        id: 5,
        desc: 'Ranked Mode',
        done: false,
        current: false,
    },
    {
        id: 6,
        desc: '$BFGv2 earning per match',
        done: false,
        current: false,
    },
]
]

const roadmap = [1,2,3,4,5]

function Home({createNotification}) {
    const navigate = useNavigate();
    const [isMobile, changeMobile] = useState(false);
    const [isDragon, changeDragon] = useState(true);
    const [classseIn, changeClasses] = useState(classes);
    const [selected, changeSelected] = useState(classes[0]);
    const [selectedRoadmap, changeSelectedRoadmap] = useState(0);
    const [email, setEmail] = useState('');
    
    const defaultValues = {routeUrl: '', title: '', coverImg: '', content: '', date: {seconds: null}}

    //news
    const firestore = newsF.firestore();
    const db = firestore.collection('posts');
    const query = db.where("isVisible", '==', true).orderBy('date', 'desc').limit(3);

    const [snapshot, loading, error] = useCollectionData(query);
 
    const loader = [{id: 0}, {id: 1}, {id:2}]


    //abilities
    const firestoreA = heroesFB.firestore();
    const dbA = firestoreA.collection('newAbilities');

    const [snapshotA, loadingA, errorA] = useCollectionData(dbA);

    function goTo(link){
        window.open(link, '_blank');
    }
    
    function changeMobileValue() {
        if (window.innerWidth >= 800){
            changeMobile(false);
        }else{
            changeMobile(true);
        }
    }
    function changeDragonValue() {
        if (window.innerWidth >= 992){
            changeDragon(true);
        }else{
            changeDragon(false);
        }
    }
    function changeVideo(){
        let video = document.getElementById('hero-video');
        if(video){
            video.pause();
            setTimeout(()=> { 
                video.classList.add('showsS');  
            }, 10);
            video.load();
        }
    }
    function selectedA(value) {
        changeSelectedRoadmap(value);
    }
    async function onSubmit(email) {
        const tempMail = email
        if (email !== '') {
            const emailSnap = firestoreA.collection('emails').doc(tempMail)

            const docSnap = await getDoc(emailSnap);

            if (docSnap.exists()) {
                createNotification('error', '', 'You already registered with ' + tempMail)
             } else { 
                const db = getFirestore(heroesFB)
                const ref = doc(db, "emails", tempMail)
                await setDoc(ref, {
                        email: emailSnap
                });
                createNotification('success', '', 'Email registered successfully')
                setEmail('')
            }
        }
    }

    window.addEventListener('resize', () => {changeMobileValue();changeDragonValue()});
    window.addEventListener('load', () => {changeMobileValue();changeDragonValue()})
    return (
        <div>
            {/* VIDEO SECTION */}
            <div className='App-header'>
                <div className='background-container'>
                    <video id="background-video" loop preload="auto" muted playsInline autoPlay>
                        <source src={trailer} type="video/mp4" />
                    </video>
                </div>
                <div className='background-layout'></div>
                <Container className='title-container' fluid>
                    <Row>
                        <h1 className='title'>BATTLE FOR GIOSTONE</h1>
                    </Row>
                    <Row>
                        <div className='separator'/>
                    </Row>
                    <Row className='btnRow'>
                        <Form className="d-grid">
                            <Button onClick={() => goTo('https://elixir.app/')}  className='playButtonMain' variant="outline-light">
                            <div className='button-logo'>
                                <img className='logoElixir1' alt='Elixir logo' src={elixir} />
                                <div>
                                    <div>PLAY FOR FREE</div>
                                    <div className='subtitle'> Download on Elixir</div>
                                </div>
                            </div>
                            </Button>
                        </Form>
                    </Row>
                </Container>
                <div className='onBottom'/>
            </div>

            {/* NEWS SECTION */}
            <div className='news-section'>
            <Container className='news-header'>
                <div className='active'>LATEST NEWS</div>
                <div className='selectElement ps-4' onClick={() => {window.open('/news', '_self')}}>VIEW ALL →</div>
            </Container>
            <Container className='news-capsule'>
                {(error || loading) &&
                    <>
                        {loader.map((i) => 
                        <NewsComponent key={i.id} id={i.id} items_data={defaultValues} ></NewsComponent>
                        )}
                    </>
                }
                {snapshot && 
                <>
                {snapshot.map((news,index) => <NewsComponent key={news.routeUrl} id={index} items_data={news} ></NewsComponent>)}
                </>
                }
            </Container>
            </div>

            {/* GAMEPLAY SECTION */}
            <div className='playSection'>
                <div className='onTop'/>
                <div className='bg-blured'/>
                <div className='imgBottom'>
                    <img className='floatingImg' alt="moba-map" src={map} />
                </div>
                <div className='text-section-bottom'>
                    <Container className='inner-container-hero__home'>
                        <Row className='justify-content-center subtitle1'>
                            <span>CARVE YOUR PLACE IN HISTORY </span>
                        </Row>
                        <Row className='justify-content-center'>
                            <div style={{marginBottom: '12px', marginTop: '12px'}} className='separator'/>
                        </Row>
                        <Row className='justify-content-center'>
                            Forge a Hero with 4 of the 72 abilities you choose<br/>
                            Use them in battle and see if you've got the skills to emerge victorious<br/>
                        </Row>
                        <Row className='justify-content-center'> 
                            <Form className="d-flex justify-content-center pt-4">
                                <Button onClick={() => navigate('/lore')} className='playButton' variant="outline-light">
                                        <div className='otherBtns'>STORY</div>
                                </Button>
                            </Form>
                        </Row>
                    </Container>
                </div>
                <div className='onBottom inhence'/>
            </div>

            {/* STORY SECTION */}
            <div className='storySection'>
                <div className='onTop inhenceTop'/>
                <div className='bg-blured-right'/>
                <div className='bg-blured-left'/>
                <div className='bg-blured'/>

                <div style={{top: '20%'}} className='text-section-bottom small-bottom'>
                    <Container>
                        
                        <Row className='justify-content-center subtitle1' style={{textShadow: '0px 0px 9px #1b022ee3'}}>
                            <span>JOIN THE BATTLE</span>
                        </Row>
                        {/* 
                        <Row className='justify-content-center'>
                            <div style={{marginBottom: '8px', marginTop: '8px'}} className='separator'/>
                        </Row>
                        <Row className='justify-content-center'> 
                            <Form className="d-flex justify-content-center pt-2">
                                <Button className='playButton' variant="outline-light">
                                        <div className='otherBtns'>READ MORE</div>
                                </Button>
                            </Form>
                        </Row>*/}
                    </Container>
                </div>

                {isDragon &&
                    <video id="background-gio-video" loop preload="auto" muted playsInline autoPlay>
                            <source src={gio} type="video/mp4" />
                            <source src={gio} type="video/webm" />
                    </video>
                }
                <div className='gioBG'>
                    <div className='story-container'>
                        <Container className='inner-container-story'>
                            <Row>
                                <Col className={!isDragon ? 'pb-5 deccan-text' : 'deccan-text'}>
                                    <div className='deccan-cont ps-3'>
                                        <div className='text-content' style={{textAlign: 'left'}}>
                                        The Deccan is a tribe that lives near a volcano. They are intimately connected and familiar with the moods, signs, and language of the volcanoes and possess valuable knowledge to contribute to decision-making and warning systems to prevent and defend their people during volcanic events. Their main goal is to warn everyone of what may come and use the Giostone to stop the volcanoes from turning the earth into a sea of magma.

                                        </div>
                                        <h4 className='pt-5 titleStory'> THE DECCAN</h4>
                                    </div>
                                    <img className='deccan-img' src={deccan} alt='deccan-icon'/>
                                </Col>
                                <Col lg={5}></Col>
                                <Col className={!isDragon ? 'pt-5 glacic-text' : 'glacic-text'}>
                                        <div className='glacic-cont pe-3'>
                                        <div className='text-content' style={{textAlign: 'right'}}>
                                            Cold, ice and glaciersare the way of life for The Glacic. They go way back when it comes to gardening, igloos and everything that is key for surviving in the harsh and cold climate. They believe that The Ice Age is upon us and with their knowledge, they can save the Earths inhabitants. Their goal is to use the Giostone to prevent the Ice Age.
                                        </div>
                                        <h4 className='pt-5 titleStory'> THE GLACIC</h4>
                                    </div>
                                    <img className='glalic-img' src={glacic} alt='glacic-icon' />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                
                <div className='onBottom blue'/>
            </div>
             
            <div className='spacerP'></div>

            {/* HERO SECTION */}
            <div className='heroSection'>
                <div className='onTop purple'/>
                <div className='bg-blured'/>
                <div className='titleHero'>
                    <Container className='pb-5 inner-container-hero__home'>
                        <Row className='justify-content-center subtitle1' style={{textShadow: '0px 0px 9px #1b022ee3'}}>
                            <span>WHO WILL YOU CHOOSE?</span>
                        </Row>
                        <Row className='justify-content-center'>
                            <div style={{marginBottom: '8px', marginTop: '8px'}} className='separator'/>
                        </Row>
                        <Row className='justify-content-center subtitleHero titleStory pt-0'> 
                            "Maneuver the BFG metaverse with crypto characters that you own."
                        </Row>
                    </Container>

                    <Container className='pt-5 pb-5 container-select'>
                        <Row className='mobile-row-hero-cont' style={{width:'100%', padding: '0'}} >
                            <Col style={{position: 'relative', overflow: 'hidden'}}>
                                <img id='bigImgHero' className='big-img-home-hero' src={selected.imgBig} alt="hero"/>
                                <Container className='row-image-home-hero'>
                                    <Row className='ps-2' style={{position: 'relative'}}>
                                        <div className='hero-name-home-hero'>{selected.name}</div>
                                        <div className='type-name-home-hero'>{selected.type}</div>
                                        <div className='button-see-hero-m'>
                                            <Button onClick={() => {navigate('/heroes/'+ selected.name.toLowerCase())}} variant="outline-light" className='playButton1 button-resp-see-hero-m'>See more</Button>
                                        </div>
                                    </Row>
                                </Container>
                            </Col>
                            <Col style={{padding: '0'}}> 
                                <Row className='row-menu-heroes'>
                                    {classseIn && classseIn.map(el => 
                                        <HeroIndexComponent key={el.id} changeVideo={changeVideo} changeSelected={changeSelected} isMobile={isMobile} changeClasses={changeClasses} classes={classseIn} classseIn={Object.values(el)} />
                                    )}
                                </Row>
                                <Row className='video-container-mobile-home' style={{ width: 'fit-content', marginTop: '3rem'}}>
                                    <video id="hero-video" poster={selected.videoPoster} className='showsS' loop preload="metadata" muted playsInline autoPlay>
                                        <source src={selected.video1} type="video/mp4" />
                                    </video> 
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='onBottom'/>
            </div>

            {/* ABILITIES SECTION */}
            <div className='abilitySection'>
                <div className='onTop' style={{height: '30px'}}/>
                <Container className='pb-5'>
                    <Row className='justify-content-center subtitle1' style={{textShadow: '0px 0px 9px #1b022ee3'}}>
                        <span>ABILITIES</span>
                    </Row>
                    <Row className='justify-content-center'>
                            <div style={{marginBottom: '8px', marginTop: '8px'}} className='separator'/>
                        </Row>
                    <Row className='justify-content-center subtitleHero titleStory pt-0'> 
                        Choose the abilities of your Hero.
                    </Row>
                </Container>
                <div className='section3'>
                    <div className='selectElement ps-3 text-white' onClick={() => {navigate('/abilities')}}>VIEW ALL →</div>
                    <section className="secCar">
                        {!errorA && !loadingA && snapshotA &&
                        <>
                            <article className="arL">
                                    {
                                        snapshotA.slice(0, Math.ceil(snapshotA.length/3)).map(x => 
                                            <AbilityIndexComponent main={true} key={x.name} x={x}></AbilityIndexComponent>
                                        )
                                    }
                            </article>
                            <article className="arR">
                                {
                                snapshotA.slice(Math.ceil(snapshotA.length/3), (snapshotA.length - Math.ceil(snapshotA.length/3))).map(x => 
                                    <AbilityIndexComponent main={true} key={x.name} x={x}></AbilityIndexComponent>
                                )
                                }
                            </article>
                            <article className="arL">
                                {
                                snapshotA.slice((snapshotA.length - Math.ceil(snapshotA.length/3)),snapshotA.length).map(x => 
                                    <AbilityIndexComponent main={true} key={x.name} x={x}></AbilityIndexComponent>
                                )
                                }
                            </article>
                        </>
                        }
                    </section>
                </div>
            </div>

            {/*BLOCKCHAIN SECTION
            <div className='blockchainSection'>
                <div className='onTop' style={{position: 'absolute'}}/>
                <div className='layerBlock'/>
                <Container className='layerK'>
                    <Row style={{height:'inherit'}}>
                        <Col className='flex-center-1'> 
                            <h1 className='subtitle1'>BFG <br/> x  <br/>BLOCKCHAIN</h1>
                        </Col>
                        <Col style={{position: 'relative'}}>
                            <div className='bfg-box'>
                                <h5 className='header-box'>
                                    <img src={bfgCoin} alt="coin-bfg"/>
                                    <span>BFG</span>
                                </h5>
                                <p className='pBox'>ERC-20 governance token for the BFG Universe. BFG Holders will be able to vote for future updates on the game and earn rewards</p>
                            </div>
                            <div className='gios-box'>
                                    <h5 className='header-box'>
                                        <img className='gioImg' src={giosCoin} alt="coin-bfg"/>
                                        <span>GIOS</span>
                                    </h5>
                                <p className='pBox'>GIOS (in-game token, non-blockchain) is the glue that holds the Battle For Giostone universe together. You can use them to forge new heroes (NFTs) and convert them to BFG (governance token).</p>
                            </div>
                        </Col>
                    </Row>      
                </Container>
            </div>
            */}

            {/*Backers*/}
            <section className='backers-section'>
                <Container style={{minWidth: '80%'}}>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className='text-backers'>Backers</Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className='row-end-backers'>
                            <img onClick={() => goTo('https://twitter.com/BlizzardFund')} className='blizzard-img' src={blizzard} alt="blizzard-logo" />
                            <img onClick={() => goTo('https://avalaunch.app/')} className='avalaunch-img' src={avalaunch} alt="avalaunch-logo" />
                            <div style={{width: '10px'}}></div>
                            <img onClick={() => goTo('https://www.avalabs.org/')} className='avalabs-img' src={avalabs} alt="avalabs-logo" />
                        </Col>
                    </Row>
                </Container>

            </section>

            {/*Roadmap*/}
            <section className='roadmap-section'>
                <div style={{position: 'absolute', height: '0.6rem'}} className='onTop'/>
                <div className='second-bg'></div>
                <Container style={{position: 'relative', zIndex: '3', paddingTop: '6rem'}}>
                    <Row className='justify-content-center subtitle1' style={{textShadow: '0px 0px 9px #1b022ee3'}}>
                        <span>ROADMAP</span>
                    </Row>
                    <Row className='justify-content-center'>
                            <div style={{marginBottom: '8px', marginTop: '8px'}} className='separator'/>
                    </Row>
                </Container>
                {isDragon &&
                <div className="roadmap-main">
                    <div className="q-section">
                        <div className="q-spaces"></div>
                        {phases &&
                            phases.map(x => 
                                <div className="q-individual" key={x.id}>
                                    <div className="q-title">{x.name}</div>
                                </div>
                            )
                        }
                    </div>
                    <div className="q-dots">

                        <div className="bg-container">
                            <img src={line} alt='line-img' />
                        </div>
                        <div className="q-spaces"></div>
                        {roadmap &&
                            roadmap.map(x => 
                                <div className="dot-section" key={x} onMouseEnter={() => {selectedA(x)}} onMouseLeave={() => selectedA(0)}>
                                    {x > 0 &&
                                        <img alt='circle' style={{filter: 'brightness(0.5)'}} className={phases[x-1]?.active ? 'dots cursor-pointer filtershadpw' : 'dots cursor-pointer'} src={circle}></img>
                                    }
                                    {
                                     x===0 &&
                                     <svg viewBox="0 0 186 57" fill="none" xmlns="http://www.w3.org/2000/svg" className="line" style={{color: 'white'}}>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M185.517 2c0 1.105-.915 2-2.02 2.005C96.916 4.409 46.89 30.793 3.753 56.208a1.998 1.998 0 0 1-2.736-.708 1.994 1.994 0 0 1 .701-2.736C45.141 27.181 95.89.41 183.497.004A2.01 2.01 0 0 1 185.517 2Z" fill="currentColor"></path>
                                    </svg>
                                    }
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="q-text">
                        <div className="q-spaces"></div>
                        {
                            roadmap &&
                                roadmap.map(x => 
                                    <div className="q-text-inner" key={x}>
                                        {
                                            text &&
                                            text[x-1].map(l => 
                                                <div className="partText" key={l.id}>
                                                    {l.done &&
                                                         <span>
                                                            <span className="material-symbols-outlined">done_all</span>
                                                         </span>
                                                    }
                                                    {!l.current &&
                                                        <span>{l.desc}</span>
                                                    }
                                                    {l.current &&
                                                     <q-item className="q-pa-none">
                                                        <q-item-section className="col-1 csss">
                                                            <svg className="loader" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                                                <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                                                    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                                                                </path>
                                                            </svg>
                                                        </q-item-section>
                                                        <q-item-section>
                                                            <span className='text-bold'>{l.desc.toUpperCase()}</span>
                                                        </q-item-section>
                                                    </q-item>
                                                    }
                                                </div>   
                                            )
                                        }
                                    </div>
                                )
                        }
                    </div>
                </div>
                }
                {!isDragon &&
                    <div className='mobile-main' style={{position: 'relative', zIndex: '6'}}>
                    <div className="list">
                        {phases && phases.map((x,index) => 
                            <div className="right" key={x.id}>
                                <div className="icon__home">
                                    {x.pass &&
                                        <img alt='circle' style={{filter: 'brightness(0.5)', width: '100%'}} src={circle} />
                                    }
                                    {x.active &&
                                        <img alt='circle' style={{filter: 'brightness(0.5)', width: '100%'}} className="filtershadpw" src={circle} />
                                    }
                                    {!x.pass && !x.active &&
                                        <img alt='circle' style={{width: '100%'}} src={circle} />
                                    }
                                    
                                </div>
                                <div className="context" style={{textAlign: 'left'}}>
                                    <div className="cTitle">{x.name}</div>
                                    {text[index] &&
                                        text[index].map(y => 
                                            <div key={y.id} className="cText">
                                                {y.done &&
                                                    <span>
                                                        <span className="material-symbols-outlined">done_all</span>&nbsp;&nbsp;
                                                    </span>
                                                }
                                                {!y.current &&
                                                    <span>{y.desc}</span>
                                                }
                                                {y.current &&
                                                    <span className="q-pa-none">
                                                        {y.desc} ...
                                                    </span>
                                                }
                                               
                                            </div> 
                                        )
                                    }
                                   
                                </div>
                            </div>
                        )}
                            
                        </div>
                    </div>
                }
            </section>

            {/*Register */}
            <div className="contact">
                <div className="insides">
                    <h2>Ready to be a part of our journey?</h2>
                    <div className="separators"></div>
                        <p>Register now to be the first to know about Battle For Giostone news, development updates, events, and more!</p>
                        <div className="q-form">
                            <input v-model={email} onChange={(e) => {setEmail(e.target.value)}} type="email" placeholder="Enter your e-mail" name="email"/>
                            <input type="submit" onClick={() => onSubmit(email)} name="submit" value="Register"/>
                        </div>
                </div>
            </div>
    </div>
    )

}

export default Home;