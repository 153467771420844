import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

import heroesFB from '../helpers/heroes';
import AbilityIndexComponent from '../components/AbilityIndexComponent';

function Abilities() {
    const [selectValue, changeselectValue] = useState('all');
    const [clicked, changeClicked] = useState(0);
    const [search, setSearch] = useState('');

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const list = []
        heroesFB.firestore().collection("newAbilities").get().then((userSnapshot) => {
            userSnapshot.forEach((doc) => {
              const data = doc.data();
              list.push(data);
            });
            setData(list);
            setFilteredData(list);
            setLoading(false);
        })
    },[]);

    useEffect(() => {
        let temp = data;
        let c = parseInt(clicked)
        if(search !== ''){
            changeClicked(0);
            changeselectValue('all');
        }else{
            if(selectValue !== 'all'){
                if(c === 0){
                    temp = data.filter(x => { return selectValue.includes(x.slot) })
                }else{
                    temp = data.filter(x => { return selectValue.includes(x.slot) && parseInt(x.complexity) === clicked})
                }
            }else{
                if(c !== 0){
                    temp = data.filter(x => { return parseInt(x.complexity) === clicked})
                }
                
            }
        }
        setFilteredData(temp);
    }, [search, clicked, selectValue, data])

    useEffect(() => {
        changeClicked(0);
        changeselectValue('all');
        setTimeout(() => {
            let temp = data.filter(x => { return x.name.toLowerCase().includes(search.toLowerCase())})
            setFilteredData(temp)
        },1000)
    }, [search, data])

    const complexity = [1,2,3];

    function selectImg(id){
        if (clicked === id) {
            changeClicked(0);
        } else {
            changeClicked(id);
        }
    }

    function slotChange(value){
        changeselectValue(value);
    }

    return(
        <div className="ability-section">
            <div className="second-bg" style={{opacity: '0.3'}}/>
            <Container className="z-1 pt-5 pb-5 ab-container__main">
                <h1 className="title-abilities pt-5">CHOOSE YOUR ABILITY</h1>
                <p className="desc-hero" style={{textAlign: 'center'}}>
                    Browse through the total 72 abilities and select the best. 
                    Place them in the order you want to play, and craft the ultimate Hero.
                </p>

                <Container className="filter pt-2 pb-2">
                    <Row className="mobile-row" style={{justifyContent: 'space-between'}}>
                        <Col className="mobile-center-ab" lg={2} md={2} sm={12} xs={12}>
                            <div className="ps-3 p-ability">FILTER</div>
                        </Col>
                        <Col className="mobile-center-ab" lg={3} md={3} sm={12} xs={12}>
                            <div className="display-inline h100">
                                <div className="p-ability p-second">SLOT</div>
                                <div className="ps-md-2 ps-lg-4 ps-xl-4">
                                    <Form.Select onChange={(e) => slotChange(e.target.value)} value={selectValue} data-bs-theme="dark" aria-label="slot-select">
                                        <option value="1st - Hands/Shoulders">1st - Hands/Shoulders</option>
                                        <option value="2nd - Legs">2nd - Legs</option>
                                        <option value="3rd - Body/Shoulders">3rd - Body/Shoulders</option>
                                        <option value="Ultimate - Head">Ultimate - Head</option>
                                        <option value="all">ALL</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </Col>
                        <Col className="mobile-center-ab" lg={3} md={4} sm={12} xs={12}>
                            <div className="display-inline h100">
                                <div className="p-ability p-second">COMPLEXITY</div>
                                <div className="display-inline ps-lg-4 ps-xl-4 ps-md-1">
                                    {complexity.map(x => 
                                        <img style={clicked >= x ? {opacity: '1'} : {}} className="img-icon-ab" onClick={() => selectImg(x)} key={x} src='/static/icons/complexity.png' alt='complexity-icon'/>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col className="mobile-center-ab" lg={3} md={3} sm={12} xs={12}>
                            <div className="display-inline h100">
                                <Form.Control value={search} onChange={(e) => setSearch(e.target.value)}  data-bs-theme="dark" type="text" placeholder="Search" />
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="ability-container">
                    {!loading && filteredData &&
                        filteredData.map(x => 
                            <AbilityIndexComponent main={false} className='mt-3' key={x.name} x={x} />
                        )
                    }
                </Container>
            </Container>
        </div>
    )   
}

export default Abilities;