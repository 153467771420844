import Navbar from 'react-bootstrap/Navbar';
import { Container } from 'react-bootstrap';
import "../assets/css/NavBar.css";
import useScript from '../helpers/useScript';
import bfgToken from '../assets/img/BFG-token-logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Price(props){
    const createNotification = props.createNotification;
    useScript('https://files.coinmarketcap.com/static/widget/currency.js');

    function copyClipboard(){
        try{
            navigator.clipboard.writeText('0xFd538CA3f58dC309dA55B11f007ff53fb4602876');
            createNotification('success', '', 'Address copied')
        }catch{
            try{
                window.clipboardData.setData("Text", 'Copy this text to clipboard')
            }catch{
                //
            }
        }
    }
    
    return (
        <>
        {/* 
        <Navbar data-bs-theme="dark" fixed="bottom">
            <Container fluid>
                <div className="adressInside">
                    <img width={20} src={bfgToken} alt='bfg-token' />
                    <b>$BFG : </b>
                    <b>AVAX</b> 
                    <b> 0xFd538CA3f58dC309dA55B11f007ff53fb4602876</b>
                    <FontAwesomeIcon onClick={() => copyClipboard()} className='copyIcon' icon="fa-regular fa-copy"/>
                </div> 
            </Container>
             
            <div className="coinmarketcap-currency-widget" data-currencyid="22157" data-base="USD" data-secondary="" data-ticker="true" data-rank="true" data-marketcap="true" data-volume="true" data-statsticker="true" data-stats="USD"></div>
            
        </Navbar>*/}
        </>
    )
}

export default Price;