import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

export default function Notifications({success, handleClose, error, handleCloseError}){
    return(
    <>
        <Snackbar
          open={success !== ''}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          TransitionComponent={SlideTransition}
          autoHideDuration={4000}
          onClose={handleClose}
          message={
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {success}
          </Alert>}
          />
          <Snackbar
          open={error !== ''}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          TransitionComponent={SlideTransition}
          autoHideDuration={4000}
          onClose={handleCloseError}
          message={
            <Alert icon={<ErrorOutlineIcon fontSize="inherit" />} severity="error">
            {error}
          </Alert>}
          />
    </>)
}