import gif1c4 from '../../assets/img/loregif/gif1c4.gif';

import '../../assets/css/lore/chapter4.css';
function ChapterFour(props){
    return(
        <div  className="row row__4">
            <div className="col-sm-4 col-sm-4__4">
                What the kings saw there left them stunned - the three men guarding the stone hadn’t aged a day! In fact, they could argue that the guards looked even younger than before. A bit frightened, the kings asked how that was possible, and one of the guards hesitantly spoke. He explained that nothing could harm them in all those years they spent on guard duty.
                <br /><br />
                At first, they noticed that smaller injuries were barely noticeable and hardly ever felt, even when it was an obviously painful scratch or a clumsy but heavy trip. Then one day, one of the guards suffered a fall that should’ve resulted in a broken bone, but nothing happened. He did not feel any pain, nor was his leg deformed in any way.
                <br /><br />
                Witnessing this, the three guards decided to test a theory and see how serious an injury they could take. The most extreme act was stabbing each other in the heart - and realizing they’re perfectly fine. However, they decided to keep this information to themselves, thinking it would cause more problems than it would solve. Their conclusion - the stone would heal anyone near it, and its powers granted immortality.
            </div>
            <div className="col-sm-4 col-sm-4__4">
                <img className="custmfg__4" src={gif1c4} alt="gif1c4" />
            </div>
            <div className="col-sm-4 col-sm-4__4">
                At that moment, all the kings could do was stare at the guards in astonishment. Nothing made sense, but they believed their story nonetheless. After all, if miracles happen every day, then even strange stones with otherworldly powers can appear out of nowhere. Of course, the three kings did not fail to see this as an opportunity.
                <br /><br />
                Ion was the first to propose the idea of taking the stone inside the kingdoms to see if it could bring prosperity to their nations once again. They knew well enough that allowing something that meddles with the laws of nature into their homes is a great gamble, but it was one they had to take. After a decade of hardship, it was time to do something. They had their people’s best intentions in mind and wanted to end their suffering most of all. And so, they agreed - King Glaucus would be the first to take the stone home.
            </div>
        </div>
    )
}

export default ChapterFour;