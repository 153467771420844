import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TextField } from '@mui/material';
import axiosInstance from '../store/api/axios-users';
import { useSelector, useDispatch} from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { setAuth } from '../store/reducers/authReducer';

export default function SetAccount({showAcc, setShowAcc, setSuccess}){
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setErrors] = useState('');
    const [showPass, setShowPass] = useState(false);

    const matches = useMediaQuery('(max-width:600px)');

    const fullscreen = matches;

    const {user} = useSelector(state => state.auth);

    const handleClose = () => setShowAcc(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setErrors('');
    }, [password, username, confirmPassword])
    
    const validateEmail = (email1) => {
        return String(email1)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };
    
    const setProfile  = async () => {
        setConfirmPassword(password)
        if(password !== '' && email !== '' && username !== ''){
            if(validateEmail(email)){
                if(password === confirmPassword){
                    setErrors('');
                    try{
                        let str = '?password='+password+'&password_confirmation='+confirmPassword+'&username='+username+'&email='+email;
                
                        const res = await axiosInstance.put('/users/'+user?.id+str);

                        if(res?.data?.data){
                            dispatch(setAuth(res?.data?.data));
                            setSuccess('Account completed');
                            setShowAcc(false);
                        }
                    }catch(err){
                        if(err?.response?.data?.message){
                            setErrors(err?.response?.data?.message)
                        }
                    }
                }else{
                    setErrors('Passwords dont match');
                }
            }else{
                setErrors('Invalid email format')
            }
        }else{
            setErrors('Fill in the form');
        }
    }
  
    return (
        <Modal
          fullscreen={fullscreen}
          show={showAcc}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title style={{textAlign: 'center', width: '100%'}}>Set Account</Modal.Title>
          </Modal.Header>
          <Modal.Body className="body_setacc">
            <div className="form_questing ps-0 pe-0">
                <TextField 
                    id="input_email_settings" 
                    sx={{color: 'white'}} 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} 
                    size="small" fullWidth label="Email" 
                    className="input_quest" type="text" 
                />
                <TextField 
                    id="input_username_settings" 
                    sx={{color: 'white'}} 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)} 
                    size="small" fullWidth label="Username" 
                    className="input_quest" type="text" 
                />
                 <TextField 
                    sx={{color: 'white'}} 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                    size="small" fullWidth label="Password" 
                    className="input_quest" 
                    type={showPass ? "text" : "password"} 
                    InputProps={{
                        endAdornment: (
                          <InputAdornment onClick={() => setShowPass(!showPass)} style={{cursor: 'pointer'}} position="end">
                            {!showPass ? <VisibilityOffIcon/> : <VisibilityIcon />}
                          </InputAdornment>
                        ),
                      }}
                />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{width: '100%'}} onClick={() => setProfile()}  variant="primary">Continue</Button>
            <div className="p_error">
                {error !== '' && <span>{error}</span>}
            </div>
          </Modal.Footer>
        </Modal>
    );
}