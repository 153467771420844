import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: 'https://bfgesport.com/api/',
  prepareHeaders: (headers, { getState }) => {
    headers['Access-Control-Allow-Origin'] = '*';
    return headers;
  }
});

//const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

export const api = createApi({
  reducerPath: 'splitApi',
  baseQuery: baseQuery,
  tagTypes: ['Esports'],
  endpoints: () => ({})
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getLeague: () => 'test'
  })
});
