import { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";

import {doc, arrayUnion, updateDoc, query, collection, where, orderBy, getDocs } from "firebase/firestore";
import newsF from "../helpers/firebase";

import arrow from '../assets/img/icons/left-arrow.png';
import { Col, Container, Row } from "react-bootstrap";

import getBrowserFingerprint from 'get-browser-fingerprint';
import NewsComponent from "../components/NewsComponent";

function NewsSample() {
    const firestore = newsF.firestore();
    const { newsName } = useParams();

    const [snapshot, setSnapshot] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [allPosts, setAllPosts] = useState(null)
    const [relatedTopic, setRelatedTopic] = useState(null)
    const [otherTopic, setOtherTopic] = useState(null)
    const [isMobile, setMobile] = useState(false)

    useEffect(() => {
        async function fetchData() {
            try{
                let q = query(collection(firestore, "posts"), where("isVisible", '==', true), orderBy('date', 'desc'))
                
                const querySnapshot = await getDocs(q);
                const fingerprint = getBrowserFingerprint();
                const temp = []
                await querySnapshot.forEach((el) => {
                    let data = el.data()
                    if(data.routeUrl === newsName){
                        setSnapshot(data);
                        setLoading(false);

                        if(!data.fingerprints.includes(fingerprint)){
                            let data1 = {
                                fingerprints: arrayUnion(fingerprint),
                                timesRead: data.timesRead + 1,
                            }
        
                            updateDoc(doc(firestore, 'posts', data.routeUrl), data1).then(() => {})
                            .catch((error) => {});
                        }
                    }else{
                        temp.push(data);
                    }
                });
                setAllPosts(temp);
            }catch(err){
                setError(true);
                setLoading(false);
            }
        }

        fetchData();
    },[firestore, newsName])

    useEffect(() => {
        if(allPosts && snapshot){
            let tempHash = []
            let tempOther = []
            allPosts.forEach((el) => {
                if (el.meta.some(x => {return snapshot.meta.includes(x)}) && el.isVisible) {
                    tempHash.push(el)
                }
                if (el.meta.every(x => {
                        return !snapshot.meta.includes(x)
                    }) && !el.top && el.isVisible) {
                    tempOther.push(el)
                }
            })
            
            //hash
            let counter = tempHash.length
            if (counter) {
                let index = Math.floor(Math.random() * counter);
                setRelatedTopic(tempHash[index])
            } else {
                topStory()
            }

            //other
            let counterOther = tempOther.length
            if (counterOther) {
                let index = Math.floor(Math.random() * counterOther);
                setOtherTopic(tempOther[index])
            }
        }

        function topStory(){
            const index = allPosts.findIndex(x => {return x.top === true});
            if(index !== -1){
                setRelatedTopic(allPosts[index])
            }
        }
    }, [allPosts, snapshot])

    const getDate1 = (timestamp) => {
        var date = new Date(timestamp * 1000);
        // eslint-disable-next-line no-unused-expressions
        date.getMonth() + 1;
        var str = (date.toLocaleString('en-us', {
            month: 'long'
        })).toUpperCase() + " " + date.getDate() + ', ' + date.getFullYear();
        return str;
    }

    function checkMobile(){
        if(window.innerWidth <= 600){
            setMobile(true)
        }else{
            setMobile(false)
        }
    }

    window.addEventListener('resize', () => checkMobile());
    window.addEventListener('load', () => checkMobile());
    

    return(
        <div className="sample-news-container">
            <div className="second-bg" style={{opacity: '0.3'}}/>
            {!loading && error &&
                <Navigate to='/news' />
            }
            {!loading && snapshot &&
            <>
                <div className="header-news">
                    <a href='/news' className="backBut" style={{display: 'flex', width: '5%'}}>
                        <img className="arrowleft" src={arrow} alt="arrow-back" />
                    </a>
                    <div className="headerIMg" style={{backgroundImage: `url(${snapshot.img})`}} />
                </div>
                <Container className="mainContainer-news">
                    <Row style={{justifyContent: 'center'}}>
                        <Col lg={10} md={12} sm={12} xs={12}>
                            <h1 className="title-news">{snapshot.title}</h1>
                            <div className="parts-news pt-5">
                                <div>{snapshot.author !== '.' ? snapshot.author : ''}</div>
                                <div>{getDate1(snapshot.date.seconds)}</div>
                            </div>  
                            <div className="content-news pt-5 mt-5 pb-5 mb-5" dangerouslySetInnerHTML={{ __html: snapshot.content }}></div>
                            <div className="pb-5 mb-5" style={isMobile ? {display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap'} : {display: 'flex', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {relatedTopic &&
                                    <NewsComponent isPagination={isMobile} importance={'Related Topic'} id={1} items_data={relatedTopic}></NewsComponent>
                                }
                                {otherTopic &&
                                    <NewsComponent isPagination={isMobile} importance={'Other Topic'} id={2} items_data={otherTopic}></NewsComponent>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
            }
        </div>
    )
}

export default NewsSample;