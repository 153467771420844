import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const config = {
    apiKey: process.env.REACT_APP_HEROES_API_KEY,
    authDomain: "bfg-website-f3f23.firebaseapp.com",
    databaseURL: "https://bfg-website-f3f23-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bfg-website-f3f23",
    storageBucket: "bfg-website-f3f23.appspot.com",
    messagingSenderId: "1041849371444",
    appId: "1:1041849371444:web:b8f5586e06f1c5b98cb361"
};

const heroesFB = firebase.initializeApp(config);


export default heroesFB;