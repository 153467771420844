import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { UseTypedSelector, selectAllTeams, selectCurrentMatch } from "../../store/reducer";
import { useGetAllTeamsMutation, useGetCurrentMatchMutation } from "../../store/api/esports-api";
import { useLocation, useNavigate } from "react-router-dom";

var options = {
    channel: "BFGiostone",
    muted: false,
    autoplay: true,
    layout: "video",
    height: "100%", 
    width: "100%",
    theme: "dark",
    controls: false, 
    parent: ["localhost", "battleforgiostone.com"]
};

function getMonth(month){
    switch (month) {
    case "12": return 'Dec'; 
    case "01": return 'Jan';
    case "02": return 'Feb'; 
    case "03": return 'Mar';
    case "04": return 'Apr'; 
    case "05": return 'May'; 
    case "06": return 'Jun';
    case "07": return 'Jul';
    case "08": return 'Aug';
    case "09": return 'Sep';
    case "10": return 'Oct';
    case "11": return 'Nov';
    default:  return '';
    }
}
function convertDate(data) {
    if(data){
        const temp = data.split('T')
        let part = parseInt(temp[1].split(':')[0]) >= 12 ? 'PM' : 'AM'
        let hours = parseInt(temp[1].split(':')[0]) % 12;
        hours = hours ? hours : 12;
        return getMonth(temp[0].split('-')[1]) + ' ' + temp[0].split('-')[2] + ' ' + hours + ':' + temp[1].split(':')[1]+ ' ' + part;
    }else{
        return 'TBD'
    }
}

function WatchEsport({league, regions, matches, matchID}){
    const [embeded, setEmbeded] = useState(false);
    const [isLoaded, setLoading] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const [selectedMatch, setSelectedMatch] = useState(null);
    const [fullscreen, setFullScreen] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(0);
    const [leagueTeams, setLeagueTeams] = useState(null);
    const [selectedGame, setSelectedGame] = useState(1);

    const state_teams = UseTypedSelector(selectAllTeams);
    const state_current_match = UseTypedSelector(selectCurrentMatch);

    const [callTeams] = useGetAllTeamsMutation();
    const [callCurrMatch] = useGetCurrentMatchMutation();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(!state_teams){
            callTeams();
        }if(state_teams){
            let temp = state_teams.filter(x => {
                return x.league.id === parseInt(league.id)
            })
            setLeagueTeams(temp);
        }
    },[state_teams, league, callTeams])

    useEffect(() => {
        callCurrMatch(2);
    }, [matchID, callCurrMatch])

    useEffect(() => {
        if(!selectedMatch && isLoaded){
            if(!state_current_match){
                if(Object.values(matches).length){
                    const filtered = Object.keys(matches).reduce(function (filtered, key) {
                        let temp = matches[key].filter(y => {
                            if(y.home_team){
                                return true;
                            }
                            return false;
                        })
                        if(temp){
                            if(temp.length){
                                filtered[key] = temp;
                            }
                        }
                        return filtered;
                    }, {});
                    const lastMatches = filtered[Object.keys(filtered)[Object.keys(filtered).length-1]]

                    if(lastMatches){
                        navigate('/esports/'+league.id+'/watch/'+lastMatches[lastMatches.length-1].id)
                    }else{
                        if(location.pathname !== '/esports/'+league.id+'/watch'){
                            navigate('/esports/'+league.id+'/watch')
                        }else{
                            if(!embeded){
                                let player = new window.Twitch.Player("twitch-embed", options);
                                player.setVolume(0.5);
                                setEmbeded(true)
                            }
                        }
                    }
                }else{
                    if(location.pathname !== '/esports/'+league.id+'/watch'){
                        navigate('/esports/'+league.id+'/watch')
                    }else{
                        if(!embeded){
                            let player = new window.Twitch.Player("twitch-embed", options);
                            player.setVolume(0.5);
                            setEmbeded(true)
                        }
                    }
                }
            }
            if(state_current_match){
                setSelectedMatch(state_current_match)
                if(!embeded){
                    let player = new window.Twitch.Player("twitch-embed", options);
                    player.setVolume(0.5);
                    setEmbeded(true)
                }
            }
        }

        if(selectedMatch && isLoaded){
            if(matchID){
                if(selectedMatch.id !== parseInt(matchID)){
                    setLoading(false)
                    setSelectedMatch(null)
                }
            }else{
                if(!state_current_match){
                    setLoading(false)
                    setSelectedMatch(null)
                }
                if(state_current_match){
                    if(state_current_match.id !== selectedMatch.id){
                        setLoading(false)
                        setSelectedMatch(null)
                    }
                }
            }
           
        }
    }, [selectedMatch, isLoaded, navigate, league, matches, matchID, location, callCurrMatch, state_current_match, embeded])

    useEffect(() => {
        async function getId(){
            await Object.values(matches).forEach(x => {
                x.forEach(y => {
                    if(parseInt(y.id) === parseInt(matchID)){
                        setSelectedMatch(y)
                    }
                })
            })
            setTimeout(() => {
                setLoading(true);
            }, 1000);
        }

        if(matches){
            setLoading(false)
            getId();
        }
    },[matchID, matches])
    
    useEffect(() => {
        if(regions){
            let temp = regions.filter(x => {
                return x.league.id === league.id
            })
            if(temp.length){
                setSelectedRegion(temp[0].region.id)
            }
        }
    },[regions, league])

    return(
        <div className="ref-in">
            <Container className={!fullscreen ? 'notfullscreenvideo__esports pb-5' : 'fullscreenvideo__esports pb-5'} >
                <Row style={{flexWrap: 'nowrap'}}>
                    <Col style={selectedMatch ? selectedMatch.home_win > 0 ? {background: 'linear-gradient(20deg, rgb(128, 45, 163), rgba(29, 10, 37, 0.9))'} : {} : {}} className="watch-col-1__esports">
                        <div className="flex-watch__esports ps-4 pe-4">
                            <div className="arrow-des__esports arrow-left__esports">
                                {selectedMatch && 
                                    [...Array(selectedMatch.best_of)].map((e,i) => 
                                        <div key={i} style={parseInt(selectedMatch.score.split(':')[0]) >= parseInt(i+1) ? {backgroundColor: 'white'} : {}}  className="arrow-l-inside__esports"></div>
                                    )
                                }
                            </div>
                            <div className="remaining__esports"> 
                                <div className="flex-center__esports start-img__esports">
                                    <div className="pe-2 title_team__esports"> {selectedMatch ? selectedMatch.home_team ? selectedMatch.home_team.name : 'Team 1'  : 'Team 1'}</div>
                                    <img className="team-img__esports" src={selectedMatch ? selectedMatch.home_team ? selectedMatch.home_team.logo : '/static/questionmark.png'  : '/static/questionmark.png'} alt='team-1-logo'/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col style={{position: 'relative'}} xl={2} lg={2} md={2} sm={1} xs={1}>
                        <div className="type-2__esports">
                            <div className="title_team__esports">{selectedMatch ? selectedMatch.bracket ? 'Playoff [' + selectedMatch.league_region.region.name +' ]' : 'Group Stage [' + selectedMatch.league_region.region.name +' ]' : 'TBD'}</div>
                            {selectedMatch && 
                            <div className="time__esports">{convertDate(selectedMatch.starts_at)}</div>
                            }
                        </div>
                    </Col>
                    <Col style={selectedMatch ? selectedMatch.away_win > 0 ? {background: 'linear-gradient(268deg, rgb(34, 36, 171), rgba(6, 7, 58, 0.9))'} : {} : {}} className="watch-col-3__esports">
                        <div className="flex-watch__esports from_end pe-4 ps-4">
                            <div className="remaining__esports"> 
                                <div className="flex-center__esports end-img__esports">
                                    <img className="team-img__esports" src={selectedMatch ? selectedMatch.away_team ? selectedMatch.away_team.logo : '/static/questionmark.png' : '/static/questionmark.png'} alt='team-1-logo'/>
                                    <div className="ps-2 title_team__esports">{selectedMatch ? selectedMatch.away_team ? selectedMatch.away_team.name : 'Team 2' : 'Team 2'} </div>
                                </div>
                            </div>
                            <div className="pe-1 arrow-des__esports arrow-right__esports">
                                {selectedMatch && 
                                    [...Array(selectedMatch.best_of)].map((e,i) => 
                                        <div key={i} style={parseInt(selectedMatch.score.split(':')[1]) >= parseInt(i+1) ? {backgroundColor: 'white'} : {}} className="arrow-l-inside__esports"></div>
                                    )
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                {selectedMatch && 
                    selectedMatch.video_source &&
                    <Row className="videos_titles__esport">
                        {
                        selectedMatch.video_source.split(';').map((e,i) => 
                            <Col onClick={() => setSelectedGame(i+1)} className={selectedGame === i+1 ? 'selected_video_game__esports' : 'video_game__esports'} key={i}>Game {i+1}</Col>
                        )
                        }
                    </Row>
                }
                <Row className={!fullscreen ? "container-video__esports" : "container-full-video__esports"}>
                    {selectedMatch && selectedMatch.video_source && !showInfo &&
                        <>
                        {selectedMatch.video_source.toString().includes('youtube')  && 
                            <iframe style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0', zIndex: '3', paddingLeft: '0', paddingRight: '0'}} src={selectedMatch.video_source.split(';')[selectedGame-1]} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        }
                        </>
                    }
                    {(selectedMatch === null || state_current_match) && !showInfo &&
                        <div id="twitch-embed" style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0', zIndex: '3', paddingLeft: '0', paddingRight: '0'}}></div>
                    }
                    {showInfo && selectedMatch &&
                        <Row className="players-stats__esports">
                            <Col className="all_flex__esports">
                                <div className="heading-players__esports"><span className={selectedMatch.home_win > 0 ? 'home_win_text_shadow' : ''}>{selectedMatch.home_team.name}</span></div>
                                <div className="col_players_flex">
                                    {[...Array(5)].map((e,i) => 
                                        <div style={{justifyContent: 'flex-end'}} className="row_players_flex row_players_flex-l pe-3" key={i}>
                                            <span className="pe-3">{selectedMatch.home_team.players[i] ? selectedMatch.home_team.players[i].name : ''}</span>
                                            <img className="profile__player" src={selectedMatch.home_team.players[i] ? selectedMatch.home_team.players[i].image : '/static/questionmark.png'} alt='player-profile' />
                                        </div>
                                    )}
                                </div>
                            </Col> 
                            <Col className="all_flex__esports">
                                <div className="heading-players__esports" style={{textAlign: 'left'}}><span className={selectedMatch.away_win > 0 ? 'away_win_text_shadow' : ''}>{selectedMatch.away_team.name}</span></div>
                                <div className="col_players_flex">
                                    {[...Array(5)].map((e,i) => 
                                        <div style={{justifyContent: 'flex-start'}} className="row_players_flex row_players_flex-r ps-3" key={i}>
                                            <img className="profile__player" src={selectedMatch.away_team.players[i] ? selectedMatch.away_team.players[i].image : '/static/questionmark.png'} alt='player-profile' />
                                            <span className="ps-3">{selectedMatch.away_team.players[i] ? selectedMatch.away_team.players[i].name : ''}</span>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    }
                </Row>
                <Row className="footer-video__esports mt-1 mb-5">
                    <Col style={{padding: '0', display: 'flex'}}>
                        <div onClick={() => {setShowInfo(false)}} className={(selectedMatch === null || state_current_match) || (selectedMatch && selectedMatch.video_source) ? showInfo ? 'container-icon-video__esports ps-3 pe-3' : 'selected-icon-video__esports ps-3 pe-3' : 'disabled-icon-video__esports ps-3 pe-3'}>
                            <FontAwesomeIcon size="lg" icon="fa-solid fa-circle-play" />
                        </div>
                        <div onClick={() => {setShowInfo(true)}} className={selectedMatch ? showInfo ? 'selected-icon-video__esports ps-3 pe-3' : 'container-icon-video__esports ps-3 pe-3' : "disabled-icon-video__esports ps-3 pe-3"}>
                            <FontAwesomeIcon size="lg" icon="fa-solid fa-circle-info" />
                        </div>
                    </Col>
                    <Col style={{padding: '0', display: 'flex', justifyContent: 'flex-end'}}>
                        <div onClick={() => {setFullScreen(false)}} className={!fullscreen ? 'selected-icon-video__esports ps-3 pe-3' : 'container-icon-video__esports ps-3 pe-3'}>
                            <FontAwesomeIcon size="lg" icon="fa-solid fa-down-left-and-up-right-to-center" />
                        </div>
                        <div onClick={() => {setFullScreen(true)}} className={fullscreen ? 'selected-icon-video__esports ps-3 pe-3' : 'container-icon-video__esports ps-3 pe-3'}>
                            <FontAwesomeIcon size="lg" icon="fa-solid fa-up-right-and-down-left-from-center" />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="teams-view__esports pt-5" fluid>
                    <div className="layoutTeam__esports"></div>
                    <h2 className="teams-title__esports mt-5">PARTICIPATING TEAMS</h2>
                    <div className="regions-title__esports">
                        {regions && regions.filter(x => {return x.league.id === league.id }).map((x,index) => 
                            <Fragment key={x.region.id}>
                                <div onClick={() => {setSelectedRegion(x.region.id)}} style={selectedRegion === x.region.id ? {color: 'white'} : {}} className="cursor-pointer"> { x.region.name}</div>
                                {index + 1 !== regions.length &&
                                    <div className="ps-1 pe-1">/</div>
                                }
                            </Fragment>
                        )}
                    </div>
                    <Container className="pt-5 pb-5" fluid>
                        <Row style={{justifyContent: 'center', position: 'relative', zIndex: '3'}}>
                            <div className="pt-4 pb-5 container-tema__esports">
                                <div className="row-team__esports">
                                    {leagueTeams && parseInt(selectedRegion) !== 0 &&
                                        leagueTeams.filter(x => {return x.region.id === parseInt(selectedRegion)}).map(x =>
                                            <div className="team-el__esports" key={x.id}>
                                                <div>
                                                    <img className="team-el-picture" src={x.team.logo} alt='team-logo' />
                                                </div>
                                                <div className="pt-4 team-el-title">{x.team.name}</div>
                                            </div>    
                                        )
                                    }
                                </div>
                            </div>
                        </Row>
                    </Container>
            </Container>
        </div>
    )
 
}

export default WatchEsport;