import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useGetLeagueResultsMutation } from "../../store/api/esports-api";

function BracketsResults({leagueId, results, rValue}){

    const [getResults] = useGetLeagueResultsMutation();

    useEffect(() => {
        if(!results[leagueId]){
            getResults({leagueId: leagueId, regionId: rValue});
        }
    }, [results, rValue, leagueId, getResults])

    return(
        <Container className="tournament-container__esports1">
            <Row className="result-bracket-container__esports" style={{gap: '1.5rem', padding: '15px'}}>
                {results[rValue] && 
                    results[rValue].map(x => 
                        <div key={x.id} className="result-inner-c__esports">
                            <div style={{width: '18%'}}>
                                {x.place <= 3 &&
                                    <img className="img-Trophy__esports" src={x.place === 1 ? '/static/esports/goldTrophy.png' : x.place === 2 ? '/static/esports/silverTrophy.png' : '/static/esports/bronzeTrophy.png'} alt='trophy'/>
                                }
                                {x.place > 3 &&
                                    <div>{x.place + 'th'}</div>
                                }
                            </div>
                            <div className="result-logo-al__esports">
                                <img className="img-logo-results__esports" src={x.team ? x.team.logo : '/static/questionmark.png'} alt='trophy'/>
                                <div className="result-team_name__esports">{x.team ? x.team.name : 'TBD'}</div>
                            </div>
                            <div className="result-price__esports">
                                <div className="result-sub__esports">EARNINGS</div>
                                <div>${x.prize ? x.prize : 0} USD</div>
                                </div>
                        </div>    
                    )
                }
            </Row>
        </Container>
    )
}

export default BracketsResults;