import "../assets/css/ThirdWeb.css";
import {useNavigate} from 'react-router-dom';
import { createThirdwebClient } from "thirdweb";
import {
    ConnectButton,
    darkTheme,
} from "thirdweb/react";
import {
    createWallet,
    walletConnect,
    inAppWallet,
} from "thirdweb/wallets";

import Zoom from '@mui/material/Zoom';
import {
    LoginPayload,
    VerifyLoginPayloadParams,
  } from "thirdweb/auth";

import { base } from "thirdweb/chains";
import axiosInstance from "../store/api/axios-users";
import { useSelector, useDispatch } from 'react-redux';
import { changeProfileAuth, logOutAuth, setAuth, setAuthToken } from "../store/reducers/authReducer";
import Avatar from '@mui/material/Avatar';
import { useEffect, useCallback, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

import { useActiveWallet } from "thirdweb/react";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#110929',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      border: '1px solid white',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
      },
}));

const defauts = [
    {id: 0, src: '/static/cybe/aku.png'},
    {id: 1, src: '/static/cybe/aur.png'},
    {id: 2, src: '/static/cybe/gog.png'},
    {id: 3, src: '/static/cybe/gur.png'},
    {id: 4, src: '/static/cybe/lucs.png'},
    {id: 5, src: '/static/cybe/ranv.png'}
];

const client = createThirdwebClient({
    clientId: process.env.REACT_APP_TW_CLIENDID,
});

const MenuItems = ({logout, navigate}) => {
    return(
        <List
            sx={{ width: '100%', minWidth: 161, maxWidth: 360, bgcolor: 'transparent', color: 'white' }}
            component="nav"
            aria-labelledby="profile-menu"
            className="list_avatar"
            >
            <ListItemButton onClick={() => window.open('/user/profile',"_self")}>
                <ListItemIcon>
                <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="My Account" />
            </ListItemButton>
            <ListItemButton onClick={() => window.open('/user/profile-settings',"_self")}>
                <ListItemIcon>
                <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => logout()}>
                <ListItemIcon>
                <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Log out" />
            </ListItemButton>
            </List>
    )
}

export default function ThirdwebBtn({setSuccess, setError}){
    const {accessToken, profile_picture} = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const [openSM, setOpenSM] = useState(false);

    const wallet = useActiveWallet();
    const navigate = useNavigate();

    const handleCloseSM = () => {
        setOpenSM(false);
    };

    const handleOpenSM = () => {
        setOpenSM(true);
    };

    const logout = async () => {
        try{
            await axiosInstance.get("auth/logout");
            await wallet.disconnect();
        }catch{}

        await dispatch(logOutAuth());
        setSuccess('Account logged out');
        setTimeout(() => {
            window.open('/','_self');
        }, 1000)
    }


    const wallets = [
        createWallet("io.metamask"),
        createWallet("com.coinbase.wallet"),
        walletConnect(),
        inAppWallet({
          auth: {
            options: [
              "email",
              "google",
              "apple",
              "facebook",
              "phone",
            ],
          },
        }),
        createWallet("com.trustwallet.app"),
    ];

    const changePicture = useCallback(
      () => {
            const rnd = Math.ceil(Math.random() * (defauts.length - 1) + 1);
            
            if(defauts[rnd]?.src)
                {
                    dispatch(changeProfileAuth(defauts[rnd]?.src));
                }
        },
      [dispatch],
    )
    

    useEffect(() => {
      if(profile_picture === '/static/defaultProfilePicture.png'){
        changePicture();
      }
    }, [profile_picture, changePicture])
    
    return(
        <div className="gap-nav0">
            <ConnectButton
                chain={base}
                client={client}
                wallets={wallets}
                autoConnect={{ timeout: 10000 }}
                theme={darkTheme({
                    colors: {
                        accentText: "#920000",
                        accentButtonBg: "#920000",
                        modalBg: "#110929",
                        dropdownBg: "#110929",
                        walletSelectorButtonHoverBg: "#1b142e"
                    }
                })}
                connectButton={{
                    label: "Log in",
                }}
                connectModal={{
                    size: "wide",
                    title: "Connect Wallet",
                    titleIcon:
                        "https://battleforgiostone.com/static/media/bfg-logo.47e385d9bbcbb5133a42.png",
                    showThirdwebBranding: false,
                    welcomeScreen: {
                        subtitle:
                        "Connect your wallet to get started",
                        title: "Welcome to Battle For Giostone",
                        img: {
                            src: "https://battleforgiostone.com/static/media/bfg-logo.47e385d9bbcbb5133a42.png",
                            width: 150,
                            height: 150,
                        },
                    },
                }}
                auth={{
                    getLoginPayload: async (params: {
                        address: string;
                    }): Promise<LoginPayload> => {
                        const fd = new FormData();
                        fd.append('wallet_address',  params.address)
                        fd.append('chain_id', base.id.toString() ? base.id.toString() : null)
                        const result = await axiosInstance.post("auth/self/web3-payload",fd);
                        
                        return result.data;
                    },
                    doLogin: async (params: VerifyLoginPayloadParams) => {
                        const payload = {
                            "address": params.payload.address,
                            "chain_id": params.payload.chain_id,
                            "domain": params.payload.domain,
                            "expiration_time": params.payload.expiration_time,
                            "invalid_before": params.payload.invalid_before,
                            "issued_at": params.payload.issued_at,
                            "nonce": `${params.payload.domain} wants you to sign in with your Ethereum account:
${params.payload.address}

Please ensure that the domain above matches the URL of the current website.

Version: ${params.payload.version}
Chain ID: ${params.payload.chain_id}
Nonce: ${params.payload.nonce}
Issued At: ${params.payload.issued_at}
Expiration Time: ${params.payload.expiration_time}
Not Before: ${params.payload.invalid_before}`
};
                        
                        const data = {
                            "payload": payload,
                            "signature": params.signature
                        };
                        
                        try{
                        let config = {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                        const response = await axiosInstance.post('auth/self/web3-login', JSON.stringify(data), config);
                        
                        if(response?.data?.data?.status){
                            dispatch(setAuthToken(response?.data?.data?.token));
                            const res1 = await axiosInstance.get('auth/me');
                            
                            if(res1?.data?.data){
                                dispatch(setAuth(res1?.data?.data))
                            }
                        }

                        setSuccess('Logged in successfully');
                        
                        }catch(err){
                            console.log(err)
                            if(err.response?.data?.message){
                                setError(err.response?.data?.message)
                            }
                        }
                    },
                    isLoggedIn: async () => {
                        return (accessToken !== null && wallet);
                    },
                    doLogout: async () => {
                        logout();
                    },
                }}
                appMetadata={{
                    name: "Battle For Giostone",
                    url: "https://battleforgiostone.com",
                    description: "A different 5v5 MOBA experience. Forge your Hero and climb the ranks. Use the Hero in Battle and see if you got the skills to emerge victorious!",
                    logoUrl: "https://battleforgiostone.com/static/media/bfg-logo.47e385d9bbcbb5133a42.png",
                }}
            />
            {accessToken && wallet &&
                <ClickAwayListener onClickAway={handleCloseSM}>
                    <div>
                        <HtmlTooltip 
                        TransitionComponent={Zoom}
                        open={openSM} 
                        onClose={handleCloseSM} 
                        onOpen={handleOpenSM} 
                        title={<MenuItems logout={logout} navigate={navigate} />}
                        placement="bottom-end"
                        PopperProps={{
                            disablePortal: true,
                        }}
                        slotProps={{
                            popper: {
                            modifiers: [
                                {
                                name: 'offset',
                                options: {
                                    offset: [0, -7],
                                },
                                },
                            ],
                            },
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener>
                            <Avatar onClick={() => setOpenSM(!openSM)} className="avatar_tw" variant="square" alt="Profile P" src={profile_picture} />
                        </HtmlTooltip>
                    </div>
                </ClickAwayListener>
            }
        </div>
    )
}