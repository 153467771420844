import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Footer() {
    const footerItems = [{
            id: 1,
            name: 'Discord',
            route: 'https://discord.com/invite/BFGiostone',
            logo: 'fab fa-discord'
        },
        {
            id: 2,
            name: 'Twitter',
            route: 'https://twitter.com/BFGiostone',
            logo: 'fab fa-twitter'
        },
        {
            id: 3,
            name: 'Twitch',
            route: 'https://www.twitch.tv/bfgiostone',
            logo: 'fab fa-twitch'
        },
        {
            id: 4,
            name: 'Instagram',
            route: 'https://www.instagram.com/battleforgiostone/',
            logo: 'fab fa-instagram'
        },
        {
            id: 5,
            name: 'Linkedin',
            route: 'https://www.linkedin.com/company/battle-for-giostone/',
            logo: 'fab fa-linkedin'
        },
        {
            id: 6,
            name: 'Youtube',
            route: 'https://www.youtube.com/channel/UCINBr5wNOrxN4NAKT_LTxDg/featured',
            logo: 'fab fa-youtube'
        },
        {
            id: 7,
            name: 'Facebook',
            route: 'https://www.facebook.com/BattleForGiostone',
            logo: 'fab fa-facebook'
        },

    ]
    return (
        <footer style={{position: 'relative', zIndex: '4'}}>
            <div className="footerD">
                {footerItems && footerItems.map(x => 
                    <div className="foterSitm"key={x.id}>
                        <a target="_blank" href={x.route}>
                            <FontAwesomeIcon icon={x.logo}/>
                        </a>
                    </div>
                )}
            </div>
            <div className="rigts">
                <span>M-Tech Entertainment © 2021</span>
            </div>
            {/* 
            <div className='space-bottom'>
                <div style={{opacity: 0}}> spacer </div>
            </div>*/}
        </footer>
    )
}
export default Footer;