import { Fragment, useState } from "react";
import { useEffect } from "react"
import { useGetMatchesAfterMutation, useGetMatchesBeforeMutation } from "../../store/api/esports-api";
import { UseTypedSelector, selectLeagueMatchesAfter, selectLeagueMatchesBefore } from "../../store/reducer";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Audio } from 'react-loader-spinner'

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function getGrouped(data){
    try{
        let i = 0;
        let j = 'a';
        return Object.entries(data).reduce((group, product) => {
                i++;
                const category = j.toString();
                group[category] = group[category] ? group[category] : [];
                group[category].push(product);    
                if(i%2===0){
                    j = String.fromCharCode(j.charCodeAt(0) + 1);
                    
                }
                
                return group;
        }, {})
    }catch{
        return data;
    }
}

function getStage(from, to, data){
    try{
        const fromMonth = parseInt(from.split('-')[1])
        const toMonth = parseInt(to.split('-')[1])

        const fromDay = parseInt(from.split('-')[2])
        const toDay = parseInt(to.split('-')[2])

        let temp = Object.keys(data).filter((key, value) => {
            let keyMonth = parseInt(key.split('-')[1])
            let keyDay = parseInt(key.split('-')[2])
            
            if(keyMonth === fromMonth){
                if(keyDay >= fromDay){
                    return {key,value}
                }
            }
            if(keyMonth > fromMonth && keyMonth < toMonth){
                return {key, value}
            }

            if(keyMonth >= fromMonth && keyMonth === toMonth){
                if(keyDay <= toDay){
                    return {key,value}
                }
            }
            return false;
        }).reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
        }, {});
        
        return temp;
    }
    catch{
        return data;
    }
}

function getMonth(month){
    switch (month) {
    case "12": return 'Dec'; 
    case "01": return 'Jan';
    case "02": return 'Feb'; 
    case "03": return 'Mar';
    case "04": return 'Apr'; 
    case "05": return 'May'; 
    case "06": return 'Jun';
    case "07": return 'Jul';
    case "08": return 'Aug';
    case "09": return 'Sep';
    case "10": return 'Oct';
    case "11": return 'Nov';
    default:  return '';
    }
}

function Schedule({league, regions, matches, executeScroll}){
    const [regionsMain, setRegions] = useState(null);
    const [groupStage, setGroupStage] = useState(null);
    const [playOffs, setPlayOffs] = useState(null);
    const [finals, setFinals] = useState(null);
    const [isLoading, setLodading] = useState(true);

    const [rValue, setRValue] = useState(-1);
    const [gValue, setGValue] = useState(-1);
    const [search, setSearch] = useState('');

    //for filtering
    const [allMatches, setAllMatches] = useState(true);
    const [groupMatches, setGroupMatches] = useState(null);
    const [playOffMatches, setPlayOffMatches] = useState(null);
    const [finalMatches, setFinalMatches] = useState(null);
    const [regionFitered, setRegionFiltered] = useState(null);

    const [getMatchesBefore] = useGetMatchesBeforeMutation();
    const [getMatchesAfter] = useGetMatchesAfterMutation();

    const state_before = UseTypedSelector(selectLeagueMatchesBefore)
    const state_after = UseTypedSelector(selectLeagueMatchesAfter)
    const navigate = useNavigate()

    useEffect(() => {
        if(regions){
            const regions_now = regions.filter(x => {
                return x.league.id === league.id
            })
            if(regions_now){
                setRegions(regions_now)
            }
        }
    }, [regions, league, setRegions])

    useEffect(() => {
        async function getMatches(){
            try{
                const today = formatDate(Date.now())
                if(matches && !state_before[league.id]){
                    await getMatchesBefore({l_id: league.id, time: today})
                }
                if(matches && !state_after[league.id]){
                    await getMatchesAfter({l_id: league.id, time: today})
                }
                setTimeout(() => {
                    setLodading(false)
                },5000)
            }catch{
                setLodading(false)
            }
        }
        if(state_before){
            let a = getStage(formatDate(league.group_stage_from),formatDate(league.group_stage_to),state_before[league.id])
            let b = getStage(formatDate(league.playoff_from),formatDate(league.playoff_to),state_before[league.id])
            let c = getStage(formatDate(league.finals_from),formatDate(league.finals_to),state_before[league.id])
            setGroupStage(getGrouped(a))
            setPlayOffs(getGrouped(b))
            setFinals(getGrouped(c))
        }
        getMatches();
        
    }, [matches, getMatchesBefore, getMatchesAfter, league, state_after, state_before])


    function SecondLevelOff(){
        setGroupMatches(null);
        setPlayOffMatches(null);
        setFinalMatches(null);
    }
    function setShowAll(){
        setAllMatches(true);
        SecondLevelOff();
        setRegionFiltered(null);
        setRValue(-1);
        setGValue(-1);
    }
    function setShowGloup(value){
        setAllMatches(false);
        setPlayOffMatches(null);
        setFinalMatches(null);
        setRValue(-1);
        setGValue(-1);
        setGroupMatches(value);
    }
    function setPlayMatches(value){
        setAllMatches(false);
        setGroupMatches(null);
        setFinalMatches(null);
        setRValue(-1);
        setGValue(-1);
        setPlayOffMatches(value);
    }
    function setFinal(value){
        setAllMatches(false);
        setGroupMatches(null);
        setPlayOffMatches(null);
        setRValue(-1);
        setGValue(-1);
        setFinalMatches(value);
    }
    async function changeRegion(val){
        setAllMatches(true);
        SecondLevelOff()
        setRValue(val);
        let temp = Object.assign({}, state_before[league.id])
        if(parseInt(val) !== -1){
            let compare = parseInt(gValue) === 1 ? null : true;
            await Object.keys(temp).forEach(async (el) => {
                temp[el] = await temp[el].filter(x => {
                    return parseInt(gValue) === -1 ? parseInt(x.league_region.region.id) === parseInt(val) :  parseInt(x.league_region.region.id) === parseInt(val) && (compare ? x.bracket : !x.bracket);
                })
            })
            setRegionFiltered(temp)
        }else{
            setGValue(-1);
            setRegionFiltered(state_before[league.id])
        }
    }
    async function changeGame(val){
        setAllMatches(true);
        SecondLevelOff()
        setGValue(val);
        let temp = Object.assign({}, state_before[league.id])
        if(parseInt(val) !== -1){
            let compare = parseInt(val) === 1 ? null : true;
            await Object.keys(temp).forEach(async (el) => {
                temp[el] = await temp[el].filter(x => {
                    return parseInt(rValue) !== -1 ? parseInt(x.league_region.region.id) === parseInt(rValue) && (compare ? x.bracket : !x.bracket) : (compare ? x.bracket : !x.bracket);
                })
            })
            setRegionFiltered(temp)
        }else{
            setRValue(-1);
            setRegionFiltered(state_before[league.id])
        }
    }
    async function changeSearch(val){
        setSearch(val);
        setShowAll();
        let temp = Object.assign({}, state_before[league.id])
        if(val !== ''){
            await Object.keys(temp).forEach(async (el) => {
                temp[el] = await temp[el].filter(x => {
                    return x.away_team ? x.away_team.name.toLowerCase().includes(val.toLowerCase()) || x.home_team.name.toLowerCase().includes(val.toLowerCase()) : false;
                })
            })
            setRegionFiltered(temp)
        }else{
            setRegionFiltered(state_before[league.id])
        }
    }

    function goTo(link){
        navigate(link);
    }

    return(
        <div className="ref-in">
            <>
                {state_after[league.id] && 
                    <>
                    {
                        state_after[league.id].length === 0 && 
                        <Container fluid>
                            <Row>
                                <Col lg={2}></Col>
                                <Col></Col>
                                <Col lg={2}></Col>
                            </Row>
                        </Container>
                    }
                    </>
                }
                <Container className="container-menu-schedule__esports">
                    <Row className="row-menu-schedule__esports">
                        <Col className="flex-select__esports show-all-container__esports" style={{justifyContent: 'start'}}>
                            <Button onClick={() => {setShowAll()}} className="btn-show-all__esports" variant="dark">SHOW ALL</Button>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={5} xs={12} className="flex-select__esports">
                            <Col lg={4} md={4} sm={6} xs={5}>
                                <Form.Select value={rValue} onChange={(e) => changeRegion(e.target.value)} className="monserat-text__esports" variant="primary" data-bs-theme="dark" aria-label="Regions">
                                    <option value="-1">All Regions</option>
                                    {regionsMain && regionsMain.map(x => 
                                        <option key={x.region.id} value={x.region.id}>{x.region.name}</option> 
                                    )}
                                </Form.Select>
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={5}>
                                <Form.Select value={gValue} onChange={(e) => changeGame(e.target.value)} data-bs-theme="dark" aria-label="Type" className="monserat-text__esports">
                                    <option value="-1">All Games</option>
                                    <option value="1">Group Stage</option>
                                    <option value="2">Playoff</option>
                                </Form.Select>
                            </Col>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12} xs={12} className="flex-select__esports">
                            <InputGroup data-bs-theme="dark">
                                <InputGroup.Text id="basic-addon">
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="Search by Team name ..."
                                    type="text"
                                    value={search}
                                    onChange={(e) => {changeSearch(e.target.value)}}
                                    id="search-text"
                                    aria-describedby="searchTeamName"
                                    className="monserat-text__esports"
                                />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row className="pt-4 row-menu-schedule__esports">
                        <Col lg={5} md={5} sm={12} xs={12} xl={5} xxl={5}>
                        {!isLoading && groupStage && Object.keys(groupStage).length !== 0 &&
                            <>
                            <div className="title__schedule">GROUP STAGE</div>
                            <div className="group-weeks__esports pt-3">
                                {Object.keys(groupStage).map((x,index) => 
                                <div onClick={() => setShowGloup(x)} className={groupMatches === x ? 'group-element__esports_selected group-element__esports' : 'group-element__esports group-element1__esports'} key={x}>
                                        {index+1}
                                        <div className="week__esports">Week</div>
                                    </div>
                                )}
                            </div>
                            </>
                        }
                        </Col>
                        <Col>
                        {!isLoading && playOffs && Object.keys(playOffs).length !== 0 &&
                            <>
                                <div className="title__schedule">PLAYOFFS</div>
                                <div className="group-weeks__esports pt-3">
                                    {Object.keys(playOffs).map((x,index) => 
                                    <div onClick={() => setPlayMatches(x)} className={playOffMatches === x ? 'group-element__esports_selected group-element__esports' : 'group-element__esports group-element1__esports'} key={x}>
                                            {index+1+Object.keys(groupStage).length}
                                            <div className="week__esports">Week</div>                            
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                        </Col>
                        <Col>
                        {!isLoading && finals && Object.keys(finals).length !== 0 &&
                            <>
                            <div className="title__schedule">FINALS</div>
                            <div className="group-weeks__esports pt-3">
                                {Object.keys(finals).map((x,index) => 
                                <div onClick={() => setFinal(x)}  className={finalMatches === x ? 'group-element__esports_selected group-element__esports' : 'group-element__esports group-element1__esports'} key={x}>
                                        {index+1+Object.keys(groupStage).length+Object.keys(playOffs).length}
                                        <div className="week__esports">Week</div>          
                                    </div>
                                )}
                            </div>
                            </>
                        }
                        </Col>
                    </Row>
                    
                    <Row className="pt-5">
                        <Col className="pb-5">
                        {isLoading &&
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '2rem', minHeight: '20rem'}}>
                                <Audio
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="white"
                                    ariaLabel="loading"
                                />
                            </div>   
                        }
                        {!isLoading && allMatches && !regionFitered && state_before[league.id] &&
                            <>
                            {
                            Object.keys(state_before[league.id]).map((x,index) => 
                                <div key={index}>
                                    <Row className="display-header__esports" >
                                        {x ? parseInt(x.split('-')[2]) + ' ' + getMonth(x.split('-')[1]) : ''}
                                    </Row>
                                    {
                                        state_before[league.id][x] &&
                                        <div className="pb-4">
                                            {state_before[league.id][x].map(y => 
                                            <Fragment key={y.id}>
                                                {y.home_team &&
                                                    <Row className="row-schedule__esports">
                                                        <Col className="col-1-schedule__esports">
                                                            <div className="col-leaguename__esports">{y.league_region.league.name} [{y.league_region.region.name}]</div>
                                                            <div className="col-stagename__esports">{!y.bracket ? 'Group Stage' : 'Playoff'}</div>
                                                        </Col>
                                                        <Col className="col-2-schedule__esports">
                                                            <div className="flex-to__esports flex-end__esports">
                                                                <span className="pe-2 name-team__esports">{y.home_team ? y.home_team.name : ''}</span>
                                                                <img className="team-img__esports" src={y.home_team ? y.home_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                            </div>
                                                        </Col>
        
                                                        <Col className="score__esport">
                                                            {y.score}
                                                        </Col>
        
                                                        <Col className="col-2-schedule__esports">
                                                            <div className="flex-to__esports flex-from__esports flex-start__esports">
                                                                <img className="team-img__esports" src={y.away_team ? y.away_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                                <span className="ps-2 name-team__esports">{y.away_team ? y.away_team.name : ''}</span>
                                                            </div>
                                                        </Col>
        
                                                        <Col className="col-3-schedule__esports">
                                                            <Button onClick={() => goTo('/esports/'+league.id+'/watch/'+y.id)} className="btn-show-all__esports" variant="dark">Details</Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Fragment>
                                            )}
                                        </div>
                                    }
                                </div>
                            )
                            }
                            <div className="pt-5">
                                <Button onClick={() => executeScroll()} className="btn-simple__esport" variant="dark">Scroll to top</Button>
                            </div>
                            </>
                        }
                        {!isLoading && allMatches && regionFitered &&
                        <>
                        {
                            Object.keys(regionFitered).map((x,index) => 
                            <>
                                <div key={index}>
                                    <Row className={regionFitered[x].length ? "display-header__esports" : ""} >
                                        {regionFitered[x].length ? parseInt(x.split('-')[2]) + ' ' + getMonth(x.split('-')[1]) : ''}
                                    </Row>
                                    <div className={regionFitered[x].length ? 'pb-4' : ''}>
                                        {regionFitered[x].map(y => 
                                        <Fragment key={y.id}>
                                            {y.home_team &&
                                            <Row className="row-schedule__esports">
                                                <Col className="col-1-schedule__esports">
                                                    <div className="col-leaguename__esports">{y.league_region.league.name} [{y.league_region.region.name}]</div>
                                                    <div className="col-stagename__esports">{!y.bracket ? 'Group Stage' : 'Playoff'}</div>
                                                </Col>
                                                <Col className="col-2-schedule__esports">
                                                    <div className="flex-to__esports flex-end__esports">
                                                        <span className="pe-2 name-team__esports">{y.home_team ? y.home_team.name : ''}</span>
                                                        <img className="team-img__esports" src={y.home_team ? y.home_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                    </div>
                                                </Col>

                                                <Col className="score__esport">
                                                    {y.score}
                                                </Col>

                                                <Col className="col-2-schedule__esports">
                                                    <div className="flex-to__esports flex-from__esports flex-start__esports">
                                                        <img className="team-img__esports" src={y.away_team ? y.away_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                        <span className="ps-2 name-team__esports">{y.away_team ? y.away_team.name : ''}</span>
                                                    </div>
                                                </Col>

                                                <Col className="col-3-schedule__esports">
                                                    <Button onClick={() => goTo('/esports/'+league.id+'/watch/'+y.id)} className="btn-show-all__esports" variant="dark">Details</Button>
                                                </Col>
                                            </Row>
                                            }
                                        </Fragment>
                                        )}
                                    </div>
                                </div>
                            </>
                            )
                        }
                            <div className="pt-5">
                                <Button onClick={() => executeScroll()} className="btn-simple__esport" variant="dark">Scroll to top</Button>
                            </div>
                        </>
                        }

                        {!isLoading && groupMatches && groupStage[groupMatches] &&
                        <>
                        {
                            groupStage[groupMatches].map((x,index) => 
                            <div key={index}>
                                <Row className="display-header__esports" >
                                    {x ? parseInt(x[0].split('-')[2]) + ' ' + getMonth(x[0].split('-')[1]) : ''}
                                </Row>
                                {
                                    x[1] &&
                                    <div className="pb-4">
                                        {x[1].map(y => 
                                        <Fragment key={y.id}>
                                        {y.home_team &&
                                            <Row className="row-schedule__esports">
                                                <Col className="col-1-schedule__esports">
                                                    <div className="col-leaguename__esports">{y.league_region.league.name} [{y.league_region.region.name}]</div>
                                                    <div className="col-stagename__esports">{!y.bracket ? 'Group Stage' : 'Playoff'}</div>
                                                </Col>
                                                <Col className="col-2-schedule__esports">
                                                    <div className="flex-to__esports flex-end__esports">
                                                        <span className="pe-2 name-team__esports">{y.home_team ? y.home_team.name : ''}</span>
                                                        <img className="team-img__esports" src={y.home_team ? y.home_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                    </div>
                                                </Col>

                                                <Col className="score__esport">
                                                    {y.score}
                                                </Col>

                                                <Col className="col-2-schedule__esports">
                                                    <div className="flex-to__esports flex-from__esports flex-start__esports">
                                                        <img className="team-img__esports" src={y.away_team ? y.away_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                        <span className="ps-2 name-team__esports">{y.away_team ? y.away_team.name : ''}</span>
                                                    </div>
                                                </Col>

                                                <Col className="col-3-schedule__esports">
                                                    <Button onClick={() => goTo('/esports/'+league.id+'/watch/'+y.id)} className="btn-show-all__esports" variant="dark">Details</Button>
                                                </Col>
                                            </Row>
                                        }
                                        </Fragment>
                                        )}
                                    </div>
                                }
                            </div>
                            )
                        }
                            <div className="pt-5">
                                <Button onClick={() => executeScroll()} className="btn-simple__esport" variant="dark">Scroll to top</Button>
                            </div>
                        </>
                        }

                        {!isLoading && playOffMatches && playOffs[playOffMatches] &&
                         <>
                         {
                            playOffs[playOffMatches].map((x,index) => 
                                <div key={index}>
                                    <Row className="display-header__esports" >
                                        {x ? parseInt(x[0].split('-')[2]) + ' ' + getMonth(x[0].split('-')[1]) : ''}
                                    </Row>
                                    {
                                        x[1] &&
                                        <div className="pb-4">
                                            {x[1].map(y => 
                                            <Fragment key={y.id}>
                                            {y.home_team &&
                                                <Row className="row-schedule__esports">
                                                    <Col className="col-1-schedule__esports">
                                                        <div className="col-leaguename__esports">{y.league_region.league.name} [{y.league_region.region.name}]</div>
                                                        <div className="col-stagename__esports">{!y.bracket ? 'Group Stage' : 'Playoff'}</div>
                                                    </Col>
                                                    <Col className="col-2-schedule__esports">
                                                        <div className="flex-to__esports flex-end__esports">
                                                            <span className="pe-2 name-team__esports">{y.home_team ? y.home_team.name : ''}</span>
                                                            <img className="team-img__esports" src={y.home_team ? y.home_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                        </div>
                                                    </Col>

                                                    <Col className="score__esport">
                                                        {y.score}
                                                    </Col>

                                                    <Col className="col-2-schedule__esports">
                                                        <div className="flex-to__esports flex-from__esports flex-start__esports">
                                                            <img className="team-img__esports" src={y.away_team ? y.away_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                            <span className="ps-2 name-team__esports">{y.away_team ? y.away_team.name : ''}</span>
                                                        </div>
                                                    </Col>

                                                    <Col className="col-3-schedule__esports">
                                                        <Button onClick={() => goTo('/esports/'+league.id+'/watch/'+y.id)} className="btn-show-all__esports" variant="dark">Details</Button>
                                                    </Col>
                                                </Row>
                                            }
                                            </Fragment>
                                            )}
                                        </div>
                                    }
                                </div>
                            )
                        }
                            <div className="pt-5">
                                    <Button onClick={() => executeScroll()} className="btn-simple__esport" variant="dark">Scroll to top</Button>
                            </div>
                         </>
                        }

                        {!isLoading && finals && finals[finalMatches] &&
                        <>

                        {!isLoading && 
                        finals[finalMatches].map((x,index) => 
                            <div key={index}>
                                <Row className="display-header__esports" >
                                    {x ? parseInt(x[0].split('-')[2]) + ' ' + getMonth(x[0].split('-')[1]) : ''}
                                </Row>
                                {
                                    x[1] &&
                                    <div className="pb-4">
                                        {x[1].map(y => 
                                        <Fragment key={y.id}>
                                        {y.home_team &&
                                             <Row className="row-schedule__esports">
                                                <Col className="col-1-schedule__esports">
                                                    <div className="col-leaguename__esports">{y.league_region.league.name} [{y.league_region.region.name}]</div>
                                                    <div className="col-stagename__esports">{!y.bracket ? 'Group Stage' : 'Playoff'}</div>
                                                </Col>
                                                <Col className="col-2-schedule__esports">
                                                    <div className="flex-to__esports flex-end__esports">
                                                        <span className="pe-2 name-team__esports">{y.home_team ? y.home_team.name : ''}</span>
                                                        <img className="team-img__esports" src={y.home_team ? y.home_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                    </div>
                                                </Col>

                                                <Col className="score__esport">
                                                    {y.score}
                                                </Col>

                                                <Col className="col-2-schedule__esports">
                                                    <div className="flex-to__esports flex-from__esports flex-start__esports">
                                                        <img className="team-img__esports" src={y.away_team ? y.away_team.logo : '/static/questionmark.png'} alt="team-img"/>
                                                        <span className="ps-2 name-team__esports">{y.away_team ? y.away_team.name : ''}</span>
                                                    </div>
                                                </Col>

                                                <Col className="col-3-schedule__esports">
                                                    <Button onClick={() => goTo('/esports/'+league.id+'/watch/'+y.id)} className="btn-show-all__esports" variant="dark">Details</Button>
                                                </Col>
                                            </Row>
                                        }
                                        </Fragment>
                                        )}
                                    </div>
                                }
                            </div>
                        )
                        }
                            <div className="pt-5">
                                <Button onClick={() => executeScroll()} className="btn-simple__esport" variant="dark">Scroll to top</Button>
                            </div>
                        </>
                        }

                        
                        </Col>
                    </Row>
                </Container>
            </>
        </div>
    
    )
}

export default Schedule;