import { combineReducers } from '@reduxjs/toolkit';
import { api } from './api/api';
import esports from './reducers/esportsReducer';
import brackets from './reducers/bracketsReducer';
import { useDispatch, useSelector } from 'react-redux';
import authReducer from './reducers/authReducer';

export const rootReducer = combineReducers({ [api.reducerPath]: api.reducer, esports: esports, brackets: brackets, auth: authReducer });

export const UseAppDispatch = () => useDispatch();
export const UseTypedSelector = useSelector;

export const selectLeagues = (state) => state.esports.leagues;
export const selectLastLeagues = (state) => state.esports.lastLeague;
export const selectIsPending = (state) => state.esports.isPending;
export const selectLR = (state) => state.esports.league_regions;
export const selectLeagueMatches = (state) => state.esports.league_matches;
export const selectLeagueMatchesBefore = (state) => state.esports.league_matches_before;
export const selectLeagueMatchesAfter = (state) => state.esports.league_matches_after;
export const selectAllTeams = (state) => state.esports.all_teams;
export const selectCurrentMatch = (state) => state.esports.current_match; 
export const selectLeagueRegionsRankList = (state) => state.esports.league_regions_rank_list;
export const selectLeagueRegionsGroupStage = (state) => state.brackets.league_regions_group_stage;
export const selectLeagueRegionPlayoffs = (state) => state.brackets.league_region_playoffs;
export const selectLeagueRegionResults = (state) =>  state.brackets.league_region_results;