import { Fragment, useEffect, useState } from "react";
import { useGetPlayOffMatchesLeagueRegionMutation } from "../../store/api/esports-api";
import { Row } from "react-bootstrap";

function getBracket(participants)
{
  var participantsCount = participants.length;	
  var rounds = Math.ceil(Math.log(participantsCount)/Math.log(2));
  //var bracketSize = Math.pow(2, rounds);
  //var requiredByes = bracketSize - participantsCount;
	
  //console.log("Number of participants: " + (participantsCount));
  //console.log("Number of rounds: " + (rounds));
  //console.log("Bracket size: " + (bracketSize));
  //console.log("Required number of byes: " + (requiredByes));    
    
  if(participantsCount < 2) {
    return [];
  }
    
  var matches = [[1,2]];
  
  for(var round = 1; round < rounds; round++) {
    var roundMatches = [];
    var sum = Math.pow(2, round + 1) + 1;
    
    for(var i = 0; i < matches.length; i++) {
      var home = changeIntoBye(matches[i][0], participantsCount);
      var away = changeIntoBye(sum - matches[i][0], participantsCount);
      roundMatches.push([home, away]);
      home = changeIntoBye(sum - matches[i][1], participantsCount);
      away = changeIntoBye(matches[i][1], participantsCount);
      roundMatches.push([home, away]);
    }
    matches = roundMatches;   
  }   
  
  return {matches, rounds};    
}

function changeIntoBye(seed, participantsCount)
{
    return seed <= participantsCount ?  seed : null;
}

function PlayOffBrackets({leagueId, regions, rValue, playoffs, teams}){
    const [matches, setMatches] = useState(null)
    const [rounds, setRounds] = useState(null)
    
    const [getPlayoff] = useGetPlayOffMatchesLeagueRegionMutation();

    useEffect(() => {
        if(teams){
            const bottomBracket = teams.slice(Math.ceil(teams.length/4) + Math.floor(teams.length/2), teams.length)
            const teamPlaying = teams.slice(0, bottomBracket.length * -1);
            const {matches,rounds} = getBracket(teamPlaying)
            setMatches(matches)
            setRounds(rounds)
        }
    }, [teams])

    useEffect(() => {
        if(!playoffs[rValue]){
            getPlayoff({leagueId: leagueId, regionId: rValue});
        }
    }, [playoffs, rValue, leagueId, getPlayoff])
    return(
        <>
        {matches && rounds && 
        <div className="tournament-container__esports">
            <Row className="flex_row__esports">
                BRACKET
            </Row>
            <div className={rounds >= 4 ? 'tournament Large' : 'tournament'}>
                {playoffs &&
                <>
                    {!playoffs[rValue] &&
                        [...Array(rounds)].map((x,i) => 
                            <div key={i}>
                                <>
                                {i+1 === rounds &&
                                        <div>
                                            <ul>
                                            <li>
                                                <div className="inline__esports">
                                                        <img className="img_brackets__esports" src='/static/questionmark.png' alt='home_team_logo' />
                                                        <div className="ps-2" style={{textAlign: 'left'}}>
                                                            Winner of UB
                                                        </div>
                                                    </div>
                                                    <div className="result__brackets__esports">
                                                        0
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inline__esports">
                                                        <img className="img_brackets__esports" src='/static/questionmark.png' alt='home_team_logo' />
                                                        <div className="ps-2" style={{textAlign: 'left'}}>
                                                            Winner of LB
                                                        </div>
                                                    </div>
                                                    <div className="result__brackets__esports">
                                                        0
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                }
                                {i+1 !== rounds &&
                                    [...Array(Math.floor(matches.length/(i+1)))].map((y,j) =>
                                        <div className={j >= Math.floor(matches.length/(i+1))/2 && i!==0 ? 'display-ul__brackets' : ''} key={j}>
                                            <ul>
                                                <li>
                                                    <div className="inline__esports">
                                                        <img className="img_brackets__esports" src='/static/questionmark.png' alt='home_team_logo' />
                                                        <div className="ps-2" style={{textAlign: 'left'}}>
                                                            {i === 0 ? 'Team ' + ((i+1)*(j+1)) : i === 1 ? 'Team ' + ((i+1)*(j+1)-1) + '/' + ((i+2)+(j*(i+1)+3)-1) : j < Math.floor(matches.length/(i+1))/2 ? 'Winner of UB' : 'Winner of LB'}
                                                        </div>
                                                    </div>
                                                    <div className="result__brackets__esports">
                                                        0
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inline__esports">
                                                        <img className="img_brackets__esports" src='/static/questionmark.png' alt='home_team_logo' />
                                                        <div className="ps-2" style={{textAlign: 'left'}}>
                                                            {i === 0 ? 'Team ' + ((i+2)+(j+3)) : i === 1 ? 'Team ' + ((i+1)*(j+1)) + '/' + ((i+3)+(j*(i+1)+2)) : j < Math.floor(matches.length/(i+1))/2 ? 'Winner of UB' : 'Winner of LB'}
                                                        </div>
                                                    </div>
                                                    <div className="result__brackets__esports">
                                                        0
                                                    </div>
                                                </li>
                                            </ul>
                                            {j >= Math.floor(matches.length/(i+1))/2 && i!==0 &&
                                            <ul>
                                                <li>
                                                    <div className="inline__esports">
                                                        <img className="img_brackets__esports" src='/static/questionmark.png' alt='home_team_logo' />
                                                        <div className="ps-2" style={{textAlign: 'left'}}>
                                                            {i === 0 ? 'Team ' + ((i+1)*(j+1)) : 'Loser of UB'}
                                                        </div>
                                                    </div>
                                                    <div className="pe-2">
                                                        0
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inline__esports">
                                                        <img className="img_brackets__esports" src='/static/questionmark.png' alt='home_team_logo' />
                                                        <div className="ps-2" style={{textAlign: 'left'}}>
                                                            {i === 0 ? 'Team ' + ((i+2)+(j+3)) : 'Winner of LB'}
                                                        </div>
                                                    </div>
                                                    <div className="pe-2">
                                                        0
                                                    </div>
                                                </li>
                                            </ul>
                                            }
                                        </div>
                                    )
                                }
                                </>
                            </div>
                        )
                    } 
                    {playoffs[rValue] &&
                        [...Array(rounds)].map((x,i) => 
                            <div key={i}>
                                <>
                                {playoffs[rValue].filter(x => {return i === 0 ? parseInt(x.round) === (i+1) : parseInt(x.round) === (i+1) && (x.bracket === 'ub' || x.bracket === 'semi-final')}).map(el => 
                                    <div key={el.id}>
                                        <ul>
                                            {el.bracket &&
                                                <li className="li-header__brackets">
                                                    {el.bracket === 'ub' ? 'UB ROUND ' + el.round : el.bracket === 'lb' ? 'LB ROUND' + el.round : el.bracket === 'semi-final' ? 'SEMIFINAL' : 'GRAND FINAL'}
                                                </li>
                                            }
                                            <li>
                                                <div className="inline__esports">
                                                    <img className="img_brackets__esports" src={el.home_team ? el.home_team.logo : '/static/questionmark.png'} alt='home_team_logo' />
                                                    <div className="ps-2" style={{textAlign: 'left'}}>
                                                        {el.home_team ? el.home_team.name : el.home_team_placeholder}
                                                    </div>
                                                </div>
                                                <div className="result__brackets__esports">
                                                    0
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inline__esports">
                                                    <img className="img_brackets__esports" src={el.away_team ? el.away_team.logo : '/static/questionmark.png'} alt='home_team_logo' />
                                                    <div className="ps-2" style={{textAlign: 'left'}}>
                                                        {el.away_team ? el.away_team.name : el.away_team_placeholder}
                                                    </div>
                                                </div>
                                                <div  className="result__brackets__esports">
                                                    0
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {i !== 0 && (i+1) !== rounds &&
                                    [...Array(playoffs[rValue].filter(x => {return parseInt(x.round) === (i+1) && x.bracket === 'lb'}).length)].map((k,j) => 
                                        <Fragment key={j+1}>
                                            {j % 2 === 0 &&
                                                <div className="display-ul__brackets">
                                                    {   
                                                        playoffs[rValue].filter(x => {return parseInt(x.round) === (i+1) && x.bracket === 'lb'}).slice(j,j+2).map((el,index) => 
                                                        <ul key={el.id}>
                                                            {el.bracket &&
                                                                <li className="li-header__brackets">
                                                                    {el.bracket === 'ub' ? 'UB ROUND ' + el.round : el.bracket === 'lb' ? 'LB ROUND' + el.round : el.bracket === 'semi-final' ? 'SEMIFINAL' : 'GRAND FINAL'}
                                                                </li>
                                                            }
                                                            <li>
                                                                <div className="inline__esports">
                                                                    <img className="img_brackets__esports" src={el.home_team ? el.home_team.logo : '/static/questionmark.png'} alt='home_team_logo' />
                                                                    <div className="ps-2" style={{textAlign: 'left'}}>
                                                                        {el.home_team ? el.home_team.name : el.home_team_placeholder}
                                                                    </div>
                                                                </div>
                                                                <div className="result__brackets__esports">
                                                                    0
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="inline__esports">
                                                                    <img className="img_brackets__esports" src={el.away_team ? el.away_team.logo : '/static/questionmark.png'} alt='home_team_logo' />
                                                                    <div className="ps-2" style={{textAlign: 'left'}}>
                                                                        {el.away_team ? el.away_team.name : el.away_team_placeholder}
                                                                    </div>
                                                                </div>
                                                                <div className="result__brackets__esports">
                                                                    0
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </Fragment>
                                    )
                                }
                                {(i+1) === rounds && playoffs[rValue].filter(x => {return parseInt(x.round) === (i+1) && x.bracket === 'final'}).map(el => 
                                    <div key={el.id}>
                                            <ul>
                                                {el.bracket &&
                                                    <li className="li-header__brackets">
                                                        {el.bracket === 'ub' ? 'UB ROUND ' + el.round : el.bracket === 'lb' ? 'LB ROUND' + el.round : el.bracket === 'semi-final' ? 'SEMIFINAL' : 'GRAND FINAL'}
                                                    </li>
                                                }
                                                <li>
                                                    <div className="inline__esports">
                                                        <img className="img_brackets__esports" src={el.home_team ? el.home_team.logo : '/static/questionmark.png'} alt='home_team_logo' />
                                                        <div className="ps-2" style={{textAlign: 'left'}}>
                                                            {el.home_team ? el.home_team.name : el.home_team_placeholder}
                                                        </div>
                                                    </div>
                                                    <div className="result__brackets__esports">
                                                        0
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inline__esports">
                                                        <img className="img_brackets__esports" src={el.away_team ? el.away_team.logo : '/static/questionmark.png'} alt='home_team_logo' />
                                                        <div className="ps-2" style={{textAlign: 'left'}}>
                                                            {el.away_team ? el.away_team.name : el.away_team_placeholder}
                                                        </div>
                                                    </div>
                                                    <div className="result__brackets__esports">
                                                        0
                                                    </div>
                                                </li>
                                            </ul>
                                    </div>
                                )}
                                </>
                            </div>
                        )
                    }  
                </>    
                }
            </div>
        </div>
        }
        </>
    )
}

export default PlayOffBrackets;