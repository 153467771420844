import { Container, Row } from 'react-bootstrap';
import TeamComponent from '../components/TeamComponent';

//{id: 5, name: 'Stefan Savevski', position: 'Head of Growth', linkedin: true, link: 'https://www.linkedin.com/in/stefan-savevski', img: '/static/team/stefan.jpg'},
const team = [
    {id: 0, name: 'Mile Gramatikov', position: 'CEO / CO Founder', linkedin: true, link: 'https://www.linkedin.com/in/mile-gramatikov-14869b1a4', img: '/static/team/mile.jpg'},
    {id: 1, name: 'Theeban Siva', position: 'CO Founder', linkedin: false, link: 'https://liquipedia.net/dota2/1437', img: '/static/team/teban.jpg'},
    {id: 2, name: 'Dimitar Solakov', position: 'COO / Co Founder', linkedin: true, link: 'https://www.linkedin.com/in/dimitar-solakov-87634122a', img: '/static/team/mitko.jpg'},
    {id: 3, name: 'Ana Toseva', position: 'CTO / Co Founder', linkedin: true, link: 'https://www.linkedin.com/in/ana-toseva-676093227', img: '/static/team/ana.jpg'},
    {id: 4, name: 'Kiril Lisickov', position: 'QA / Co Founder', linkedin: true, link: 'https://www.linkedin.com/in/kiril-lisichkov-3b9a06213', img: '/static/team/kiril.jpg'},
    {id: 5, name: 'Stojanco Gorbasliev', position: 'Co Founder', linkedin: true, link: 'https://www.linkedin.com/in/stojanco-gorbasliev-79b34522a/', img: '/static/team/stojan.jpg'}
]

function About(){

    return(
        <section className='about-section'>
            <div className='second-bg'/>
            <Container className='starting-container'>
                <Row>
                    <h1 className="subtitle1" style={{color: 'white'}}> OUR TEAM </h1>
                </Row>
                <p className='text-content' style={{color: '#ffffff99'}}>
                    What started out as a freelance gig turned into something much bigger. M-Tech is more than a company. <br/>
                    It’s a team that has evolved through the years and has completed every goal set before us. 
                    It’s time to do it all over again in the world of Play and Earn gaming.
                </p>

                <Row className='team-row'>
                    {team && team.map(x => 
                        <TeamComponent key={x.id} values={x}></TeamComponent>
                    )}
                </Row>
            </Container>
        </section>
    )
}

export default About;