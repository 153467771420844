import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const config = {
    apiKey: process.env.REACT_APP_NEWS_API_KEY,
    authDomain: "bfg-news.firebaseapp.com",
    projectId: "bfg-news",
    storageBucket: "bfg-news.appspot.com",
    messagingSenderId: "842426299623",
    appId: "1:842426299623:web:a918661b17ee574bb69e42",
    measurementId: "G-06403KRQTK"
};

const newsF = firebase.initializeApp(config, 'news');


export default newsF;